// Action types
import {
    LOADING_STATUS,
} from '../helpers/action_types';
const _initalState = false;

const Loading_status = (state = _initalState, action) => {
Object.freeze(state);
let newState;

switch (action.type) {
    case LOADING_STATUS:
        newState = action.data;
        return newState
    default:
        return state
}
}

export default Loading_status;