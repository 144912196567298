import React, { useState, useEffect } from 'react'
import { Form, Button, ButtonGroup } from 'react-bootstrap'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { toPermissionObject, toPermissionBinary } from '../permissionConverter'
import '../permissionComponents.css'
import PermissionOptions from './PermissionOptions';

const roles = ['Manager', 'Operator'];

export default function URLPermissionsEditor({ folderID, fileID, currentUser, permission, updatePermission, removePermission, createLink }) {
    const [token, setToken] = useState('');
    const [readPermission, setReadPermission] = useState(false);
    const [writePermission, setWritePermission] = useState(false);
    const [downloadPermission, setDownloadPermission] = useState(false);
    const [deletePermission, setDeletePermission] = useState(false);
    const [editPermissionsPermission, setEditPermissionsPermission] = useState(false);
    const [SelectPerm, setSelectPerm] = useState(false);

    useEffect(() => {
        if (permission){
            setToken(permission.token);
            const convertedPermissions = toPermissionObject(permission.permission);
            setReadPermission(convertedPermissions.read);
            setWritePermission(convertedPermissions.write);
            setDownloadPermission(convertedPermissions.download);
            setDeletePermission(convertedPermissions.delete);
            setEditPermissionsPermission(convertedPermissions.editPermissions);
        }
    }, [permission])

    const currentPermissionsBinary = () => {
        return toPermissionBinary({
            read: readPermission,
            write: writePermission,
            download: downloadPermission,
            delete: deletePermission,
            editPermissions: editPermissionsPermission
        });
    }

    const hasChanged = () => {
        const permissionBinary = currentPermissionsBinary();

        return (
            // Check if the permission changed
            permission.permission !== permissionBinary
        );
    }

    const stateToObject = () => ({
        id: permission.id,
        token: token,
        permission: currentPermissionsBinary(),
        notify: false
    })

    const handleURLPermission = () => {
        if (!permission){
            createLink()
        } else {
            removePermission(stateToObject())
        }
    }

    return (
        <Container>
            <Row>{permission?"Share this link with anyone you wish to give access to":"Shared link"}</Row>
            <Row>
                <Col style={{display: 'flex'}}>
                    <label class="switch">
                        <input type="checkbox" onChange={handleURLPermission} checked={permission} />
                        <span class="slider round"></span>
                    </label>
                    {permission?
                        <Form.Group style={{width: '100%'}}>
                            <Form.Control
                                value={`https://${window.location.hostname}/#/share/${token}/` + (fileID ? `file/${fileID}` : `folder/${folderID}`)} />
                        </Form.Group>
                        :null}
                </Col>                     
            </Row>
            {/* Right now, links only have read permission {permission?
                <Row>
                    <Col style={{display: 'flex'}}>
                        <div style={{marginLeft: 'auto'}}>
                            {SelectPerm? 
                                <div>
                                    <ButtonGroup>
                                        <Button size="sm" variant="primary" onClick={() => updatePermission(stateToObject())} disabled={hasChanged()?false:true}>Confirm</Button>
                                        <Button size="sm" variant="outline-primary" onClick={()=>{setSelectPerm(!SelectPerm)}}>Cancel</Button>
                                    </ButtonGroup>
                                    <PermissionOptions
                                    readPermission={readPermission} setReadPermission={setReadPermission}
                                    writePermission={writePermission} setWritePermission={setWritePermission}
                                    downloadPermission={downloadPermission} setDownloadPermission={setDownloadPermission}
                                    deletePermission={deletePermission} setDeletePermission={setDeletePermission}
                                    editPermissionsPermission={editPermissionsPermission} setEditPermissionsPermission={setEditPermissionsPermission}
                                    />
                                </div>:
                                <Button style={{marginLeft: '1em'}} size="sm" variant="outline-primary" onClick={()=>{setSelectPerm(!SelectPerm)}}>
                                    Edit permissions
                                </Button>
                            }
                        </div>
                    </Col>
                </Row>
            :null} */}
            
        </Container>
    )
}