import React, { useState } from 'react';
import { withRouter, Redirect, useHistory } from 'react-router-dom';
import './style.css';

import { connect } from 'react-redux';
import { updateUser } from '../../actions/user_actions';
import { SimplePost } from '../../actions/request_actions';

const mapStateToProps = (state, ownProps) => {
  return {
  };
};

const mapDispatchToProps = (dispatch, { location }) => {
  return {
    updateUser: user => dispatch(updateUser(user)),
    simplepost: (data, url) => dispatch(SimplePost(data, url)),
  };
};

const Cancel = (props) => {
  const [cancelled, setCancelled] = useState(false);
  let history = useHistory();

  const handleClick = async (e) => {
    e.preventDefault();
    if (props.location.state){
      const {subscription} = await props.simplepost({subscriptionId: props.location.state.subscription}, '/cancel-subscription');
      if (subscription.status === "canceled"){
        await props.updateUser({membership: 'basic'});
        setCancelled(true);
      } else {
        window.alert('Cancel failed, please try again.')
      }
    } else {
      history.push('/account-details');
    }
  };

  if(cancelled) {
    return <Redirect to={`/userprofile`} />
  }

  return (
    <div>
      <h1>Cancel</h1>
      <button onClick={handleClick}>Cancel</button>
    </div>
  )
}


// export default withRouter(Cancel);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Cancel)
);