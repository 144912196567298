import React from 'react';

// import moment from 'moment';

import { withRouter } from 'react-router-dom';

import { uniqueId } from '../../helpers/id_generator';

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import Usage from "./Usage_container";
import Recent from "./Recent";
import QuickAccess from "./QuickAccess";
import QuickAction from "./QuickAction";
import QuickOCRview from "./QuickOCRview";
import Tools from "./Tools";

import Popup from '../Popup';

import {translate} from '../../helpers/translate';

import "babel-polyfill";
import "./style.css";

var _ = require('underscore-node');

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      quickOCR: null,
      RecentFiles: [],
      activedServices: [],
    };

  }

  async componentWillMount() {
    // #1. get user info
    if (!(this.props.currentUser || this.props.userId))
      this.props.readUser(this.props.userId);
    // #2. Fetch recent files, max 7
    this.props.simplepost({limit: 7}, '/ftree/allRecentFiles').then(RecentFiles => {
      this.setState({ RecentFiles: RecentFiles['Files']?RecentFiles['Files']:[]});
    });
    // #3. Fetch recent created folders, max 7
    this.props.simplepost({limit: 7}, '/ftree/allmain').then(activedServices => {
      this.setState({ activedServices });
    });
  }

  componentDidMount() {
    this.setState({ loading: false });
  }

  displayError() {
    if (this.props.errors.length > 0) {
      const errors = this.props.errors;

      return (
        <div className="login-error">
          { errors.map((error, idx) => {
            return (
              <h4 key={idx + uniqueId()}>{error}</h4>
            )
          })}
        </div>
      )
    }
  }

  render() {
    if (!this.props.currentUser || this.state.loading) {
      return (
        <div className='loading'><p>LOADING...</p></div>
      )
    }
    return (
      <Container fluid>
        {this.state.quickOCR?
          <Popup show={true} size='xsmall' closeButtonLoc='TopRight' 
            handleClose={() => {this.setState({quickOCR: null})}}>
              <QuickOCRview simplepost={this.props.simplepost} />
          </Popup>
          :null
        }

        <div className="StickyTop">
          <Row>
            <Usage simplepost={this.props.simplepost} />
            <a className="QuickUpload" href='' onClick={(e)=>{e.preventDefault(); this.setState({quickOCR: true})}}>{translate('Quick upload')}</a>
          </Row>
        </div>
        <Row>
          <Recent titleList={this.state.RecentFiles}
            currentUser={this.props.currentUser}
            simplepost={this.props.simplepost} />
        </Row>
        <Row>
          <QuickAccess FolderList={this.state.activedServices} />
        </Row>
        <Row>
          <QuickAction simplepost={this.props.simplepost} />
        </Row>
        {/* <Row>
          <Tools />
        </Row> */}
      </Container>
    );
  }
}

export default withRouter(Dashboard);
