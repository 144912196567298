import React, { useEffect, useRef } from 'react';  
import './style.css';
import ListGroup from 'react-bootstrap/ListGroup';
// import {MdClose} from '@react-icons/all-files/md/MdClose';
import useOutsideClick from './useOutsideClick';


const ActionMenu = ({ Location, ActionList, HandleAction, handleDisappear }) => {
    const ref = useRef();
    useOutsideClick(ref, () => {
        console.log('You clicked outside');
        handleDisappear();
    })

    const left = Location?Location[0]:0;
    const top = Location?Location[1]:0;
    const key = Location.length>2?Location[2]:'';
    // console.log('top: ', top);
    // console.log('screen height: ', screen.availHeight);
    console.log('screen width: ', screen.availWidth);
    let TheStyle;
    if (screen.availHeight - top <= 300){
        TheStyle = {position:'fixed', left: screen.availWidth-left<200?left-200:left, bottom: screen.availHeight - top - 40, zIndex: '10'};
    } else {
        TheStyle = {position:'fixed', left: screen.availWidth-left<200?left-200:left, top:top, zIndex: '10'};
    }
    return (
        <ListGroup style={TheStyle} ref={ref}>
            {ActionList.map(ele=>{
                return (
                    <ListGroup.Item className='ButtonHighLight' onClick={()=>{HandleAction(ele, key)}}>{ele}</ListGroup.Item>
                )
            })}
        </ListGroup>
    );
};
export default ActionMenu;