import React, { useState } from 'react';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';



const DownloadCirclePro = ({percentage}) => {
    // console.log('percentage ', percentage);
    if (percentage === 100){
        setTimeout(() => {
            store.dispatch({type: 'DOWNLOAD_PROGRESS', data: false});
        }, 1000);
    }
    return (
        <div className="DownloadSign">
            <CircularProgressbar className={percentage===100?'box bounce-7':null}
                value={percentage} 
                text={`${percentage}%`} 
                background 
                backgroundPadding={6}
                styles={buildStyles({
                    backgroundColor: "#26225b",
                    textColor: "#fff",
                    pathColor: "#fff",
                    trailColor: "transparent"
                  })}
                />
        </div>
    );
};
export default DownloadCirclePro;