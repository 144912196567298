import React, { useState, useEffect } from 'react'
import { Form, Button, FormControl, InputGroup } from 'react-bootstrap'
import './TagModal.css'
import {translate} from '../../../helpers/translate';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';

import { BsTriangleFill } from '@react-icons/all-files/bs/BsTriangleFill';
import { BsFillSquareFill } from '@react-icons/all-files/bs/BsFillSquareFill';
import { BsFillCircleFill } from '@react-icons/all-files/bs/BsFillCircleFill';

import { options } from './TagOptions';

import "babel-polyfill";

const animatedComponents = makeAnimated();

export default function TagModal({ doc, simplepost }) {
    let tags = doc.tags;
    let title = doc.title;
    let FileID = doc.FileID;
    let FolderID = doc.FolderID;
    // console.log('title: ', title);
    const [Tags, setTags] = useState(null); 
    const [Title, setTitle] = useState(title); 

    // const options = [
    //     { value: '0', label: 'tri', color: '#00B8D9' }, // blue
    //     { value: '1', label: 'tri', color: '#FF5630' }, // red
    //     { value: '2', label: 'tri', color: '#36B37E' }, // green
    //     { value: '3', label: 'rec', color: '#00B8D9' },
    //     { value: '4', label: 'rec', color: '#FF5630' },
    //     { value: '5', label: 'rec', color: '#36B37E' },
    //     { value: '6', label: 'cir', color: '#00B8D9' },
    //     { value: '7', label: 'cir', color: '#FF5630' },
    //     { value: '8', label: 'cir', color: '#36B37E' },
    // ];

    const addAlpha = (color, opacity) => {
        // coerce values so ti is between 0 and 1.
        var _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
        return color + _opacity.toString(16).toUpperCase();
    }

    const customStyles = {
        option: (styles, {data}) => {
            return {
                ...styles,
                color: data.color,
            }
        },
        control: (styles, { data }) => {
            return {
                ...styles,
                borderColor: 'rgb(204 204 204 / 0%)',
                ':hover': {
                    borderColor: 'rgb(204, 204, 204)'
                }
            };
        },
        clearIndicator: (styles, { data }) => {
            return {
                ...styles,
                ':hover': {
                    cursor: 'pointer'
                }
            };
        },
        dropdownIndicator: (styles, { data }) => {
            return {
                ...styles,
                ':hover': {
                    cursor: 'pointer'
                }
            };
        },
        valueContainer:(styles, { data }) => {
            return {
                ...styles,
                '>div': {
                    maxWidth: 'fit-content !important'
                }
            }
        },
        multiValue: (styles, { data }) => {
            const color = data.color;
            return {
              ...styles,
              backgroundColor: addAlpha(color, 0.1),
            };
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: data.color,
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: data.color,
            ':hover': {
                backgroundColor: data.color,
                color: 'white',
                cursor: 'pointer'
            },
        }),
    }

    const TagsToOptions = (storedTags) => { // "0,1,2" => [option0, option1, option2]
        if (storedTags){
            // let idx_list = storedTags.split(',');
            let idx_list = storedTags;
            let optionList = idx_list.map(ele=>{return options[Number(ele)]})
            return optionList
        } else {
            return []
        }
    }

    const OptionsToTags = (allOptions) => { // [option0, option1, option2] => "0,1,2"
        if (allOptions.length > 0){
            let NewTags = allOptions.map(ele=>{return ele['value']});
            // return NewTags.join(',')
            return NewTags
        } else {
            return null
        }
    }


    if (Title != title) {
        console.log('update!');
        setTitle(title);
        setTags(null)
    }

    const handleChange = (selectedOption) => {
        console.log('selectedOption: ', selectedOption);
        let TagsInStr = OptionsToTags(selectedOption);
        simplepost({Data: JSON.stringify({'tags': TagsInStr, FileID, FolderID})}, '/ftree/updateTags');
        setTags(TagsInStr || '');
        console.log('TagsInStr: ', TagsInStr);
        doc['tags'] = TagsInStr;
    };

    return (
        <div style={{width: '100%'}}>
            {/* <div>{title}</div> */}
            <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                defaultValue={TagsToOptions(tags)}
                value={TagsToOptions(Tags?Tags:tags)}
                isMulti
                options={options}
                placeholder='Add tags'
                styles={customStyles}
                getOptionLabel={e => (
                    e.label === 'tri'?
                    <BsTriangleFill />: e.label === 'rec'?
                    <BsFillSquareFill />: <BsFillCircleFill/>
                )}
                onChange={handleChange}
            />
        </div>
    )
}
