
import {
  RECEIVE_SESSION_ERRORS,
  RECEIVE_CURRENT_USER,
  LOGOUT_USER,
  CLEAR_SESSION_ERRORS,
  RECEIVE_FETCH_ERROR
} from '../helpers/action_types';

const _nullErrors = [];

export default (state = _nullErrors, action) => {
  Object.freeze(state);
  switch (action.type) {
    case RECEIVE_SESSION_ERRORS:
      const errors = Array.isArray(action.errors) ? action.errors : [action.errors]
      return errors;
    
    case RECEIVE_FETCH_ERROR:
      const error = action.errors?action.errors:""
      return error;

    case LOGOUT_USER, RECEIVE_CURRENT_USER, CLEAR_SESSION_ERRORS:
      return _nullErrors;
      
    default:
      return state;
  }
};
