module.exports = {

  // Session Types
  RECEIVE_CURRENT_USER: 'RECEIVE_CURRENT_USER',
  RECEIVE_SIGNUP_USER: 'RECEIVE_SIGNUP_USER',
  LOGOUT_USER: 'LOGOUT_USER',
  SET_SHARE_TOKEN: 'SET_SHARE_TOKEN',

  // User Types
  READ_CURRENT_USER: 'READ_CURRENT_USER',
  FORGOT_PASSWORD_USER: 'FORGOT_PASSWORD_USER',
  CLEAR_SESSION_ERRORS: 'CLEAR_SESSION_ERRORS',
  RECEIVE_ALL_REQUESTS: 'RECEIVE_ALL_REQUESTS',
  CLEAR_ALL_REQUESTS: 'CLEAR_ALL_REQUESTS',
  RECEIVE_A_REQUEST: 'RECEIVE_A_REQUEST',
  RECEIVE_BATCH_REQUEST: 'RECEIVE_BATCH_REQUEST',
  UPDATE_CURRENT_USER: 'UPDATE_CURRENT_USER',
  UPDATE_KEY: 'UPDATE_KEY',
  RECEIVE_INVITE_USER: 'RECEIVE_INVITE_USER',
  RECEIVE_INVITATION_CONFIRMATION: 'RECEIVE_INVITATION_CONFIRMATION',
  RECEIVE_INVITE_DATA: 'RECEIVE_INVITE_DATA',
  RECEIVE_SENT_INVITATIONS: 'RECEIVE_SENT_INVITATIONS',

  // RATE LIMIT TYPES
  RECEIVE_RATE_LIMIT: 'RECEIVE_RATE_LIMIT',
  ADD_PROCESSED_PAGES: 'ADD_PROCESSED_PAGES',

  // Notification types
  OPEN_NOTIFICATION_CENTER: 'OPEN_NOTIFICATION_CENTER',
  CLOSE_NOTIFICATION_CENTER: 'CLOSE_NOTIFICATION_CENTER',
  SWITCH_NOTIFICATION_CENTER: 'SWITCH_NOTIFICATION_CENTER',
  RECEIVED_NOTIFICATION: 'RECEIVED_NOTIFICATION',
  FETCH_ALL_NOTIFICATIONS: 'FETCH_ALL_NOTIFICATIONS',
  SET_NOTIFICATION_AS_READ: 'SET_NOTIFICATION_AS_READ',
  FETCH_UNREAD_COUNT: 'FETCH_UNREAD_COUNT',
  RECEIVE_NOTIFICATION_ERRORS: 'RECEIVE_NOTIFICATION_ERRORS',

  // Math Types
  ADDMATHUSAGEINFO: 'ADDMATHUSAGEINFO',

  //GeoInfo Types
  SHOWGEOINFO: 'SHOWGEOINFO',

  // Requests_OCR Types
  RECEIVE_BATCH_REQUEST_OCR: 'RECEIVE_BATCH_REQUEST_OCR',

  //Request count info
  ADDREQUSAGEINFO: 'ADDREQUSAGEINFO',

  //Admin Ops
  RECEIVE_ALL_USERS: 'RECEIVE_ALL_USERS',
  RECEIVE_ADMIN_DATA: 'RECEIVE_ADMIN_DATA',
  RECEIVE_ADMIN_COMPANY_DETAILS: 'RECEIVE_ADMIN_COMPANY_DETAILS',

  // Search Types
  RECEIVE_SEARCH_DATA: 'RECEIVE_SEARCH_DATA',

  //Invoice Types
  SAVE_INVOCIE_GEN: 'SAVE_INVOCIE_GEN',

  //Error types
  RECEIVE_FETCH_ERROR: 'RECEIVE_FETCH_ERROR',
  RECEIVE_SESSION_ERRORS: 'RECEIVE_SESSION_ERRORS',

  // Choose a language
  CHOOSE_A_LANGUAGE: 'CHOOSE_A_LANGUAGE',

  // Loading reducer
  LOADING_STATUS: 'LOADING_STATUS',

  // Filebox reducer
  MODIFY_PATH: 'MODIFY_PATH',

  // Display download progress
  DOWNLOAD_PROGRESS: 'DOWNLOAD_PROGRESS',

  // Guest user usage data
  GUEST_USAGE: 'GUEST_USAGE',
}
