import React from 'react';
// import '../../assets/css/components/app.css';
import './navbar.css';
import { withRouter, Link } from 'react-router-dom';

import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';

import { MdPowerSettingsNew } from '@react-icons/all-files/md/MdPowerSettingsNew';
import { BsBellFill } from '@react-icons/all-files/bs/BsBellFill';
import { FaServer } from '@react-icons/all-files/fa/FaServer';
import { FaSearch } from '@react-icons/all-files/fa/FaSearch';

import {LanguagesSelect} from '../LanguagesDropDown';

import Alert from 'react-bootstrap/Alert';

import {Loading} from '../loading';

import DownloadCirclePro from '../DownloadCirclePro';

import Search from './search';

import UserPortrait from '../UserProfile/UserPortrait';

import {translate} from '../../helpers/translate';


class Navbar2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userID: 0,
      ServerStatus: 'init',
      UserPortraitDropDown: false,
    }

    this.handleLogout = this.handleLogout.bind(this);
    this.handleResetPassword = this.handleResetPassword.bind(this);
    this.handleSelectOutput = this.handleSelectOutput.bind(this);
    this.displayError = this.displayError.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
}

  componentWillMount() {
    if (this.props.currentUser){
      let userID = this.props.currentUser.id;
      // if (localStorage.getItem(`Language`)){
      //   this.props.ChooseLanguage(localStorage.getItem(`Language`));
      // }

      // Get the notifications
      this.props.fetchAllNotifications();
      this.props.fetchUnreadCount();

      // Get rate limit
      this.props.getRateLimit();

      this.setState({userID: userID})
    }
    // console.log('this.props.currentUser: ', this.props.currentUser);
    if (this.props.currentUser){
      if (this.props.currentUser.role === 'Admin'){
        if (this.props.socket){
          this.props.socket.on('ML_STATUS', data=>{
            if (data != this.state.ServerStatus){
              this.setState({ServerStatus: data});
            }
          })
        }
      }
    }
  }

  handleClickOutside(event) {
    if (this.state.UserPortraitDropDown){
      this.setState({UserPortraitDropDown: false});
    }
  }

  handleNotificationModal(e) {
    // Responsible for opening/closing the notification modal
    e.preventDefault();
    this.props.switchNotificationCenter();
  }

  redirectToSearch(e) {
    e.preventDefault();
    this.props.history.push('/search');
  }

  handleLogout(e) {
    e.preventDefault();
    this.props.logout();
    this.props.history.push('/');
  }

  handleResetPassword(e) {
    console.log('reset password');
    
    e.preventDefault();
    this.props.history.push('/update-password');
  }

  handleSelectOutput(e){
    this.props.ChooseLanguage(e.target.value);
    localStorage.setItem(`Language`, e.target.value);
    location.reload();
  }

  async stripeRegister(e) {
    e.preventDefault();
    if (!this.props.currentUser.customerID){
      // create customerID
      const {customer} = await this.props.simplepost({'email': this.props.currentUser.email, 'name': this.props.currentUser.name}, '/create-customer');
      if (customer.id) {
        await this.props.updateUser({customerID: customer.id});
        this.props.history.push('/account-details');
      }
    } else {
      this.props.history.push('/account-details');
    }
  }
  
  displayLanguageSelect(){
    return (
      <LanguagesSelect current_lang={localStorage.getItem(`Language`)} 
                    selectOutput={this.handleSelectOutput} 
                    loggedIn={this.props.currentUser}/>
    )
  }

  displayUpgrade(){
    if (this.props.currentUser.membership === 'basic') {
      return (
        <button style={{margin: '6px 10px'}} className='UpgradeButton' onClick={(e)=>this.stripeRegister(e)}>
          {translate('Upgrade')}
        </button>
      )
    }
  }

  displayMLServerStatus(){
    if (this.props.currentUser){
      if (this.props.currentUser.role === 'Admin'){
      return (
        <div className="notificationButton Mytooltip" style={{cursor: 'unset', marginLeft: 'auto'}}>
          {this.state.ServerStatus==='ping'?
            <span className="notificationCircle ServerRed" />:
              this.state.ServerStatus==='init'?
                <span className="notificationCircle ServerYellow" />:
                <span className="notificationCircle ServerGreen" />}
          <FaServer size="1.8em" />
          <span className='Mytooltiptext'>
            {this.state.ServerStatus==='ping'?
              'Offline':
              this.state.ServerStatus==='init'?
                'Connecting':'Online'}
          </span>
        </div>
      )}
    }
  }

  displayButton() {    
    const { currentUser }  = this.props;
    if (currentUser && currentUser.isAuthenticated) {
      const {resetedPassword, name, avatar} = currentUser;
      if (resetedPassword){
        // console.log('this.props.SearchResults: ', this.props.SearchResults);
        return (
          // <Nav className="justify-content-end" variant="pills" >
          <div style={{display: 'flex'}}>
            {/* <div className="notificationButton" onClick={(e) => this.redirectToSearch(e)}>
              <FaSearch size="1.8em" />
            </div> */}
            <Search doSearch={this.props.doSearch} SearchResults={this.props.SearchResults} />
            {this.displayUpgrade()}
            {this.displayMLServerStatus()}
            <div className="notificationButton" onClick={(e) => this.handleNotificationModal(e)}>
              { this.props.unreadCount > 0 ? <span className="notificationCircle">{this.props.unreadCount}</span> : '' }
              <BsBellFill size="1.8em" />
            </div>
            {this.displayLanguageSelect()}
            <div className='Clickable' onClick={()=> this.setState({UserPortraitDropDown: !this.state.UserPortraitDropDown})}>
              <UserPortrait fullname={name} avatar={avatar} fontsize="1em"/>
              {this.state.UserPortraitDropDown?
                <div className="PortraitDropDown">
                  <div className='Clickable LOGOUT' onMouseDown={(e)=>this.handleLogout(e)}>
                    <strong>{translate('LOG OUT')}</strong>
                  </div>
                </div>:null
              }
            </div>
            {/* <Button className='AllButton' variant="outline-dark" onClick={(e)=>this.handleLogout(e)}>
              <MdPowerSettingsNew/>
              <Translate text='LOGOUT'/>
            </Button> */}
          </div>
          // </Nav>
        )
      } else {
        //first time log in, ask for create a password
      }
    } else  {
      return (
        <Nav className="justify-content-end" variant="pills" >
          {this.displayLanguageSelect()}
        </Nav>
      )
    }
  }

  displayError() {
    if (this.props.error.length > 0) {
      let clearErrors = this.props.clearErrors || null;

      setTimeout(() => {
        if (clearErrors) clearErrors();
        store.dispatch({ type: 'LOADING_STATUS', data: false });
      }, 3000);
      
      return (
        <Alert variant="danger" className="UniError">
          {String(this.props.error.length) > 200?"Oops! Something went wrong.":this.props.error}
        </Alert>
      );
    }
    
    return null;
  }

  render() {    
    return (
      <div  className='Navbar'>
        {this.props.loading ? <Loading /> : null}

        <this.displayError/>
        
        {this.displayButton()}
        {this.props.DownloadProgress?
          <DownloadCirclePro percentage={this.props.DownloadProgress} />
          :null}
      </div>

    );
  }
}

export default withRouter(Navbar2);
