import React from 'react';

import { withRouter } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';

import { MdModeEdit } from '@react-icons/all-files/md/MdModeEdit';
import { MdDoneAll } from '@react-icons/all-files/md/MdDoneAll';
import { FaExclamation } from '@react-icons/all-files/fa/FaExclamation';
import { FaShareSquare } from '@react-icons/all-files/fa/FaShareSquare';
import { MdFullscreen } from '@react-icons/all-files/md/MdFullscreen';
import { TiChevronLeft } from '@react-icons/all-files/ti/TiChevronLeft';
import { TiChevronRight } from '@react-icons/all-files/ti/TiChevronRight';
import { FaSearch } from '@react-icons/all-files/fa/FaSearch';
import { BiExport } from '@react-icons/all-files/bi/BiExport';

// Others
import { zfill } from '../../helpers/others';
import ActionMenu from '../ActionMenu';
import { string2csv } from '../../helpers/csv_convert';
import { IMGLoading } from '../loading';

import { Document, Packer, Paragraph, TextRun } from "docx";

import OCRResultModal from './components/OCRResultModal';

import "babel-polyfill";

import { translate } from '../../helpers/translate';


class DetailViewTMPLTM extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            meta: {},
            data: {},
            FolderSetting: {},
            title: '',
            editing: false,
            searchTyping: false,
            highlights: [],
            current_page: 0,
            pageData: [],
            current_pageData: null,
            HideEmpty: false,

            active_tab: '1',
            isImgLoading: true,
            SubImgLoadedList: [],
            isFullScreen: false,
            SearchMatches: [],

            ActionMenuLoc: null,

            promptResult: '',
        }
        this.SendUpdate = this.SendUpdate.bind(this);
        this.ChangeVerify = this.ChangeVerify.bind(this);
        this.FlipPage = this.FlipPage.bind(this);
        this.export2excel = this.export2excel.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.HandleSingleHighlight = this.HandleSingleHighlight.bind(this);
        this.HandleExportPDF = this.HandleExportPDF.bind(this);
        this.HandleExportWord = this.HandleExportWord.bind(this);
        this.HandleAction = this.HandleAction.bind(this);
    }

    componentDidMount() {
        document.addEventListener("keydown", this.handleKeyPress, false);

        document.getElementById('IMG_SVG_MASK').addEventListener("fullscreenchange", () => {
            if (document.fullscreenElement) {
                this.setState({ isFullScreen: true })
            } else {
                this.setState({ isFullScreen: false })
            }
        }, false);
        document.getElementById('IMG_SVG_MASK').addEventListener("mozfullscreenchange", () => {
            if (document.fullscreenElement) {
                this.setState({ isFullScreen: true })
            } else {
                this.setState({ isFullScreen: false })
            }
        }, false)
        document.getElementById('IMG_SVG_MASK').addEventListener("webkitfullscreenchange", () => {
            if (document.fullscreenElement) {
                this.setState({ isFullScreen: true })
            } else {
                this.setState({ isFullScreen: false })
            }
        }, false)
        document.getElementById('IMG_SVG_MASK').addEventListener("msfullscreenchange", () => {
            if (document.fullscreenElement) {
                this.setState({ isFullScreen: true })
            } else {
                this.setState({ isFullScreen: false })
            }
        }, false)
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyPress, false);
    }

    componentWillMount() {
        // Info from POSTGRESQL
        let title = this.props.item.title;
        let page_index = this.props.page_index ? this.props.page_index : 0;
        // Data from MONGODB for each page
        let pageData = this.props.data;

        let first_pageData, list_pageData, last_pageData;
        if (Array.isArray(pageData[0])) {
            first_pageData = pageData[0][page_index];
            list_pageData = pageData[0];
            last_pageData = pageData[0].slice(-1)[0];
        } else {
            first_pageData = pageData[page_index];
            list_pageData = pageData;
            last_pageData = pageData.slice(-1)[0];
        };

        let promptResult = last_pageData ? last_pageData.promptResult : null || '';

        let FolderSetting = pageData.FolderSetting ? pageData.FolderSetting : {};

        console.log('pageData: ', pageData);
        // console.log('last_pageData: ', last_pageData);
        // console.log('promptResult: ', promptResult);

        this.setState({
            meta: this.GetPageMeta(pageData, page_index),
            // pageData: pageData,
            pageData: list_pageData,
            current_page: page_index,
            current_pageData: first_pageData,
            title: title,
            FolderSetting: FolderSetting,
            promptResult,
        })
    }

    handleKeyPress(e) {
        if (this.state.editing || this.state.searchTyping) {
            return
        }
        if (e.keyCode === 38) {
            // up key
            this.FlipPage(event, 'up');
        } else if (e.keyCode === 37) {
            // left key
            this.FlipPage(event, 'up');
        } else if (e.keyCode === 39) {
            // right key
            this.FlipPage(event, 'down');
        } else if (e.keyCode === 40) {
            // down key
            this.FlipPage(event, 'down');
        }
    }

    HandleActionMenu(e) {
        e.preventDefault();
        e.stopPropagation();
        let dim = e.target.getBoundingClientRect();

        if (this.state.ActionMenuLoc) {
            this.setState({ ActionMenuLoc: null })
        } else {
            this.setState({ ActionMenuLoc: [dim.left, dim.top + dim.height] })
        }
    }

    HandleAction(Action, info) { //'Export PDF for this page only','Export PDF for all pages'
        switch (Action) {
            case translate('Export to PDF for this page'):
                this.HandleExportPDF(this.state.current_page + 1);
                break;
            case translate('Export to PDF for all pages'):
                this.HandleExportPDF('All')
                break;
            case translate('Export to Word for this pages'):
                this.HandleExportWord()
                break;
            default:
                null
        };
        this.setState({ ActionMenuLoc: null });
    }

    HandleExportPDF(page_idx) {
        console.log('export to pdf');
        let data = {
            'FileIDs': [this.props.item.FileID],
            'ChooseWhat': 'PDF',
            'Pages': String(page_idx),
        }
        // 'OutputName': this.props.item.title.replace(/\.[^/.]+$/, "")};
        this.props.simplepost({ 'data': JSON.stringify(data) }, '/ftree/MultiExport2excel').then(async res => {
            if (res) {
                let url = res;
                let extension = url.split('.').pop();
                let resp = await fetch(url, { method: 'GET' });
                const reader = resp.body.getReader();
                const contentLength = +resp.headers.get('Content-Length');
                let receivedLength = 0;
                let chunks = [];
                while (true) {
                    const { done, value } = await reader.read();
                    if (done) {
                        break;
                    }
                    chunks.push(value);
                    receivedLength += value.length;
                    let download_percentage = Math.round(receivedLength / contentLength * 100);
                    if (download_percentage % 10 === 0) {
                        store.dispatch({ type: 'DOWNLOAD_PROGRESS', data: download_percentage });
                    }
                };
                let blob = new Blob(chunks);
                let newurl = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = newurl;
                console.log('newurl: ', newurl);
                a.download = `${this.props.item.title.replace(/\.[^/.]+$/, "")}.pdf`
                a.click();
            };
            setTimeout(() => {
                store.dispatch({ type: 'LOADING_STATUS', data: false });
                this.setState({ ExportViewEnabled: false });
            }, 500);
        })

    }

    async HandleExportWord() {
        // const doc = null;
        const doc = new Document({
            sections: [{
                properties: {},
                children: [
                    new Paragraph({
                        children: [
                            new TextRun(this.state.current_pageData['raw_text'])
                        ],
                    }),
                ],
            }]
        })
        const buffer = await Packer.toBuffer(doc);
        const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${this.props.item.title.replace(/\.[^/.]+$/, "")}.docx`;
        link.click();
    }

    GetPageMeta(pageData, page_index) {
        let keyList = Object.keys(pageData[page_index]);
        let metaKeyList = ['FileID', '_id', '__v', 'TimeStamp', 'createdAt', 'updatedAt', 'status', 'Thumb', 'ROUTE'];
        let metaData = keyList.filter(key => metaKeyList.includes(key)).reduce((obj, key) => { obj[key] = pageData[page_index][key]; return obj }, {});
        return metaData
    }

    SendUpdate() {
        setTimeout(() => {
            this.setState({
                editing: false,
            })
        }, 900);
        let pageData = this.state.current_pageData;
        Object.assign(pageData, { VerifiedBy: this.props.currentUser });
        this.props.simplepost({ 'UpdateMongoFile': JSON.stringify(pageData) }, '/ftree/updateMongoFile').then(res => {
            if (res) {
                this.setState({
                    editing: false
                })
            }
        })
    }

    ChangeVerify() {
        let pageData = this.state.current_pageData;
        let VerifiedStatus = pageData.VerifiedBy;
        Object.assign(pageData, { VerifiedBy: VerifiedStatus ? null : this.props.currentUser });

        this.props.simplepost({ 'UpdateMongoFile': JSON.stringify(pageData) }, '/ftree/updateMongoFile').then(res => {
            if (res) {
                this.setState({
                    editing: false
                })
            }
        });
    }

    HighlightSignature() {
        this.HandleOtherHighlight(this.state.current_pageData['Signature'])
    }
    HighlightStamp() {
        this.HandleOtherHighlight(this.state.current_pageData['Stamp'])
    }

    HandleSearch(e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.target.value.length > 0) {
            var searches = e.target.value.split(" ").filter(String);
            var regex = this.GenerateRegExp(searches);
            let All_matches_res = [];
            this.state.pageData.forEach((Current_pageData, pageIndex) => {
                let ky_pairs = Current_pageData['ky_pairs'];
                let matches = ky_pairs.filter((i) => {
                    const r = new RegExp(regex, 'gi');
                    return r.test(i.value_txt)
                });
                if (matches.length > 0) {
                    All_matches_res.push({ 'PageIndex': pageIndex, 'matches': matches });
                }
            });
            this.setState({ SearchMatches: All_matches_res })
        } else {
            this.setState({ SearchMatches: [] })
        }
    }

    GenerateRegExp(arr) {
        var s = "";
        arr.forEach((e) => {
            s += "(?=.*" + this.EscapeRegExp(e) + ")";
        });
        return s += ".*";
    }

    EscapeRegExp(str) {
        return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
    }


    updateData(e, ky_idx, key_or_value) {
        let current_pageData = this.state.current_pageData;
        let newData = e.target.value;
        // update ky_pairs
        let ky_pairs_lst = current_pageData['ky_pairs'];
        let CurrentData = ky_pairs_lst[ky_idx];
        if (!Object.keys(CurrentData).includes(`${key_or_value}_old`)) {
            CurrentData[`${key_or_value}_old`] = CurrentData[`${key_or_value}`];
            CurrentData[`${key_or_value.replace('txt', 'conf')}_old`] = CurrentData[`${key_or_value.replace('txt', 'conf')}`];
        }
        CurrentData[`${key_or_value}`] = newData;
        CurrentData[`${key_or_value.replace('txt', 'conf')}`] = 100;

        // update LabelIMG_LIST
        let LabelIMG_LIST = current_pageData['LabelIMG_LIST'];
        let CurrentLabel = LabelIMG_LIST ? LabelIMG_LIST[ky_idx] : {};
        CurrentLabel['NewRes'] = newData;

        const update = (arr, idx, itm, ...rest) => {
            arr[idx] = itm;
            if (rest.length)
                update(arr, ...rest);
        }


        update(ky_pairs_lst, ky_idx, CurrentData);
        if (LabelIMG_LIST) { // old version
            update(LabelIMG_LIST, ky_idx, CurrentLabel);
        }

        current_pageData['ky_pairs'] = ky_pairs_lst;
        if (LabelIMG_LIST) { // old version
            current_pageData['LabelIMG_LIST'] = LabelIMG_LIST;
        }

        this.setState({
            current_pageData
        })
    }

    handleSelect(key) {
        this.setState({
            active_tab: key,
            highlights: []
        })
    }

    GetColor(conf, ky, ky_idx) {
        let threashold;
        if (Object.keys(this.state.FolderSetting).length > 0) {
            let uid = ky['uid'] ? ky['uid'] : zfill(ky_idx, 3);
            let AllConfigs = this.state.FolderSetting.config
            let cell_config = AllConfigs ? AllConfigs.filter(x => x.uid === uid)[0] : null;
            let sensitive = cell_config ? cell_config['sensitive'] ? cell_config['sensitive'] : 'Default' : 'Default';
            threashold = sensitive === 'Extremely high' ? 95 : sensitive === 'High' ? 65 : sensitive === 'Default' ? 45 : 5;
        } else {
            if (Object.keys(ky).includes('sensitive')) {
                threashold = ky['sensitive'];
            } else {
                threashold = 20
            }
        }
        if (conf >= threashold) {
            return 'unset'
        } else if (conf > threashold / 2 && conf < threashold) {
            return '#efef7c'
        } else if (conf <= threashold / 2) {
            return '#ff8484'
        }
    }

    HandleKYPairHighlight(ky_pair) {
        let key_block_Geo = ky_pair['key_geo'];
        let value_block_Geo = ky_pair['value_geo'];
        let key_block_rect = [key_block_Geo[0], key_block_Geo[1], key_block_Geo[2] - key_block_Geo[0], key_block_Geo[3] - key_block_Geo[1]] // x y w h
        let value_block_rect = [value_block_Geo[0], value_block_Geo[1], value_block_Geo[2] - value_block_Geo[0], value_block_Geo[3] - value_block_Geo[1]] // x y w h
        this.setState({
            highlights: [key_block_rect, value_block_rect]
        }, () => {
            if (document.getElementById('ScrollPoint')) {
                var ChildElement = document.getElementById('ScrollPoint');
                var PareElement = document.getElementById('HighlightScroll');
                var y_relative = ChildElement.getBoundingClientRect().y;
                var Offheight = PareElement.offsetHeight;
                document.getElementById('HighlightScroll').scrollTop = y_relative - Offheight / 2;
            }
        });
    }

    HandleOtherHighlight(table_detail) {
        if (!table_detail) {
            return null
        };
        if (!Array.isArray(table_detail)) {
            table_detail = [table_detail];
        };
        let highlights = [];
        for (let i = 0; i < table_detail.length; i++) {
            let table_Geo = table_detail[i]['geo'];
            let table_block_rect = [table_Geo[0], table_Geo[1], table_Geo[2] - table_Geo[0], table_Geo[3] - table_Geo[1]]; // x y w h
            highlights.push(table_block_rect)
        }
        this.setState({
            highlights: highlights
        }, () => {
            if (document.getElementById('ScrollPoint')) {
                var ChildElement = document.getElementById('ScrollPoint');
                var PareElement = document.getElementById('HighlightScroll');
                var y_relative = ChildElement.getBoundingClientRect().y;
                var Offheight = PareElement.offsetHeight;
                document.getElementById('HighlightScroll').scrollTop = y_relative - Offheight / 2;
            }
        });
    }

    HandleSingleHighlight(GEO) {
        console.log('GEO: ', GEO);
        let rect = [GEO[0], GEO[1], GEO[2] - GEO[0], GEO[3] - GEO[1]];
        this.setState({
            highlights: [rect]
        }, () => {
            if (document.getElementById('ScrollPoint')) {
                var ChildElement = document.getElementById('ScrollPoint');
                var PareElement = document.getElementById('HighlightScroll');
                var y_relative = ChildElement.getBoundingClientRect().y;
                var Offheight = PareElement.offsetHeight;
                document.getElementById('HighlightScroll').scrollTop = y_relative - Offheight / 2;
            }
        })
    }

    FlipPage(e, page_idx) {
        e.preventDefault();
        let current_page
        if (page_idx === 'up') {
            current_page = this.state.current_page > 0 ? this.state.current_page - 1 : 0;
        } else if (page_idx === 'down') {
            current_page = this.state.current_page < this.state.pageData.length - 1 ? this.state.current_page + 1 : this.state.pageData.length - 1;
        } else {
            current_page = page_idx;
        };
        console.log('filp to: ', page_idx);
        let current_pageData = this.state.pageData[current_page];
        if (current_page != this.state.current_page) {
            this.setState({
                current_page, current_pageData,
                meta: this.GetPageMeta(this.state.pageData, current_page),
                SubImgLoadedList: [],
                isImgLoading: (this.state.current_page > 0 && this.state.current_page < this.state.pageData.length - 1) ? true : false,
                highlights: []
            }, () => {
                if (document.getElementById('PageScrollPoint')) {
                    var ChildElement = document.getElementById('PageScrollPoint');
                    var PareElement = document.getElementById('PageIndexListParent');
                    var topPos = ChildElement.offsetTop;
                    var Offheight = PareElement.offsetHeight;
                    document.getElementById('PageIndexListParent').scrollTop = topPos - Offheight / 2;
                }
            });
        }
    }

    export2excel() {
        console.log('here is export to excel');
        let ExportData = Object.assign(this.state.meta, { current_page: this.state.current_page });
        this.props.simplepost({ 'data': JSON.stringify(ExportData) }, '/ftree/export2excel').then(res => {
            if (res) {
                console.log('export excel res: ', res);
                let url = res;
                let a = document.createElement('a');
                a.href = url;
                a.download = 'output.xlsx';
                a.click();
            }
        })
    }

    fullscreen(e) {
        let div = document.getElementById('IMG_SVG_MASK');
        this.setState({ highlights: [] });
        if (div.requestFullscreen)
            div.requestFullscreen();
        else if (div.webkitRequestFullscreen)
            div.webkitRequestFullscreen();
        else if (div.msRequestFullScreen)
            div.msRequestFullScreen();
    }

    displayTabContent() {
        // This render is for fix format only
        let LabelIMG_LIST = Object.keys(this.state.current_pageData).includes('LabelIMG_LIST') ? this.state.current_pageData['LabelIMG_LIST'] : [];

        let active_tab = this.state.active_tab;
        let raw_text = this.state.current_pageData['raw_text'] ? this.state.current_pageData['raw_text'] : null;
        let ky_pairs = Object.keys(this.state.current_pageData).includes('ky_pairs') ? this.state.current_pageData['ky_pairs'] : [];
        let table_data = Object.keys(this.state.current_pageData).includes('Table') ? this.state.current_pageData['Table'] : [];

        if (!raw_text && active_tab === '1') {
            this.setState({ active_tab: '2' })
        }

        return (
            <div className='fullHeight'>
                <article className="PlainText" style={this.state.active_tab === '1' ? null : { display: 'none' }}>
                    <div>
                        <p>{raw_text ? raw_text : 'Text not available'}</p>
                    </div>
                </article>
                {ky_pairs.length > 0 ?
                    <div className="WordsContainerFixFormat">
                        <Table striped bordered hover style={{ display: this.state.active_tab === '2' ? null : 'none' }}>
                            <thead>
                                <tr className='sticky'>
                                    <th>Key Name</th>
                                    <th>Result</th>
                                    {Object.keys(ky_pairs[0]).includes('value_img') || LabelIMG_LIST > 0 ? <th>Image</th> : null}
                                </tr>
                            </thead>
                            <tbody>
                                {ky_pairs.map((ky, ky_idx) => {
                                    return (
                                        // (this.state.HideEmpty && ky['value_conf'] === -1)
                                        this.state.HideEmpty && (ky['value_conf'] === 99 || ky['value_conf'] === -1) && ky['value_txt'] === ''
                                            ?
                                            null
                                            :
                                            <tr onClick={(e) => { this.HandleKYPairHighlight(ky) }}>
                                                <th style={{ backgroundColor: `${this.GetColor(ky['key_conf'], ky, ky_idx)}` }}>
                                                    {ky['key_txt']}
                                                </th>
                                                <td style={{ backgroundColor: `${this.GetColor(ky['value_conf'], ky, ky_idx)}` }}>
                                                    {this.state.editing
                                                        ?
                                                        <Form.Control value={ky['value_txt']} onChange={(e) => { this.updateData(e, ky_idx, 'value_txt') }} />
                                                        :
                                                        ky['value_txt']}
                                                </td>
                                                {Object.keys(ky).includes('value_img') || LabelIMG_LIST > 0 ?
                                                    <td style={{ position: 'relative', padding: '0.3rem' }}>
                                                        {!this.state.SubImgLoadedList.includes(ky_idx) ? <span className="SubImgLoading strip" /> : null}
                                                        <img className='LabelSubimage'
                                                            src={ky['value_img'] ? ky['value_img'] : this.state.current_pageData['LabelIMG_LIST'][ky_idx]['ImageURL']}
                                                            onLoad={() => { this.setState(prevState => ({ SubImgLoadedList: [...prevState.SubImgLoadedList, ky_idx] })); }} />
                                                    </td>
                                                    : null}
                                            </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </div>
                    :
                    <div className="SYSINFO">No information available</div>
                }
                <Table striped bordered hover style={{ marginTop: '1rem', display: this.state.active_tab === '3' ? null : 'none' }}>
                    {table_data.map((ky, idx) => {
                        return (
                            <tbody>
                                {Object.keys(ky).includes('CSV_STRING') ?
                                    string2csv(ky['CSV_STRING']).map(RowList => {
                                        return (
                                            <tr onClick={(e) => { this.HandleOtherHighlight(ky) }}>
                                                {RowList.map(item => {
                                                    return (<td colSpan={RowList.length === 1 ? '100%' : '1'}>{item}</td>)
                                                })}
                                            </tr>
                                        )
                                    })
                                    :
                                    <tr onClick={(e) => { this.HandleOtherHighlight(ky) }}>
                                        <th style={{ backgroundColor: `${this.GetColor(ky['conf'])}` }}>{`Table ${idx}`} info currently unavailable, click to find it on image</th>
                                    </tr>}
                            </tbody>
                        )
                    })}
                </Table>
            </div>
        )
    }

    render() {
        return (
            <Container fluid className='detailDocContainer'>
                {this.state.ActionMenuLoc ?
                    <ActionMenu
                        Location={this.state.ActionMenuLoc}
                        ActionList={[translate('Export to PDF for this page'), translate('Export to PDF for all pages'), translate('Export to word for this pages')]}
                        HandleAction={this.HandleAction}
                        handleDisappear={() => { this.setState({ ActionMenuLoc: null }) }} />
                    : null}
                <Row className='fullHeight'>
                    <Col style={{ maxWidth: '5em', padding: '0px 0px 0px 0px', height: '100%' }}>
                        <div className='PageIndexListParent' id='PageIndexListParent'>
                            {
                                this.state.pageData.map((ky, ky_idx) => {
                                    return (
                                        <div className="PageIndexList" id={ky_idx === this.state.current_page ? 'PageScrollPoint' : null}
                                            style={ky_idx === this.state.current_page ? { border: '5px solid #27235cad' } : { cursor: 'pointer' }}
                                            onClick={(e) => { this.FlipPage(e, ky_idx) }}>
                                            <strong>{ky_idx + 1}</strong>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </Col>
                    <Col style={{ height: '100%', padding: '0px', width: 'calc(50% - 5em)', backgroundColor: '#808080' }}>
                        <Row style={{ height: '2rem', backgroundColor: '#3c3c3c', color: 'snow' }}>
                            <Col sm={8} style={{ margin: 'auto' }}><h5 className="ImageViewTitle">{this.state.title}</h5></Col>
                            <Col sm={4} style={{ margin: 'auto' }}><div className="ToggleFullScreen pull-right" onClick={(e) => this.fullscreen(e)}><MdFullscreen /></div></Col>
                        </Row>
                        <Row style={{ position: 'absolute', top: '2rem', bottom: '0px' }}>
                            <div id="HighlightScroll" style={{ height: '100%', overflow: 'auto', scrollBehavior: "smooth", border: "2px solid gray", backgroundColor: 'gray' }}>
                                <div className={this.state.isFullScreen ? 'IMG_SVG_MASK_FULLSCREEN' : 'IMG_SVG_MASK'} id='IMG_SVG_MASK'>
                                    {this.state.isImgLoading ? <div className="IMGLoading"><IMGLoading /></div> : null}
                                    {this.state.current_pageData && this.state.current_pageData['IMG_URL'].length > 0 ?
                                        <img src={this.state.current_pageData['IMG_URL']} onLoad={() => this.setState({ isImgLoading: false })} />
                                        : <div className="NotFound"><h2>Image Not Found</h2></div>
                                    }
                                    <svg viewBox="0 0 100 100" preserveAspectRatio="none">
                                        {this.state.highlights.map(lst => {
                                            return <rect id='ScrollPoint' x={lst[0] * 100} y={lst[1] * 100} width={lst[2] * 100} height={lst[3] * 100} fill="#ffc857" fill-opacity="0.5" />
                                        })}
                                    </svg>
                                    {this.state.isFullScreen ?
                                        <div className="FullScreenNav">
                                            <TiChevronLeft />
                                            <span>{`${this.state.current_page + 1}/${this.state.pageData.length}`}</span>
                                            <TiChevronRight />
                                        </div>
                                        : null}
                                </div>
                            </div>
                        </Row>
                    </Col>
                    <Col style={{ height: '100%', display: 'flex', flexFlow: 'column', paddingRight: '0', minWidth: '50%' }}>
                        {Object.keys(this.state.current_pageData).includes('json_res') ? null :
                            this.state.current_pageData['ky_pairs'].length === 0 ? null :
                                <Row>
                                    {this.state.editing
                                        ?
                                        <ButtonGroup style={{ marginRight: '10px' }}>
                                            <Button variant="primary" onClick={this.SendUpdate}>Confirm</Button>
                                            <Button variant="outline-primary" onClick={() => { this.setState({ editing: false }) }}>{translate('Cancel')}</Button>
                                        </ButtonGroup>
                                        :
                                        <div style={{ display: 'flex', width: '100%' }}>
                                            <ButtonGroup>
                                                <Button variant="outline-primary" onClick={() => { this.setState({ editing: true }) }}>
                                                    <MdModeEdit style={{ verticalAlign: 'middle', fontSize: 'small' }} />{translate('Edit')}
                                                </Button>
                                                {Object.keys(this.state.current_pageData).includes('VerifiedBy') ?
                                                    this.state.current_pageData['VerifiedBy'] ?
                                                        <Button variant="primary" onClick={() => this.ChangeVerify()}>
                                                            <MdDoneAll style={{ verticalAlign: 'middle', fontSize: 'small' }} />{translate('Verified')}
                                                        </Button>
                                                        :
                                                        <Button variant="outline-primary" onClick={() => this.ChangeVerify()}>
                                                            <FaExclamation style={{ verticalAlign: 'middle', fontSize: 'small' }} />{translate('Verify')}
                                                        </Button>
                                                    :
                                                    <Button variant="outline-primary" onClick={() => this.ChangeVerify()}>
                                                        <FaExclamation style={{ verticalAlign: 'middle', fontSize: 'small' }} />{translate('Verify')}
                                                    </Button>
                                                }
                                                <Button variant="outline-primary" onClick={() => this.export2excel()}>
                                                    <FaShareSquare style={{ verticalAlign: 'middle', fontSize: 'small' }} />{translate('Export')}
                                                </Button>
                                            </ButtonGroup>
                                            <InputGroup style={{ marginLeft: '1rem' }}>
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text><FaSearch /></InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <FormControl onFocus={() => this.setState({ searchTyping: true })}
                                                    onBlur={() => this.setState({ searchTyping: false })}
                                                    onChange={(e) => this.HandleSearch(e)}
                                                    type="search" placeholder="Search result" />
                                                {this.state.SearchMatches.length > 0 ?
                                                    <div className="SearchDropDown">
                                                        {this.state.SearchMatches.map((x, SearchIdx) => {
                                                            return (
                                                                <div className={"SearchResultSection" + " StripColor" + SearchIdx % 2}>
                                                                    Page {x.PageIndex + 1} {x.matches.map(y => {
                                                                        return (
                                                                            <div className="SearchItem Clickable"
                                                                                onClick={(e) => {
                                                                                    this.FlipPage(e, x.PageIndex);
                                                                                    setTimeout(() => { this.HandleKYPairHighlight(y); }, 500);
                                                                                }}>
                                                                                {y.key_txt}: {y.value_txt}
                                                                            </div>)
                                                                    })}</div>
                                                            )
                                                        })}
                                                    </div>
                                                    : this.state.searchTyping ? <div className="SearchDropDown">
                                                        <div className="NoResult">No result found, try something else...</div>
                                                    </div> : null}
                                            </InputGroup>
                                        </div>
                                    }
                                    <label className='CheckContainer'>Hide empty.
                                        <input type="checkbox" onChange={(e) => { this.setState({ HideEmpty: !this.state.HideEmpty }) }} checked={this.state.HideEmpty} />
                                        <span className="checkmark"></span>
                                    </label>
                                </Row>}
                        {Object.keys(this.state.current_pageData).includes('json_res') ?
                            <Nav variant="tabs" justified defaultActiveKey="2">
                                <Nav.Item eventKey='1' style={{ zIndex: '2' }}>
                                    <Nav.Link eventKey="1" active={this.state.active_tab === '1'} onClick={() => this.handleSelect('1')}>{translate('Text')}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item eventKey='2' style={{ zIndex: '2' }}>
                                    <Nav.Link eventKey="2" active={this.state.active_tab === '2'} onClick={() => this.handleSelect('2')}>{translate('Words')}</Nav.Link>
                                </Nav.Item>
                                {Object.keys(this.state.current_pageData).includes('ky_pairs') ?
                                    this.state.current_pageData.ky_pairs.length > 0 ?
                                        <Nav.Item eventKey='3' style={{ zIndex: '2' }}>
                                            <Nav.Link eventKey="3" active={this.state.active_tab === '3'} onClick={() => this.handleSelect('3')}>Key-Value pairs</Nav.Link>
                                        </Nav.Item> : null : null
                                }
                                {Object.keys(this.state.current_pageData).includes('tables') ?
                                    this.state.current_pageData.tables.length > 0 ?
                                        <Nav.Item eventKey='4' style={{ zIndex: '2' }}>
                                            <Nav.Link eventKey="4" active={this.state.active_tab === '4'} onClick={() => this.handleSelect('4')}>Tables</Nav.Link>
                                        </Nav.Item> : null : null
                                }
                                {this.state.promptResult ?
                                    <Nav.Item eventKey='5' style={{ zIndex: '2' }}>
                                        <Nav.Link eventKey="5" active={this.state.active_tab === '5'} onClick={() => this.handleSelect('5')}>Prompt Result</Nav.Link>
                                    </Nav.Item> : null
                                }
                                <div className="InPageExport" >
                                    <a href="" onClick={(e) => this.HandleActionMenu(e)}>
                                        <BiExport />
                                    </a>
                                </div>
                            </Nav>
                            :
                            this.state.current_pageData['raw_text'].length > 0 ?
                                <Nav variant="tabs" justified defaultActiveKey="2" id='TabContentSelect'>
                                    <Nav.Item eventKey='1'>
                                        <Nav.Link eventKey="1" active={this.state.active_tab === '1'} onClick={() => this.handleSelect('1')}>{translate('Text')}</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item eventKey='2'>
                                        <Nav.Link eventKey="2" active={this.state.active_tab === '2'} onClick={() => this.handleSelect('2')}>Key-Value-Pairs</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item eventKey='3'>
                                        <Nav.Link eventKey="3" active={this.state.active_tab === '3'} onClick={() => this.handleSelect('3')}>Tables</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                :
                                null
                        }
                        <Row style={{ height: '100%' }}>
                            {/* {Object.keys(this.state.current_pageData).includes('json_res')?this.displayOCRTabs():this.displayTabContent()} */}
                            {Object.keys(this.state.current_pageData).includes('json_res') ?
                                <OCRResultModal current_pageData={this.state.current_pageData} active_tab={this.state.active_tab}
                                    HandleSingleHighlight={this.HandleSingleHighlight} simplepost={this.props.simplepost}
                                    editStatus={(stat) => { this.setState({ editing: stat }) }} promptResult={this.state.promptResult} />
                                : this.displayTabContent()}
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default withRouter(DetailViewTMPLTM);