import React, { useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import { SimpleGet } from '../../actions/request_actions';
import { postAuth } from '../../services/routes'

export default function SendNotificationView() {
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [date, setDate] = useState('');
    const [severity, setSeverity] = useState(3);
    const [everyone, setEveryone] = useState(false);
    const [roles, setRoles] = useState('');
    const [users, setUsers] = useState('');
    const [requestResult, setRequestResult] = useState('');

    const sendNotification = (e) => {
        e.preventDefault();

        postAuth({
            title: title,
            message: body,
            expirationDate: date,
            severity,
            everyone,
            roles: roles.length > 0 ? roles.split(',') : [],
            users: users.length > 0 ? users.split(',') : []
        }, '/notifications/sendCustomNotification')
        .then(() => setRequestResult('Notification sent!'))
        .catch(err => {
            // Weirdly, even the request returns 200, it interprets as a catch
            if (err.status === 200) {
                setRequestResult('Notification sent!')
            } else {
                setRequestResult('An error occurred: ' + JSON.stringify(err.responseJSON.detail))
            }
        });
    }

    return (
        <div>
            <Form>
                <Form.Group controlId="sendNotificationForm.inputTitle">
                    <Form.Label>Title</Form.Label>
                    <Form.Control value={title} onChange={e => setTitle(e.target.value)} type="text" />
                </Form.Group>
                <Form.Group controlId="sendNotificationForm.inputBody">
                    <Form.Label>Message content</Form.Label>
                    <Form.Control value={body} onChange={e => setBody(e.target.value)} type="text" placeholder="The server will be down for maintenance at 2:00AM PST" />
                </Form.Group>
                <Form.Group controlId="sendNotificationForm.inputDate">
                    <Form.Label>Expiration Date</Form.Label>
                    <Form.Control value={date} onChange={e => setDate(e.target.value)} type="date" />
                </Form.Group>
                <Form.Group controlId="sendNotificationForm.rangeSeverity">
                    <Form.Label>Severity</Form.Label>
                    <Form.Control value={severity} onChange={e => setSeverity(parseInt(e.target.value))} type="range" min={0} max={4} />
                </Form.Group>
                <Form.Group controlId="sendNotificationForm.checkEveryone">
                    <Form.Label>Send to everyone?</Form.Label>
                    <Form.Check checked={everyone} onChange={e => setEveryone(true)} label="yes" type="radio" id={`everyone-yes`} />
                    <Form.Check checked={!everyone} onChange={e => setEveryone(false)} label="no" type="radio" id={`everyone-no`} />
                </Form.Group>
                <Form.Group controlId="sendNotificationForm.inputRoles">
                    <Form.Label>Roles (divided by commas)</Form.Label>
                    <Form.Control value={roles} onChange={e => setRoles(e.target.value)} type="text" placeholder="Admin,Manager" />
                </Form.Group>
                <Form.Group controlId="sendNotificationForm.inputUsers">
                    <Form.Label>Users (divided by commas)</Form.Label>
                    <Form.Control value={users} onChange={e => setUsers(e.target.value)} type="text" placeholder="x@learningpal.com,y@learningpal.com,..." />
                </Form.Group>
                <Button variant="primary" onClick={(e) => sendNotification(e)}>
                    Send
                </Button>
                <p>{requestResult}</p>
            </Form>
        </div>
    )
}
