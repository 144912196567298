// API Util
import * as Util from '../util/user_api_util';
import * as Util_request from '../util/request_util';
import { logout } from './session_actions';
// Action types
import {
  RECEIVE_CURRENT_USER,
  READ_CURRENT_USER,
  FORGOT_PASSWORD_USER,
  RECEIVE_SESSION_ERRORS,
  UPDATE_CURRENT_USER,
  UPDATE_KEY,
  ADDREQUSAGEINFO,
  RECEIVE_INVITE_USER,
  RECEIVE_INVITATION_CONFIRMATION,
  RECEIVE_INVITE_DATA,
  RECEIVE_SENT_INVITATIONS,
} from '../helpers/action_types';

/*** ************************************
 *** Action POJO with type and payload ***
 *** *************************************
 */
const receiveCurrentUser = user => ({
  type: RECEIVE_CURRENT_USER,
  user
});

const receiveErrors = errors => ({
  type: RECEIVE_SESSION_ERRORS,
  errors
});

const readCurrentUser = user => ({
  type: READ_CURRENT_USER,
  user
});

const forgotpasswordUser = user => ({
  type: FORGOT_PASSWORD_USER,
  user
});

const updateCurrentUser = user => ({
  type: UPDATE_CURRENT_USER,
  user
})

const updateKey = user => ({
  type: UPDATE_KEY,
  user
})

const addReqCountInfo = ReqCount => ({
  type: ADDREQUSAGEINFO,
  ReqCount
})

const receiveInviteUser = data => ({
  type: RECEIVE_INVITE_USER,
  data
})

const receiveInvitationConfirmation = data => ({
  type: RECEIVE_INVITATION_CONFIRMATION,
  data
})

const receiveInviteData = data => ({
  type: RECEIVE_INVITE_DATA,
  data
})

const getInvitations = data => ({
  type: RECEIVE_SENT_INVITATIONS,
  data
})

/*** ************************************
 *** Apply Redux thunk middle   *********
 *** Including calling API, and *********
 ********* POJO to reducers *************
 *** *************************************
 */
export const updatePassword = data => dispatch => {
  return Util.updatePassword(data)
    .then(user => dispatch(receiveCurrentUser(user)))
    .fail(errors => dispatch(receiveErrors(errors.responseText)));
};

export const forgotPassword = data => dispatch => {
  return Util.forgotPassword(data)
    .then(user => dispatch(forgotpasswordUser(user)))
    // .fail(errors => dispatch(receiveErrors(errors.responseText)));
};

export const readUser = data => dispatch => {
  return Util.readUser(data)
    .then(user => dispatch(readCurrentUser(user)))
    .fail(errors => dispatch(receiveErrors(errors.responseText)));
};

export const getApiKey = data => dispatch => {
  return Util.getApiKey(data)
    .then(user => {dispatch(updateCurrentUser(user)); dispatch(updateKey(user))})
    .fail(errors => dispatch(receiveErrors(errors.responseText)));
};

export const updateUser = data => dispatch => {
  return Util.updateUserProfile(data)
  .then(user => dispatch(updateCurrentUser(user)))
  .fail(errors => dispatch(receiveErrors(errors.responseText)));
}

export const ReqCountInfo = data => dispatch => {
  return Util.ReqCountInfo(data)
  .then(ReqCount => dispatch(addReqCountInfo(ReqCount)))
  .fail(errors => dispatch(receiveErrors(errors.responseText)));
}

export const fetchInvitations = data => dispatch => {
  return Util.FetchInvitations(data)
  .then(invitations => dispatch(getInvitations(invitations)))
  .fail(errors => dispatch(receiveErrors(errors.responseText)));
}

export const inviteUser = (email, url) => dispatch => {
  return Util_request.simplePost({email: email}, url)
  .then(data => {
    dispatch(fetchInvitations());
    dispatch(receiveInviteUser(data)); 
  })
  .fail(errors => dispatch(receiveErrors(errors.responseText)));
}

export const acceptInvitation = (body, url) => dispatch => {
  return Util_request.simplePost(body, url)
  .then(data => dispatch(receiveInvitationConfirmation(data)))
  .fail(errors => dispatch(receiveErrors(errors.responseText)));
}

export const getInviteData = (body, url) => dispatch => {
  return Util_request.simplePost(body, url)
  .then(data => dispatch(receiveInviteData(data)))
  .fail(errors => dispatch(receiveErrors(errors.responseText)));
}

// When the user is deleted, it's logged out
export const deleteOwnAccount = () => dispatch => {
  return Util.deleteOwnAccount()
    .then(() => dispatch(logout()))
    .fail(errors => dispatch(receiveErrors(errors.responseText)));
};