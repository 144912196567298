import React, { useState, useRef, useEffect } from 'react'
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import './OCRResultModal.css';
import { string2csv } from '../../../helpers/csv_convert';
import { translate } from '../../../helpers/translate';
import CodeEditor from '@uiw/react-textarea-code-editor';


export default function OCRResultModal({ current_pageData, active_tab,
    HandleSingleHighlight, simplepost, editStatus, promptResult }) {
    const json_res = current_pageData.json_res;

    const words = json_res ? json_res.words : [];
    const ky_pairs = current_pageData.ky_pairs ? current_pageData.ky_pairs : [];
    const tables = current_pageData.tables ? current_pageData.tables : [];

    const [Selected, setSelected] = useState(null);
    const [EditFocus, setEditFocus] = useState(false);
    const [EditText, setEditText] = useState(null);

    const [EditKVPairs, setEditKVPairs] = useState(false);

    const [PageID, setPageID] = useState(current_pageData.PageID);
    const [IMG_URL, setIMG_URL] = useState(current_pageData.IMG_URL);
    const [RAW_TEXT, setRAW_TEXT] = useState(current_pageData.raw_text);

    const FocusedRef = React.useRef(EditFocus);
    const SelectedRef = React.useRef(Selected);
    const EditTextRef = React.useRef(EditText);

    const PageIDRef = React.useRef(PageID);
    const IMG_URLRef = React.useRef(IMG_URL);
    const RAW_TEXTRef = React.useRef(RAW_TEXT);

    const setFocused = data => {
        FocusedRef.current = data;
        setEditFocus(data);
    };
    const setSelectedRef = data => {
        SelectedRef.current = data;
        setSelected(data);
    };
    const setEditTextRef = data => {
        EditTextRef.current = data;
        setEditText(data);
    };
    const setPageIDRef = data => {
        PageIDRef.current = data;
        setPageID(data);
    };
    const setIMG_URLRef = data => {
        IMG_URLRef.current = data;
        setIMG_URL(data);
    };
    const setRAW_TEXTRef = data => {
        RAW_TEXTRef.current = data;
        setRAW_TEXT(data);
    };

    if (PageID != current_pageData.PageID) {
        setPageIDRef(current_pageData.PageID);
        setIMG_URLRef(current_pageData.IMG_URL);
        setRAW_TEXTRef(current_pageData.raw_text);
    }

    useEffect(() => {
        const listener = event => {
            // event.preventDefault();
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                console.log("Enter key was pressed. Run your function.");
                console.log(FocusedRef.current);
                if (FocusedRef.current) {
                    submitChange();
                }
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, []);

    const submitChange = () => {
        console.log('SelectedRef: ', SelectedRef.current);
        console.log('EditTextRef: ', EditTextRef.current);
        console.log('PageIDRef: ', PageIDRef.current);
        if (SelectedRef.current) {
            let WordData = SelectedRef.current;
            let current_pageID = PageIDRef.current;
            let current_pageIMG = IMG_URLRef.current;
            WordData['text_old'] = WordData.text;
            WordData['IMG_URL'] = current_pageIMG;
            WordData['text'] = EditTextRef.current;

            simplepost({ 'UpdateMongoFile': JSON.stringify({ 'PageID': current_pageID, 'new_word': WordData }) },
                '/ftree/updateMongoFile').then(res => {
                    if (res) {
                        setEditFocus(null);
                        setSelectedRef(null);
                        setEditTextRef(null);
                        if (typeof res === 'object' && res !== null) {
                            // Update raw_text
                            if (Object.keys(res).includes('raw_text')) {
                                setRAW_TEXTRef(res.raw_text);
                            }
                        }
                    }
                })
        }
    }

    const GetColor = (conf) => {
        if (conf >= 50) {
            return 'unset'
        } else if (conf > 35 && conf < 50) {
            return '#efef7c'
        } else if (conf <= 35) {
            return '#ff8484'
        }
    }

    const display_raw_txt = () => {
        return (
            <article className='PlainText'>
                <div>
                    <p>{RAW_TEXT ? RAW_TEXT : 'Text not available'}</p>
                </div>
            </article>
        )
    }
    const display_words = () => {
        return (
            <div className="WordsContainer">
                <Row className="WordsRow" style={{ display: 'flex' }}>
                    {words.map(ele =>
                        <div className={ele.conf < 80 ? "word_warn" : "word"}
                            style={SelectedRef.current === ele ? { border: '4px solid var(--MainColor)' } : null}
                            onClick={() => { setSelectedRef(ele); setEditTextRef(ele.text); HandleSingleHighlight(ele.GEO) }}>
                            {ele.text}
                            {ele.TextColor ?
                                <span className={ele.TextColor} />
                                : null}
                        </div>)}
                </Row>
            </div>
        )
    }

    const display_ky_pairs = () => {
        return (
            <div style={{ height: '100%', overflowY: 'auto', position: 'absolute', width: '100%' }}>
                <Table striped bordered hover style={{ marginTop: '1rem' }}>
                    <tbody>
                        {ky_pairs.map((ky, ky_idx) => {
                            return (
                                // <tr onClick={(e)=>{this.HandleKYPairHighlight(ky)}}>
                                <tr>
                                    <th style={{ backgroundColor: `${GetColor(ky['key_conf'])}` }}>
                                        {EditKVPairs
                                            ?
                                            // <Form.Control value={ky['key_txt']} onChange={(e)=>{this.updateData(e, ky_idx, 'key_txt')}}/>
                                            <Form.Control value={ky['key_txt']} />
                                            :
                                            ky['key_txt']}
                                    </th>
                                    <td style={{ backgroundColor: `${GetColor(ky['value_conf'])}` }}>
                                        {EditKVPairs
                                            ?
                                            // <Form.Control value={ky['value_txt']} onChange={(e)=>{this.updateData(e, ky_idx, 'value_txt')}}/>
                                            <Form.Control value={ky['value_txt']} />
                                            :
                                            ky['value_txt']}
                                    </td>
                                </tr>
                            )
                        })
                        }
                    </tbody>
                </Table>
            </div>
        )
    }

    const display_tables = () => {
        return (
            <div style={{ height: '100%', overflowY: 'auto', position: 'absolute', width: '100%' }}>
                {tables.map((ky, idx) => {
                    return (
                        <Table striped bordered hover style={{ marginTop: '1rem' }}>
                            <tbody>
                                {Object.keys(ky).includes('CSV_STRING') ?
                                    string2csv(ky['CSV_STRING']).map(RowList => {
                                        return (
                                            <tr>
                                                {RowList.map(item => {
                                                    return (
                                                        <td>{item}</td>
                                                    )
                                                })}
                                            </tr>
                                        )
                                    })
                                    :
                                    null
                                }
                            </tbody>
                        </Table>
                    )
                })}
            </div>
        )
    }

    const displayPromptResult = () => {
        console.log('promptResult: ', promptResult);
        return (
            <div>
                <CodeEditor
                    value={promptResult}
                    language="js"
                    placeholder={promptResult}
                    padding={15}
                    style={{
                        fontSize: 12,
                        backgroundColor: "#f5f5f5",
                        fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                    }}
                />
            </div>
        )
    }

    return (
        <div className='fullHeight' style={{ position: 'relative' }}>
            {active_tab === '2' ?
                <Form.Control className="WordEdit" placeholder={translate("Select to edit")} type="search"
                    aria-describedby="basic-addon1"
                    value={EditText} disabled={!(SelectedRef.current || false)}
                    onChange={e => setEditTextRef(e.target.value)}
                    onFocus={() => { setFocused(true); editStatus(true) }}
                    onBlur={() => { setFocused(false); editStatus(false) }} />
                : null}
            {active_tab === '1' ?
                display_raw_txt() :
                active_tab === '2' ?
                    display_words() :
                    active_tab === '3' ?
                        display_ky_pairs() :
                        active_tab === '4' ?
                            display_tables() :
                            active_tab === '5' ?
                                displayPromptResult()
                                : null
            }
        </div>
    )
}
