import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { updateUser } from '../../actions/user_actions';

const mapStateToProps = (state, ownProps) => {
  return {
  };
};

const mapDispatchToProps = (dispatch, { location }) => {
  return {
    updateUser: user => dispatch(updateUser(user)),
  };
};

const Subscribe = (props) => {
  if (!props.location.state){
    return <Redirect to={{pathname: '/prices'}} />
  }
  // Get the lookup key for the price from the previous page redirect.
  const [clientSecret] = useState(props.location.state.clientSecret);
  const [subscriptionId] = useState(props.location.state.subscriptionId);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [messages, _setMessages] = useState('');
  const [paymentIntent, setPaymentIntent] = useState();
  
  useEffect(() => {
    if (props){
      setEmail(props.location.currentUser.email);
      setName(props.location.currentUser.name);
    }
  }, [])
  // helper for displaying status messages.
  const setMessage = (message) => {
    _setMessages(`${messages}\n\n${message}`);
  }

  // Initialize an instance of stripe.
  const stripe = useStripe();
  const elements = useElements();

  if (!stripe || !elements) {
    // Stripe.js has not loaded yet. Make sure to disable
    // form submission until Stripe.js has loaded.
    return '';
  }

  // When the subscribe-form is submitted we do a few things:
  //
  //   1. Tokenize the payment method
  //   2. Create the subscription
  //   3. Handle any next actions like 3D Secure that are required for SCA.
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use card Element to tokenize payment details
    let { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: name,
        }
      }
    });

    if(error) {
      // show error and collect new card details.
      setMessage(error.message);
      return;
    }
    setPaymentIntent(paymentIntent);
  }

  if(paymentIntent && paymentIntent.status === 'succeeded') {
    props.updateUser({membership: 'pro'});
    return <Redirect to={{pathname: '/account-details'}} />
  }

  return (
    <div>
      <h1>Subscribe</h1>

      <p>
        Try the successful test card: <span>4242424242424242</span>.
      </p>

      <p>
        Try the test card that requires SCA: <span>4000002500003155</span>.
      </p>

      <p>
        Use any <i>future</i> expiry date, CVC,5 digit postal code
      </p>

      <hr />
      <h3>Billing Information</h3>
      <form onSubmit={handleSubmit}>
        <label>
          Full name
          <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} />
        </label>
        <label>
          Email
          <input type="text" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </label>

        <CardElement />

        <button>
          Subscribe
        </button>

        <div>{messages}</div>
      </form>
    </div>
  )
}

// export default withRouter(Subscribe);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Subscribe)
);