// API Util
import * as Util from '../util/notification_util';

// Action types
import {
  OPEN_NOTIFICATION_CENTER,
  CLOSE_NOTIFICATION_CENTER,
  SWITCH_NOTIFICATION_CENTER,
  RECEIVED_NOTIFICATION,
  FETCH_ALL_NOTIFICATIONS,
  SET_NOTIFICATION_AS_READ,
  FETCH_UNREAD_COUNT,
  RECEIVE_NOTIFICATION_ERRORS
} from '../helpers/action_types';

/*** ************************************
 *** Action POJO with type and payload ***
 *** *************************************
 */
const receivedNotificationCall = notification => ({
  type: RECEIVED_NOTIFICATION,
  notification
});

const receiveErrors = errors => ({
  type: RECEIVE_NOTIFICATION_ERRORS,
  errors
});

const fetchAllNotificationsCall = notifications => ({
  type: FETCH_ALL_NOTIFICATIONS,
  notifications
});

const setNotificationAsReadCall = (id) => ({
  type: SET_NOTIFICATION_AS_READ,
  id
});

const fetchUnreadCountCall = (count) => ({
  type: FETCH_UNREAD_COUNT,
  count
});

/*** ************************************
 *** Apply Redux thunk middle   *********
 *** Including calling API, and *********
 ********* POJO to reducers *************
 *** *************************************
 */

export const receivedNotification = notification => dispatch => {
  const socket = store.getState().session.socket;
  socket.emit('notificationStatus', { id: notification.id, received: true, read: false });
  return dispatch(receivedNotificationCall(notification));
}

export const fetchAllNotifications = () => dispatch => {
  return Util.fetchAllNotifications()
    .then(notifications => dispatch(fetchAllNotificationsCall(notifications)))
    .fail(errors => dispatch(receiveErrors(errors.responseText)));
};

export const fetchUnreadCount = () => dispatch => {
  return Util.fetchUnreadCount()
    .then(count => dispatch(fetchUnreadCountCall(count)))
    .fail(errors => dispatch(receiveErrors(errors.responseText)));
};

export const setNotificationAsRead = id => dispatch => {
  const socket = store.getState().session.socket;
  socket.emit('notificationStatus', { id, received: true, read: true });
  dispatch(setNotificationAsReadCall(id));
};

export const openNotificationCenter = () => dispatch => dispatch({type: OPEN_NOTIFICATION_CENTER});

export const closeNotificationCenter = () => dispatch => dispatch({type: CLOSE_NOTIFICATION_CENTER});

export const switchNotificationCenter = () => dispatch => dispatch({type: SWITCH_NOTIFICATION_CENTER});