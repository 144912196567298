import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import {translate} from '../../helpers/translate';

import {TimeBasedGreeting} from "../../helpers/LocalTimeGreeting"

const Usage = ({ simplepost, rateLimit, userRole }) => {

    const animateValue = (id, start, end, duration) => {
        var obj = document.getElementById(id);
        let range = end - start;
        // no timer shorter than 50ms (not really visible any way)
        var minTimer = 50;
        // calc step time to show all interediate values
        var stepTime = Math.abs(Math.floor(duration / range));
        // never go below minTimer
        stepTime = Math.max(stepTime, minTimer);
        // get current time and calculate desired end time
        var startTime = new Date().getTime();
        var endTime = startTime + duration;
        var timer;

        function run() {
            var now = new Date().getTime();
            var remaining = Math.max((endTime - now) / duration, 0);
            var value = Math.round(end - (remaining * range));
            obj.innerHTML = value;
            if (value == end) {
                clearInterval(timer);
            }
        }
        timer = setInterval(run, stepTime);
        run();
    }

    useEffect(async () => {
        const fetchData = async () => {
            await simplepost({}, '/ftree/stats').then(res => {
                if (res) {
                    animateValue('year', 0, res.year, 800);
                    animateValue('month', 0, res.month, 800);
                    animateValue('day', 0, res.day, 800);
                }
            });
        };
        fetchData();
    }, []);

    return (
        <Container fluid>
            <Row><div className="xxLargeText">{translate(`${TimeBasedGreeting()+','}`)}</div></Row>
            <Col className="Greeting">
                <span>You've uploaded {' '}<b id="year">0</b>{' '}documents this year, {' '}<b id="month">0</b>{' '} this month, and {' '}<b id="day">0</b>{' '} today</span>
                {userRole==='basic'?<p>Besides that, you have <b>{ rateLimit.pageQuota - rateLimit.pagesUploaded || '0' }</b> pages left to process.</p>:null}
            </Col>
        </Container>
    )
};

export default Usage;
