import React, { useEffect } from 'react';

const UserPortrait = ({ fullname, avatar, fontsize }) => {
    
    return (
      <div className="UserPortrait" style={{fontSize: fontsize}}>
        {avatar?
        <img src={avatar[0]} />
        :
        <strong>{fullname[0]}</strong>
        }
      </div>
    );
};
export default UserPortrait;