
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';

import { login, signup, logout } from '../../actions/session_actions';

import { forgotPassword } from '../../actions/user_actions';

import SessionPage from './session_page';

const mapStateToProps = (state, ownProps) => {

  const loggedIn = Boolean(state.session.currentUser)

  let currentUser = loggedIn ? state.session.currentUser : null;

  return {
    currentUser,
    loggedIn,
  };
};

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout()),
  signup: user => dispatch(signup(user)),
  login: user => dispatch(login(user)),
  forgotPassword: data => dispatch(forgotPassword(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SessionPage));
