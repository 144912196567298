import { connect } from 'react-redux';

import { fetchAllNotifications, closeNotificationCenter, setNotificationAsRead } from '../../actions/notification_actions';

import NotificationCenter from './NotificationCenter';

const mapStateToProps = (state, ownProps) => {

  const loggedIn = Boolean(state.session.currentUser)

  let currentUser = loggedIn ? state.session.currentUser : null;

  let language = state.entities.Language;

  let loading = state.entities.Loading;

  let notifications = state.notifications;

  return {
    currentUser,
    loggedIn,
    language,
    loading,
    notifications
  };
};

const mapDispatchToProps = dispatch => ({
  fetchAllNotifications: () => dispatch(fetchAllNotifications()),
  isLoading: data => dispatch({type: 'LOADING_STATUS', data}),
  closeNotificationCenter: () => dispatch(closeNotificationCenter()),
  setNotificationAsRead: id => dispatch(setNotificationAsRead(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationCenter);
