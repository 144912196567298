import { connect } from 'react-redux';

import { readUser } from '../../actions/user_actions';
import { showCustomError } from '../../actions/session_actions';
import { getBatchRequests, SimplePost, GetSearchResults, jsonPost } from '../../actions/request_actions';

import { withRouter } from 'react-router-dom';

import FileBox from './filebox';
import { data } from 'jquery';


const mapStateToProps = (state, ownProps) => {
  
  const loggedIn = Boolean(state.session.currentUser);

  let currentUser = state.session.currentUser;
  
  let error = state.errors.session;

  let socket = state.session.socket;

  let AllPath = state.entities.AllPath;

  let fbtype = 'regular';
  let noSearchResults = null;

  console.log("ownProps:", ownProps)

  if (ownProps.location.pathname.split('/')[1] == 'search') {
    let searchResults = state.entities.Search.results;
    AllPath = searchResults || {Folders: [], Files: []};
    fbtype = "search";
    noSearchResults = (searchResults !== null && searchResults.Folders.length === 0 && searchResults.Files.length === 0);
  }

  return {
    socket,
    userId: currentUser.id,
    userRole: currentUser.role,
    currentUser,
    loggedIn,
    error,
    AllPath,
    fbtype,
    noSearchResults,
  };
};

const mapDispatchToProps = (dispatch, { location }) => {
  return {
    readUser: userId => dispatch(readUser({id: userId})),
    getRootOwnFolders: () => dispatch(getBatchRequests({}, '/ftree/fetchOwn')),
    getRootSharedFolders: () => dispatch(getBatchRequests({}, '/ftree/fetchShared')),
    getRootTrash: () => dispatch(getBatchRequests({}, '/ftree/rootTrash')),
    getTrashContents: (currentFolderId, page) => dispatch(getBatchRequests({ currentFolderId, page }, '/ftree/fetchTrashContents')),
    getRecentFiles: () => dispatch(getBatchRequests({}, '/ftree/allRecentFiles')),
    getFolderContents: (currentFolderId, page) => dispatch(getBatchRequests({ currentFolderId, page }, '/ftree/fetchContents')),
    showCustomError: (str) => dispatch(showCustomError(str)),
    simplepost: (data, url) => dispatch(SimplePost(data, url)),
    jsonPost: (data, url) => dispatch(jsonPost(data, url)),
    ReceiveError: msg => {dispatch({type:'RECEIVE_FETCH_ERROR', errors: msg}); 
                          setTimeout(()=>{dispatch({type:'RECEIVE_FETCH_ERROR', errors: ''})}, 3000)},
    ModifyPath: data => dispatch({type:'MODIFY_PATH', requests: data}),
    doSearch: (query) => dispatch(GetSearchResults(query, '/ftree/simpleSearch')),
    starResource: data => dispatch(SimplePost(data, '/ftree/star')),
    unstarResource: data => dispatch(SimplePost(data, '/ftree/unstar')),
    getStarredResources: () => dispatch(getBatchRequests({}, '/ftree/getStarred')),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FileBox)
);