import { SimpleGet, JSONPost, JSONPut, JSONDelete } from '../../../services/routes';

export async function getFilePermissions(folderId, folderName, fileId) {
    // File permissions consists in two requests:
    //  1 - Get the own permissions of the file
    //  2 - Get the inherited permissions from the parent folder
    const ownPermissions = await getFilePermissionsRequest(fileId);
    const folderPermissions = await getFolderPermissionsRequest(folderId);

    // Then the inherited permissions array will be flattened since, for the file, the permissions from the folder are
    // all inherited
    // First convert them to inherited
    const permissionToInherited = folderPermissions[0].map(val => ({
        depth: 0,
        permission: val,
        parentFolder: folderName
    }));

    // Then flatten
    const inheritedPermissions = [...permissionToInherited, ...folderPermissions[1]];

    // Then group by folder
    const groupByFolder = groupInheritedByFolder(inheritedPermissions);

    // Return the permissions
    return [ownPermissions, groupByFolder];
}

export async function getFolderPermissions(folderId) {
    // Just does the request since nothing else is needed
    const permissions = await getFolderPermissionsRequest(folderId);

    // Group inherited by folder
    const groupByFolder = groupInheritedByFolder(permissions[1]);

    return [permissions[0], groupByFolder];
}

export function createPermission(permission, props) {
    permission.folderID = props.folderID;
    permission.fileID = props.fileID ? props.fileID : null;
    return JSONPost(permission, '/permissions/permission');
}

export function createURLPermission(props) {
    const reqBody = {
        folderID: props.folderID,
        fileID: props.fileID ? props.fileID : null
    }
    return JSONPost(reqBody, '/permissions/urlPermission');
}

export function updatePermission(permission, props) {
    permission.folderID = props.folderID;
    permission.fileID = props.fileID ? props.fileID : null;
    return JSONPut(permission, '/permissions/permission');
}

export function removePermission(permission, props) {
    const request = {
        id: permission.id,
        folderID: props.folderID,
        fileID: props.fileID ? props.fileID : null,
    }
    return JSONDelete(request, '/permissions/permission');
}

async function getFilePermissionsRequest(fileId) {
    return SimpleGet({}, '/permissions/filePermissions?file=' + fileId);
}

async function getFolderPermissionsRequest(folderId) {
    const params = `folder=${folderId}`;
    return SimpleGet({}, '/permissions/folderPermissions?' + params);
}

function groupInheritedByFolder(inherited) {
    const groupByFolder = {};
    for (const permission of inherited) {
        if (groupByFolder[permission.parentFolder]) {
            groupByFolder[permission.parentFolder] = [...groupByFolder[permission.parentFolder], permission.permission];
        } else {
            groupByFolder[permission.parentFolder] = [permission.permission];
        }
    }
    return groupByFolder;
}