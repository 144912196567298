import { connect } from 'react-redux';
import { SimpleGet2 } from '../../actions/request_actions';
import Usage from './Usage'

const mapStateToProps = (state) => {
    let currentUser = Object.keys(state.entities.user).length > 0 ? state.entities.user : state.session.currentUser;
    return {
        rateLimit: state.entities.rateLimit,
        currentUser: currentUser,
        userRole: currentUser.role
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        simpleget: (data, url) => dispatch(SimpleGet2(data, url))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Usage);