import React from 'react';

import { withRouter } from 'react-router-dom';

import { uniqueId } from '../../helpers/id_generator';

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { errors: [] };
  }

  componentWillMount() {
    // Go to dashboard if already login
    if (this.props.loggedIn) this.props.history.push('/dashboard');

    // Else do the verify third party login process
    const qs = new URLSearchParams(this.props.history.location.search);
    
    if (qs.has('token') && qs.has('loginProvider') && qs.has('isAuthenticated')) {
      const data = {
        user: {
          id: decodeURIComponent(qs.get('id')),
          loginProvider: decodeURIComponent(qs.get('loginProvider')),
          // name: decodeURIComponent(qs.get('name')),
          firstname: decodeURIComponent(qs.get('firstname')),
          lastname: decodeURIComponent(qs.get('lastname')),
          email: decodeURIComponent(qs.get('email')),
          company: decodeURIComponent(qs.get('company')),
          profileUrl: decodeURIComponent(qs.get('profileUrl')),
          avatar: decodeURIComponent(qs.get('avatar')),
          token: decodeURIComponent(qs.get('token')),
          membership: decodeURIComponent(qs.get('membership')),
          role: decodeURIComponent(qs.get('role')),
          isAuthenticated: decodeURIComponent(qs.get('isAuthenticated')),
          apiKey: decodeURIComponent(qs.get('apiKey'))
        },

        token: decodeURIComponent(qs.get('token')),
      }
      
      this.props.verifyThirdPartyLogin(data);
      this.props.history.push('/dashboard');

    } else {
      this.setState({ errors: ['Not a valid URL Or Fail to login'] })
    }
  }

  displayError() {
    const errors = this.state.errors.length > 0 ? this.state.errors : this.props.errors;

    if (errors.length > 0) {
      return (
        <div className="verify-error">
          {
            errors.map((error, idx) => {
              return (
                <h4 key={idx + uniqueId()}>{error}</h4>
              )
            })
          }
        </div>
      );
    }
  }

  render() {
    return (
      <div className="verify-third-party-login">
        <h1>Redirecting...</h1>

        {this.displayError()}
      </div>
    );
  }
}

export default withRouter(LoginForm);
