import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import ProgressBar from "react-bootstrap/ProgressBar";

import ReactDropzone from "react-dropzone";
import { MdPictureAsPdf } from '@react-icons/all-files/md/MdPictureAsPdf';
import { MdPhotoLibrary } from '@react-icons/all-files/md/MdPhotoLibrary';

import { LanguagesSelect } from './LanguagesDropDown';
import { translate } from '../helpers/translate';
import Form from 'react-bootstrap/Form';

import * as Util from "../util/request_util";

class FileDrop extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            uploading: false,
            lang: this.props.FolderLang || 'eng',
            status: 'sent',
            imageName: [],
            file: [],
            submit_buttton: false,
            progressMap: {}
        };
        this.uploadFilePreview = this.uploadFilePreview.bind(this);
        this.HandleUpload = this.HandleUpload.bind(this);
    }

    //   componentWillMount() {
    //       console.log('current FolderLang: ', this.props.FolderLang);
    //       this.setState({lang: this.props.FolderLang})
    //   }

    componentDidMount() {
        this.uploadFilePreview(this.props.files);
    }

    uploadFilePreview(files) {
        console.log('files: ', files);
        let filename_list = files.map((ele) => { return ele.name });
        this.setState({
            file: files,
            imageName: filename_list,
            submit_buttton: true
        });
    }

    HandleUpload() {
        this.setState({ uploading: true })
        let url = this.props.url ? this.props.url : '/ftree/authupload';
        let CODE = this.props.CODE ? this.props.CODE : '';
        let fieldname = this.props.fieldname ? this.props.fieldname : 'pdf';
        const all_fs = this.state.file;
        let count = 0;
        all_fs.forEach((f, i) => {
            let formData = new FormData();
            formData.append(`${fieldname}`, f);
            formData.append('method', this.props.method);
            formData.append('CODE', CODE);
            formData.append('lang', this.state.lang);
            formData.append('status', this.state.status);
            formData.append('type', this.props.type);
            Util.PostRequest(formData, url, (prog) => {
                let currentprogressMap = this.state.progressMap;
                currentprogressMap[`${i}`] = prog * 100;
                this.setState({ progressMap: currentprogressMap });
            })
                .then((res) => {
                    count++;
                    if (count === all_fs.length) {
                        setTimeout(() => {
                            this.props.closePopup();
                        }, 100);
                    }
                })
                .catch((err) => {
                    this.setState({ uploading: false });
                    window.alert(`An error occured when upload documents, please contact manager: ${String(err)}`);
                })
        })
    }

    render() {
        return (
            <Container fluid className="PrepareSendContainer">
                <Row className="fullHeight">
                    <Col style={{ height: '100%' }}>
                        <ReactDropzone type="file" name='pdf' className="dropzone fullHeight"
                            onDrop={(accepted, rejected) => {
                                if (accepted.length > 0) {
                                    this.uploadFilePreview(accepted)
                                } else {
                                    window.alert("Please Upload a single file (Support format: PNG, JPEG, and SVG")
                                }
                            }}
                            accept={this.props.accept ? this.props.accept : "image/*, .pdf"}
                        >
                            <div className="logUploadicon">
                                <p className="uploadicon_text">{"Drag and drop files\n- or -\nBrowse"}</p>
                            </div>
                        </ReactDropzone>
                    </Col>
                    <Col className='RightSidePOP' style={{ height: '100%' }}>
                        <div>
                            <h6>Upload documents to folder <span className='bold'>{this.props.CaseName}</span> in PDF or Image format.</h6>
                            {/* <Row style={{height:'10%'}}> */}
                            <Row style={{ flexDirection: 'column' }}>
                                {this.props.LanguageSelectOption ?
                                    <div>
                                        <Form.Label style={{ color: 'var(--SecondaryColor)' }}>Select a language if different than folder default.</Form.Label>
                                        <LanguagesSelect style={{ padding: '8px 0', margin: '0px' }} current_lang={this.state.lang} selectOutput={(e) => { this.setState({ lang: e.target.value }) }} />
                                    </div>
                                    :
                                    null
                                }
                                {this.props.StatusSelect ?
                                    <Form.Group className="SelectMenu">
                                        <Form.Control className='SelectMenuInner' as="select" onChange={(e) => { this.setState({ status: e.target.value }) }}>
                                            {this.props.StatusSelect.map((value, idx) => {
                                                return (<option value={value[0]}>{value[1]}</option>)
                                            })}
                                        </Form.Control>
                                    </Form.Group>
                                    :
                                    null
                                }
                            </Row>
                            <Row style={{ maxHeight: 'calc(100% - 140px)', overflow: 'auto' }}>
                                <Table striped bordered hover>
                                    <tbody>
                                        {this.state.imageName.map((fileurl, i) => {
                                            return (
                                                <tr>
                                                    <th>{String(i + 1)}</th>
                                                    <td style={{ position: "relative" }}>
                                                        {fileurl.substring(fileurl.length - 3, fileurl.length).toLowerCase() === 'pdf' ?
                                                            <MdPictureAsPdf style={{ color: 'darkred' }} />
                                                            :
                                                            <MdPhotoLibrary style={{ color: 'darkred' }} />
                                                        }
                                                        {fileurl}
                                                        <ProgressBar id={`Progress_${i}`} className="ProgressAsBackground"
                                                            variant="info" now={this.state.progressMap[`${i}`] ? this.state.progressMap[`${i}`] : 0} />
                                                    </td>
                                                </tr>)
                                        })}
                                    </tbody>
                                </Table>
                            </Row>
                            {this.state.submit_buttton ?
                                <div className='RightBottomButton' style={{ margin: '0px' }}>
                                    <Button onClick={this.HandleUpload}
                                        disabled={this.state.uploading ? true : false}
                                    >
                                        {translate('Upload')}
                                    </Button>
                                </div> : null}
                        </div>
                    </Col>
                </Row>
            </Container>

        );
    }
}

export default FileDrop;
