// Action types
import {
  RECEIVE_ALL_REQUESTS,
  RECEIVE_A_REQUEST,
  CLEAR_ALL_REQUESTS,
  RECEIVE_BATCH_REQUEST,
  MODIFY_PATH,
} from '../helpers/action_types';

const _initalState = null;

const RequestReducer = (state = _initalState, action) => {
  Object.freeze(state);
  let newState;

  switch (action.type) {
    case RECEIVE_ALL_REQUESTS:
      newState = action.requests.requests;
      return newState;

    case RECEIVE_A_REQUEST:
      newState = state.slice(0);
      const request = action.request;
      newState.unshift(request); //put at beginning
      return newState

    case RECEIVE_BATCH_REQUEST:
      newState = action.requests;
      return newState

    case CLEAR_ALL_REQUESTS:
      return _initalState;

    case MODIFY_PATH:
      newState = action.requests;
      return newState;

    default:
      return state;
  }
};

export default RequestReducer;
