// Action types
import {
    RECEIVE_ALL_REQUESTS,
    RECEIVE_A_REQUEST,
    CLEAR_ALL_REQUESTS,
    RECEIVE_BATCH_REQUEST,
    ADDREQUSAGEINFO
  } from '../helpers/action_types';
  
  const _initalState = {};
  
  const ReqCountReducer = (state = _initalState, action) => {
    Object.freeze(state);
      
    switch (action.type) {

      case ADDREQUSAGEINFO:
        const ReqCountInfo = action.ReqCount.ReqCountInfo;        
        return ReqCountInfo
  
      default:
        return state;
    }
  };
  
  export default ReqCountReducer;
  