import React, { useState, useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import URLPermissionsEditor from './components/URLPermissionsEditor';
import PermissionsEditor from './components/PermissionsEditor';
import { toText, fromFolderToDTO, PermissionTypes } from './permissionConverter';
import {MdLink} from '@react-icons/all-files/md/MdLink';
import { SimpleGet, JSONPost, JSONPut, JSONDelete } from '../../services/routes';
import * as requests from './helpers/PermissionRequests'
import './permissionComponents.css'
import {MdPerson} from '@react-icons/all-files/md/MdPerson' ;
import {MdPeople} from '@react-icons/all-files/md/MdPeople' ;
import {MdFolder} from '@react-icons/all-files/md/MdFolder' ;

import { iconMap, mimeIconMap } from '../../helpers/IconHelper';
import InheritedPermissionEditor from './components/InheritedPermissionEditor';

const roles = ['Manager', 'Operator'];

export default function PermissionManager(props) {
    const [permissions, setPermissions] = useState([]);
    const [SelectPerm, setSelectPerm] = useState(false);
    const [inheritedPermissions, setInheritedPermissions] = useState([]); // These are grouped by folder
    const [error, setError] = useState(null);
    const [NotifyPeople, setNotifyPeople] = useState(true);
    const [AddValue, setAddValue] = useState('');
    const [AddTarget, setAddTarget] = useState("user");
    const [roleAccess, setRoleAccess] = useState(roles[0]);

    useEffect(() => {
        if (props.fileID) {
            requests.getFilePermissions(props.folderID, props.folderName, props.fileID)
            .then(result => {
                setPermissions(result[0]);
                setInheritedPermissions(result[1]);
            })
            .catch(err => setError(err));
        } else {
            requests.getFolderPermissions(props.folderID)
            .then(result => {
                setPermissions(result[0]);
                setInheritedPermissions(result[1]);
            })
            .catch(err => setError(err));
        }
    }, [props])

    const updatePermissionRequest = (index, permission) => {
        requests.updatePermission(permission, props)
        .then((response) => {
            const newState = permissions.map((val, i) => {
                if (i === index) return permission;
                return val;
            })
            setError(null);
            setPermissions(newState);
        })
        .catch(err => setError("Unable to update the permission, please check if all parameters are correctly set."));
    }

    const removePermissionRequest = (index, permission) => {
        // Confirm
        if (confirm('Are you sure you want to remove this permission?')) {
            requests.removePermission(permission, props)
            .then((response) => removePermissionFromState(index))
            .catch((err) => setError("Unable to delete the permission, please check if the permission still exists."));
        }
    }

    const removePermissionFromState = (index) => {
        setPermissions(permissions.filter((val, i) => i !== index));
    }

    const createDisplayName = permission => {
        if (permission.token) {
            return <span><MdLink /> {toText(permission.permission)}</span>
        }
        return toText(permission.permission) + ' - ' + (permission.role ? permission.role : permission.user);
    }

    const DisplayUserOrGroupPerm = permission => {
        if (permission.token) {
            return null
        }
        if (permission.role){
            return <div><MdPeople/>{permission.role}</div>
        }else{
            return <div><MdPerson/>{permission.user}{permission.protected?<span>(Owner)</span>:null}</div>
        }
    }

    const handleCreateOverridePermission = async (user, role) => {
        const permissionData = {
            user,
            role,
            token: null,
            permission: PermissionTypes.READ,
            notify: false
        };

        createNewPermission(permissionData);
    }

    const handleCreateNewPermissions = async () => {
        const permissionData = {
            user: AddTarget === 'user' ? AddValue : null,
            role: AddTarget === 'group' ? roleAccess : null,
            token: null,
            permission: PermissionTypes.READ,
            notify: NotifyPeople
        };

        createNewPermission(permissionData);
    }

    const createNewPermission = permissionData => {
        requests.createPermission(permissionData, props)
        .then((createdPermission) => {
            setPermissions([...permissions, createdPermission]);
            setAddTarget('user');
            setAddValue('');
        })
        .catch(err => setError(err.responseText));
    }

    const handleCreateURLPermission = () => {
        requests.createURLPermission(props)
        .then((response) => {
            const newState = [...permissions, response];
            setError(null);
            setPermissions(newState);
        })
        .catch(err => setError(err.responseText));
    }

    const renderInheritedPermissions = () => {
        // Stores the roles that already have permission
        const rolesUsed = permissions.map(val => val.role).filter(val => val !== null);
        // Stores the users that already have permission
        const usersUsed = permissions.map(val => val.user).filter(val => val !== null);

        return Object.keys(inheritedPermissions).map(folder=> {
            const permissions = createJSXForAFolderInheritedPermissions(rolesUsed, usersUsed, inheritedPermissions[folder]);
            if (!permissions.every(v => v === undefined)) {
                return (
                    <div>
                        <span className="inherited-permission-folder-title"><b><i>Folder {folder}</i></b></span>
                        <div>{ permissions }</div>
                    </div>
                )
            }
        })
    }

    /**
     * This functions creates an array of JSX for every unique permission a folder has. If, for example, a higher level
     * permission already exists for a user (usersUsed) or a role (rolesUser), it won't be added to the array
     */
    const createJSXForAFolderInheritedPermissions = (rolesUsed, usersUsed, inheritedPermissions) => {
        return inheritedPermissions.map(val => {
            let unique = false;
            if (val.role && !rolesUsed.includes(val.role)) {
                unique = true;
                rolesUsed.push(val.role);
            } else if (val.user && !usersUsed.includes(val.user)) {
                unique = true;
                usersUsed.push(val.user);
            }

            if (unique) {
                return <InheritedPermissionEditor permission={val} overridePermission={handleCreateOverridePermission} />
            }
        })
    }

    return permissions === null ? <div>Loading...</div> : (
        <div className='PermissionView'>
            { error ? <Alert variant="danger" className="permissionsAlert">{error}</Alert> : ''}
            <Container>
                <Row><Col className="cell-icon"><h5 className="ShareTagLine">You are sharing {props.fileID?mimeIconMap(props.fileName):<MdFolder />}{props.fileName}</h5></Col></Row>
                <Row>
                    <Form.Group as={Col}>
                        <Form.Label>Invite:</Form.Label>
                        <Form.Label style={{float: 'right', display: 'flex'}}>
                            <label className='CheckContainer' style={{margin: '0', fontSize: 'small'}}>Notify People
                                <input type="checkbox" onChange={(e) => {setNotifyPeople(!NotifyPeople)}} checked={NotifyPeople} />
                                <span className="checkmark"></span>
                            </label>
                        </Form.Label>
                        <div style={{display: 'flex'}}>
                            <Form.Control as="select" onChange={e=>setAddTarget(e.target.value)} style={{width: '30%'}}>
                                <option selected={AddTarget==='user'} value="user">Add user</option>
                                <option selected={AddTarget==='group'} value="group">Add group</option>
                            </Form.Control>
                            {AddTarget==='user'?
                                <Form.Control onChange={(e)=>{setAddValue(e.target.value)}} value={AddValue} placeholder="User e-mail" />
                                :
                                <Form.Control onChange={(e) => setRoleAccess(e.target.value)} as="select">
                                    {roles.map(val => <option selected={roleAccess === val} value={val}>{val}</option>)}
                                </Form.Control>
                            }
                        </div>
                    </Form.Group>
                    <Col sm={4} style={{marginBottom: '1rem', display: 'flex', flexFlow: 'column'}}>
                        <Form.Label>Default read only permission</Form.Label>
                        <Button size="sm" variant="primary" onClick={handleCreateNewPermissions} disabled={AddValue.length===0&&AddTarget==='user'}>Add</Button>
                        {/* <div style={{marginLeft: 'auto'}}>
                            {SelectPerm? 
                                <ButtonGroup>
                                    <Button size="sm" variant="primary" onClick={() => updatePermission(stateToObject())} disabled={hasChanged()?false:true}>Confirm</Button>
                                    <Button size="sm" variant="outline-primary" onClick={()=>{setSelectPerm(!SelectPerm)}}>Cancel</Button>
                                </ButtonGroup>:
                                <Button style={{marginLeft: '1em'}} size="sm" variant="outline-primary" onClick={()=>{setSelectPerm(!SelectPerm)}}>
                                    Select permissions
                                </Button>
                            }
                        </div>
                        {SelectPerm?<PermissionOptions
                            readPermission={readPermission} setReadPermission={setReadPermission}
                            writePermission={writePermission} setWritePermission={setWritePermission}
                            downloadPermission={downloadPermission} setDownloadPermission={setDownloadPermission}
                            deletePermission={deletePermission} setDeletePermission={setDeletePermission}
                            editPermissionsPermission={editPermissionsPermission} setEditPermissionsPermission={setEditPermissionsPermission}
                            />:null} */}
                    </Col>
                </Row>
                <Row>
                    <Col style={{maxHeight: '20vh', overflowY: 'auto'}}>
                        <div>Currently shared with: </div>
                        { permissions.map((val, i) => (
                            <div>
                                    {val.token ? null
                                    : <PermissionsEditor
                                    currentUser={props.currentUser}
                                    permission={val}
                                    updatePermission={(received) => updatePermissionRequest(i, received)}
                                    removePermission={(received) => removePermissionRequest(i, received)} />
                                }
                                </div>
                            )) 
                        }
                        <div>{ Object.keys(inheritedPermissions).length > 0 && "Inheriting from:" }</div>
                        { renderInheritedPermissions() }
                        {/* {permissions.map((val, i) => {
                            return <div>{DisplayUserOrGroupPerm(val)}</div>
                        })} */}
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        <URLPermissionsEditor
                            currentUser={props.currentUser}
                            permission={permissions.some(val => val.token)?permissions.filter(val=>val.token)[0]:null}
                            updatePermission={(received) => updatePermissionRequest(permissions.findIndex(val=>val.token), received)}
                            removePermission={(received) => removePermissionRequest(permissions.findIndex(val=>val.token), received)}
                            createLink={handleCreateURLPermission}
                            folderID={props.folderID}
                            fileID={props.fileID} />
                    </Col>
                </Row>
            </Container>

            {/* <Tab.Container defaultActiveKey="first" style={{height: '100%'}}>
                <Row style={{height: '100%'}}>
                    <Col sm={3} style={{height: '100%'}}>
                        <Nav variant="pills" className="flex-column">
                            { permissions.map((val, i) => {
                                if (val.protected) {
                                    return (<Nav.Item disabled>
                                        <Nav.Link disabled eventKey={i} onClick={()=>{}}>{createDisplayName(val)}</Nav.Link>
                                    </Nav.Item>)
                                } else {
                                    return (<Nav.Item>
                                        <Nav.Link eventKey={i} onClick={()=>{}}>{createDisplayName(val)}</Nav.Link>
                                    </Nav.Item>)
                                }
                            }) }
                            <Nav.Item>
                                <Nav.Link eventKey={permissions.length} onClick={handleCreateNewPermissions}>+ New Permission</Nav.Link>
                            </Nav.Item>
                            { !permissions.some(val => val.token) &&
                                <Nav.Item>
                                    <Nav.Link eventKey={1 + permissions.length} onClick={handleCreateURLPermission}><MdLink /> Get Link</Nav.Link>
                                </Nav.Item>
                            }
                            <Nav.Item disabled>
                                <Nav.Link disabled>
                                    <b>Inherited permissions:</b>
                                </Nav.Link>
                            </Nav.Item>
                            { renderInheritedPermissions() }
                        </Nav>
                    </Col>
                    <Col sm={9} style={{height: '100%'}}>
                        <Tab.Content style={{height: '100%'}}>
                            { permissions.map((val, i) => (
                                <Tab.Pane eventKey={i} style={{height: '100%'}}>
                                    {
                                        val.token ?
                                        <URLPermissionsEditor
                                            currentUser={props.currentUser}
                                            permission={val}
                                            updatePermission={(received) => updatePermission(i, received)}
                                            removePermission={(received) => removePermission(i, received)} />
                                        : <PermissionsEditor
                                            currentUser={props.currentUser}
                                            permission={val}
                                            updatePermission={(received) => updatePermission(i, received)}
                                            removePermission={(received) => removePermission(i, received)} />
                                    }
                                </Tab.Pane>
                            )) }
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container> */}
        </div>
    )
}
