import React from 'react'
import { Button } from 'react-bootstrap'

export default function SocialAccountCreateConfirmation({ goBack, startAccountCreation }) {
    return (
        <div>
            <center style={{marginBottom: '20px'}}><h6>The account you provided doesn't exist. Would you like to use it for a new account?</h6></center>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Button
                    style={{ width: '100%', marginBottom: 5 }}
                    onClick={startAccountCreation}>Register this account</Button>
                <Button
                    variant="secondary"
                    style={{ width: '100%' }}
                    onClick={goBack}>Try a different login</Button>
            </div>
        </div>
    )
}
