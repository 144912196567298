import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './style.css';

const RetryView = ({OutputMethod}) =>{
    const [ChoosePages, setChoosePages] = useState('All');
    const [CustomValue, setCustomValue] = useState(null);

    useEffect(()=> {
        if (ChoosePages || CustomValue){
            console.log('choose page change');
            OutputMethod({ 'Pages': ChoosePages==='All'?'All':CustomValue });
        }
    })

    return (
        <Form className="ExportViewParent">
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>Pages</Form.Label>
                </Form.Group>
                <Col xs={8}>
                    <Form.Control as="select" onChange={e=>setChoosePages(e.target.value)}>
                        <option selected={ChoosePages==='All'} value={'All'}>All</option>
                        <option selected={ChoosePages==='Custom'} value={'Custom'}>Custom</option>
                    </Form.Control>
                    {
                        ChoosePages === 'Custom'?
                        <Form.Control value={CustomValue} placeholder='e.g. 1-6, 8, 10-100'
                                      onChange={e=>setCustomValue(e.target.value)}>
                        </Form.Control>
                        :null
                    }
                </Col>
            </Row>
        </Form>
    )
};

export default RetryView;