import React, { useState } from 'react'
import { Form, Button, FormControl, InputGroup } from 'react-bootstrap'
import Popup from '../../Popup'
import './RenameModal.css'
import {translate} from '../../../helpers/translate';

export default function RenameModal({ data, handleClose, handleRename }) {
    const { innerWidth: width, innerHeight: height } = window;

    // Extract the file name and extension if it is a file
    let previousName = data.title;
    let extension = ''; // Only applies to files

    if (Object.keys(data).includes('FileID')) {
        const regexpSize = /^(.*)(\..*)$/;
        const match = data.title.match(regexpSize);
        if (match){
            previousName = match[1];
            extension = match[2];
        }
    }

    // State
    const [newName, setNewName] = useState(previousName);

    const checkReturn = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleRename(newName)
        }
    }

    return (
        <div>
            <Popup show={!!data} // Data will be null if the component shouldn't be shown
                handleClose={handleClose}
                closeButtonLoc='TopRight'
                size='tiny' >
                <div className='inner-rename'>
                    <h3>{translate('Rename')}</h3>
                    <Form>
                        <InputGroup>
                            <FormControl
                                placeholder="New name"
                                value={newName}
                                onChange={e => setNewName(e.target.value)}
                                onKeyDown={checkReturn} />
                            { extension && <InputGroup.Text>{extension}</InputGroup.Text> }
                        </InputGroup>
                        <br />
                        <Button variant="primary" disabled={!newName} onClick={() => handleRename(newName)}>{translate('Confirm')}</Button>{'   '}
                        <Button variant="light" onClick={handleClose}>{translate('Cancel')}</Button>
                    </Form>
                </div>
            </Popup>
        </div>
    )
}
