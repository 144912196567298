import React from 'react';
import { Switch, Route } from "react-router-dom";

import SocialRegisterPage from './social_register_page_container';
import RegisterIndividualPage from './register_individual_page_container';
import RegisterEnterprisePage from './register_enterprise_page_container';
import RegisterHomePage from './register_home_page_container';

export default class RegisterPage extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Switch>
                    <Route path="/auth/register/social" component={SocialRegisterPage} />
                    <Route path="/auth/register/individual" component={RegisterIndividualPage} />
                    <Route path="/auth/register/enterprise" component={RegisterEnterprisePage} />
                    <Route exact path="/auth/register" component={RegisterHomePage} />
                </Switch>
            </React.Fragment>
        )
    }
}