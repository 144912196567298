import React, { useState, useEffect } from 'react';  

import { withRouter } from 'react-router-dom';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import {MdMoreVert} from '@react-icons/all-files/md/MdMoreVert';
import {HiOutlineArrowsExpand} from '@react-icons/all-files/hi/HiOutlineArrowsExpand';

import {IMGLoading} from '../loading';

import ActionMenu from '../ActionMenu';

import {translate} from '../../helpers/translate';

const QuickAction = ({ simplepost }) => {
    const [Loading, setLoading] = useState(true);
    const [Labels, setLabels] = useState([]);
    const [Correction, setCorrection] = useState(null);
    const [ActionMenuLoc, setActionMenuLoc] = useState(null);
    const [TurnOn, setTurnOn] = useState(false);

    // useEffect( async ()=>{
    //     const fetchData = async () => {
    //         await simplepost({}, '/ftree/fetchlabels').then(res => {
    //             console.log('res: ', res);
    //             setLabels(res?res:[]);
    //             setLoading(false);
    //         });
    //     };
    //     fetchData();
    // }, []);

    const fetchData = async (e) => {
        e.preventDefault();
        setTurnOn(true);
        await simplepost({}, '/ftree/fetchlabels').then(res => {
            console.log('res: ', res);
            setLabels(res?res:[]);
            setLoading(false);
        }).catch((err)=>{
            setLoading(false);
        });
    };

    const SkipCurrent = async (e) => {
        setLoading(true);
        let Data = Labels[0];
        if (!Object.keys(Data).includes('value_conf_old')){
            Data['value_conf_old'] = Data['value_conf']
            Data['value_txt_old'] = Data['value_txt']
        }
        Data['value_conf'] = 100
        await simplepost({Data: JSON.stringify(Data), 'Skip': true}, '/ftree/updatelabel').then(res => {
            setTimeout(() => {
             setLabels(state => state.slice(1));
                setCorrection(null);
                setLoading(false);
            }, 200);
        });
    };

    const MarkCorrect = async (e) => {
        setLoading(true);
        let Data = Labels[0];
        if (!Object.keys(Data).includes('value_conf_old')){
            Data['value_conf_old'] = Data['value_conf']
            Data['value_txt_old'] = Data['value_txt']
        }
        Data['value_conf'] = 100
        Data['value_txt'] = Correction ? Correction : Data['value_txt']
        await simplepost({Data: JSON.stringify(Data)}, '/ftree/updatelabel').then(res => {
            setTimeout(() => {
                setLabels(state => state.slice(1));
                setCorrection(null);
                setLoading(false);
            }, 200);
        });
    };

    const handleChange = e => {        
        setCorrection(e.target.value);
    }

    const HandleActionMenu = e => {
        e.stopPropagation();
        let dim = e.target.getBoundingClientRect();
        if (ActionMenuLoc){
            setActionMenuLoc(null);
        } else {
            setActionMenuLoc([dim.left, dim.top + dim.height])
        }
    }

    const HandleAction = async (action) => {
        console.log('action: ', action);
        let Data = Labels[0];
        let FileID = Data['FileID'];
        let PageID = Data['PageID'];
        let sendData
        if (action === 'Skip all in page'){
            sendData = {PageID}
        } else if (action === 'Skip all in file'){
            sendData = {FileID}
        } else {
            if (window.confirm('Are you sure that you want to skip all?')){
                sendData = {'all': 'all'}
            } else {
                setActionMenuLoc(null);
            }
        }
        await simplepost({Data: JSON.stringify(sendData)}, '/ftree/LabelSkipBatch').then(res => {
            window.location.reload();            
        })
    }

    return (
        <div style={{width: "100%", marginTop: '20px'}}>
            {ActionMenuLoc?<ActionMenu Location={ActionMenuLoc} 
                                       ActionList={['Skip all in page', 'Skip all in file', 'Skip all']}
                                       HandleAction={HandleAction}
                                       handleDisappear={()=>{setActionMenuLoc(null)}} />: null}
            <h5 style={{marginBottom: 0}}>{translate('Quick Action')}</h5>
            <hr style={{marginTop: 0}} />
            {TurnOn?
                Loading?
                    <Container className="LabelView">
                        <div className="LabelViewLoading">
                            <IMGLoading />
                        </div>
                    </Container>
                    :
                    Labels.length > 0?
                        <Container className="LabelView">
                            <Row style={{maxWidth: '350px', minWidth: '350px'}}>
                                <Col style={{display:'flex', overflow:'auto', }}>
                                    <strong>From:</strong><div style={{whiteSpace: 'nowrap'}}>{Labels[0].Foldername?Labels[0].Foldername:'N/A'}</div>
                                </Col>
                                <Col xs={2}>
                                    <MdMoreVert style={{float:'right' }} className='MoreAction' onClick={(e)=>{HandleActionMenu(e)}}/>
                                </Col>
                            </Row>
                            <Row style={{maxWidth: '350px', minWidth: '350px'}}>
                                <Col style={{display:'flex', overflow:'auto', }}>
                                    <strong>File:</strong><div style={{whiteSpace: 'nowrap'}}>{Labels[0].Filename?Labels[0].Filename:'N/A'}</div>
                                </Col>
                            </Row>
                            <Row style={{maxWidth: '350px', minWidth: '350px'}}>
                                <Col style={{display:'flex', overflow:'auto', }}>
                                <strong>Page:</strong><div style={{whiteSpace: 'nowrap'}}>{Number(Labels[0].PageID.split('PAGE')[1])+1}</div>
                                </Col>
                            </Row>
                            <Row className='LabelViewRow' style={{minHeight: '64px', maxHeight: '64px'}}>
                                <Col><img src={Labels[0].value_img} style={{maxHeight:'64px', maxWidth: '100%'}} /></Col>
                            </Row>
                            <hr style={{marginTop: '8px', marginBottom: '8px'}} />
                            <Row><Col><strong>Result</strong></Col></Row>
                            <Row><Col><Form.Control placeholder={Labels[0].value_txt} 
                                                    value={Correction!=null?Correction:Labels[0].value_txt}
                                                    onChange={(e)=>handleChange(e)}/></Col></Row>
                            <hr style={{marginTop: '8px', marginBottom: '8px'}} />
                            <Row className='LabelViewRow'>
                                <Col style={{height:'40px', overflow:'hidden'}}>
                                    <strong>Field:</strong><p>{Labels[0].key_txt}</p>
                                </Col>
                                <Col style={{height:'40px', overflow:'hidden'}} xs={6}>
                                    <strong>Confidence:</strong>
                                    <p style={Labels[0].value_conf > Labels[0].sensitive/2?{borderLeft: "solid yellow 10px"}:{borderLeft: "solid red 10px"}}>{Labels[0].value_conf}%</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{justifyContent: 'center', display: 'flex'}}>
                                    <Button style={{width: '100%'}} variant="secondary" onClick={()=>SkipCurrent()}>Skip</Button>
                                </Col>
                                <Col style={{justifyContent: 'center', display: 'flex'}}>
                                    <Button style={{width: '100%'}} variant="success" onClick={()=>MarkCorrect()}>Correct</Button>
                                </Col>
                            </Row>
                            <Row><Col style={{textAlign: 'right', color:'darkgray'}}><strong style={{color: 'coral'}}>{Labels.length}</strong>Left</Col></Row>
                        </Container>
                        :
                        <Container className="LabelView">No data available, please upload documents.</Container>
                :
                // <a className="QuickActionSwitch" href='' onClick={(e)=> {e.preventDefault(); setTurnOn(!TurnOn)}}>
                <a className="QuickActionSwitch" href='' onClick={fetchData}>
                    <HiOutlineArrowsExpand />
                    <span>Explore</span>
                </a>
            }
        </div>
    )
};

export default QuickAction;
