const { NODE_ENV } = process.env;

import { createStore, applyMiddleware } from 'redux'; // compose
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import rootReducer from '../reducers/root_reducer';

const thunk_logger_middleware = () => {
  // , window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  return (NODE_ENV !== "production")
      ? applyMiddleware(thunk, logger)
      : applyMiddleware(thunk)
}

const configureStore = (preloadedState = {}) => (
  createStore(
    rootReducer,
    preloadedState,
    thunk_logger_middleware(),
  )
);

export default configureStore;
