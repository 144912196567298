import React from 'react';

import { withRouter } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import { uniqueId } from '../../helpers/id_generator';

class VerifyEmail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {errors: []};

  }

  componentWillMount() {
    // Go to dash if already login
    if (this.props.loggedIn) this.props.history.push('/dashboard');

    // Else do the verify email process
    const qs = new URLSearchParams(this.props.history.location.search);
    if (qs.has('token') && qs.has('email')) {
      const data = { 
        token: decodeURIComponent(qs.get('token')), 
        email: decodeURIComponent(qs.get('email')) 
      };

      if (isMobile) {
        window.location = `docupal://verify-email/${data.token}/${data.email}`;
      }

      this.props.verifyEmail(data).then(result => this.props.history.push('/update-password'));
      
      
    } else {
      this.setState({ errors: ['Not a valid URL'] })
    }
  }

  displayError() {
    const errors = this.state.errors.length > 0 ? this.state.errors : this.props.errors;

    if (errors.length > 0) {
      return (
        <div className="login-error">
        { 
          errors.map((error, idx) => {
            return (
              <h4 key={idx + uniqueId()}>{error}</h4>
            )
          })
        }
        </div>
      );
    }
  }

  render() {
    return (
      <div className="verify-email">
        <h1>Authenticating...</h1>
        
        {this.displayError()}
      </div>
    );
  }
}

export default withRouter(VerifyEmail);
