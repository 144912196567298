// Action types
import {
  RECEIVE_ALL_USERS,
  RECEIVE_ADMIN_DATA,
  RECEIVE_ADMIN_COMPANY_DETAILS,
} from '../helpers/action_types';
  
  const _initalState = {"companyData": {}};
  
  const admin_reducer = (state = _initalState, action) => {
    Object.freeze(state);
    let newState = Object.assign({}, state);
  
    switch (action.type) {
      case RECEIVE_ALL_USERS:
          newState['all_users'] = action.all_users;
          return newState;

      case RECEIVE_ADMIN_DATA:
        newState['adminData'] = action.data;
        newState['adminDataLoaded'] = true;
        
        // add some initial data for companyData, in case admin opens this company's detail page
        action.data.map((v, i) => {
          let prevCompanyData = newState['companyData'][v.uid] || {};
          newState['companyData'][v.uid] = Object.assign({}, prevCompanyData, {name: v.company});
        })

        return newState;

      case RECEIVE_ADMIN_COMPANY_DETAILS:
        let prevData = newState['companyData'][action.data.company_uid] || {};
        newState['companyData'][action.data.company_uid] = Object.assign({}, prevData, action.data);
        return newState;
  
      default:
        return state;
    }
};

export default admin_reducer;
