import {
  RECEIVE_CURRENT_USER,
  RECEIVE_SIGNUP_USER,
  LOGOUT_USER,
  UPDATE_CURRENT_USER,
  SET_SHARE_TOKEN
} from '../helpers/action_types';
import io from 'socket.io-client';
import { SERVER_URL } from '../helpers/constants';
import { receivedNotification } from '../actions/notification_actions';

const _nullSession = {
  currentUser: null,
  socket: null,
  shareToken: null
};

const SessionReducer = (state = _nullSession, action) => {
  Object.freeze(state);
  let newState;
  
  switch (action.type) {
    case RECEIVE_CURRENT_USER:
      const currentUser = action.user.user;
      localStorage.setItem('token', action.user.token);
      localStorage.setItem('user', JSON.stringify(currentUser));

      return Object.assign({}, _nullSession, { currentUser, socket: createSocket({ user: currentUser.id }) });

    case RECEIVE_SIGNUP_USER:
      localStorage.setItem('email-sent', action.user.email);

      return _nullSession;
      
    case LOGOUT_USER:
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('email-sent');
      state.socket !== null ? state.socket.disconnect() : '';

      return _nullSession;

    case UPDATE_CURRENT_USER:
      const updatedUser = action.user.user;
      // localStorage.setItem('token', action.user.token);
      localStorage.setItem('user', JSON.stringify(updatedUser));
      // state.socket !== null ? state.socket.disconnect() : '';
      // return Object.assign({}, _nullSession, { currentUser:updatedUser, socket: createSocket({ user: currentUser.id }) });
      return Object.assign({}, _nullSession, { currentUser: updatedUser });


    case SET_SHARE_TOKEN:
      return { shareToken: action.shareToken, currentUser: state.currentUser, socket: state.socket };
    default:
      return state;
  }
};

function createSocket(paramId) {
  // socket = openSocket(SERVER_URL, { query: paramId, transports: ['websocket'], upgrade: false });
  // socket.on('reconnect', function () { window.location.reload(); });

  const socket = io(SERVER_URL, {
    query: paramId, transports: ['websocket'], upgrade: false,
    reconnection: true, reconnectionDelay: 1000, reconnectionDelayMax: 5000,
    reconnectionAttempts: Infinity
  });

  socket.on('connect', function(){
    console.log('Socket connected to server');
  })

  socket.on('NEW_WS_NOTIFICATION', notification => {
    receivedNotification(notification)(store.dispatch);
  })
  
  return socket;
}


export default SessionReducer;
