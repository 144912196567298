function bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
 }

function zfill(n, width, z) {
   z = z || '0';
   n = n + '';
   return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

function FilterObjectByKey(InputObj, ListKeys){
   return (
      Object.keys(InputObj)
         .filter(key => ListKeys.includes(key))
         .reduce((obj, key) => {
            obj[key] = InputObj[key];
            return obj}, {})
   )
}


module.exports = {
   bytesToSize, zfill, FilterObjectByKey
}