import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import { translate } from "../../helpers/translate";
import "./login_page.css";

export default class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email1: "", // sign in email
      email2: "", // sign up email
      email3: "", // forget password email
      email3_repeat: "", // forget password email
      password: "",
      active_tab: "1",
      forget_password: false,
      warn: "",
    };

    this.update = this.update.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.displayError = this.displayError.bind(this);
    this.responseGoogle = this.responseGoogle.bind(this);
  }

  displayError() {
    let errors = this.state.warn ? this.state.warn : this.props.errors;

    if (errors === undefined || errors === null) return null;

    return (
      <p>
        <div className="login-error">
          <h6>{errors}</h6>
        </div>
      </p>
    );
  }

  validateForm() {
    return (
      this.state.email1.length > 0 &&
      this.state.email1.indexOf("@") != -1 &&
      this.state.password.length > 0
    );
  }

  update(type) {
    return (e) =>
      this.setState({
        [type]: e.currentTarget.value,
      });
  }

  responseGoogle(response) {
    if (response.error) {
      this.setState({
        warn: "Please allow third party cookies in order to use a social login.",
      });
      return;
    }
    const classThis = this;
    this.props.googleLogin(response.tokenId).catch((res) => {
      classThis.props.history.push({
        pathname: "/auth/register/social",
        state: { response },
      });
    });
  }

  onSubmit() {
    const user = {
      email: window.$("#login-form #login-email").val(),
      password: window.$("#login-form #login-password").val(),
    };

    this.props.login(user).then((response) => {
      if (response) {
        this.setState({
          warn: response.user.response,
        });
      }
      this.props.fetchAllNotifications();
      this.props.fetchUnreadCount();
      this.props.getRateLimit();
    });
  }

  render() {
    if (this.props.currentUser !== null) {
      window.location.reload();
    }

    return (
      <div className="auth-outer">
        <div className="inner">
          <form id="login-form">
            {
              /* Checks if the user has just registered through a social login */
              this.props.location.state &&
                this.props.location.state.justRegistered && (
                  <div className="justRegistered-dialog">
                    <p>
                      ✔ Your account was successfully created. You can now use
                      it to log in.
                    </p>
                  </div>
                )
            }
            <img
              src="assets/images/DocuPal_solid_transp.png"
              class="logo"
              alt=""
            />

            <h3>{translate("Log in")}</h3>

            <div class="social-buttons">
              <GoogleLogin
                // clientId="84641549428-qeiqo5shqg628njkl0srigfq2bi88bf8.apps.googleusercontent.com"
                clientId="259904413532-7aha6e36s0vfdrm1kp0hmr9tk3ampqnf.apps.googleusercontent.com"
                onSuccess={this.responseGoogle}
                onFailure={this.responseGoogle}
                cookiePolicy={"single_host_origin"}
              />
            </div>

            <div className="form-group">
              <label>{translate("Email")}</label>
              <input
                required
                type="email"
                className="form-control"
                autoComplete="login-email"
                placeholder="Enter email"
                id="login-email"
                name="login-email"
              />
            </div>

            <div className="form-group">
              <label>{translate("Password")}</label>
              <input
                required
                type="password"
                className="form-control"
                placeholder="Enter password"
                id="login-password"
              />
            </div>

            <div className="form-group">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="remember_me"
                />
                <label className="custom-control-label" htmlFor="remember_me">
                  {translate("Remember this account")}
                </label>
              </div>
            </div>

            <button
              type="button"
              onClick={() => {
                this.onSubmit(this.props.login);
              }}
              className="btn btn-dark btn-lg btn-block"
            >
              {translate("Sign in")}
            </button>

            <p className="forgot-password text-right">
              {translate("New user")}?{" "}
              <NavLink to="/auth/register">
                {translate("Register an account")}
              </NavLink>
            </p>
            <p className="forgot-password text-right">
              <NavLink to="/auth/forgot_password">
                {translate("Forgot password")}?
              </NavLink>
            </p>

            <this.displayError />
          </form>
        </div>
      </div>
    );
  }
}
