// Action types
import {
    CHOOSE_A_LANGUAGE,
} from '../helpers/action_types';

const _initalState = 'eng';

const ChooseALanguageReducer = (state = _initalState, action) => {
    Object.freeze(state);
    let newState;

    switch (action.type) {
        case CHOOSE_A_LANGUAGE:
            newState = action.data;
            return newState;
            
        default:
            return state
    }
}

export default ChooseALanguageReducer;