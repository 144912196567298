import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {translate} from '../../helpers/translate';
import { mimeIconMap } from '../../helpers/IconHelper';
import './style.css';

const ExportView = ({CurrentFolder, AllSelected, OutputMethod}) =>{
    const [ChooseWhat, setChooseWhat] = useState('Results'); // Results or Reports or PDF, Reports is accuracy stats
    const [ChoosePages, setChoosePages] = useState('All');
    const [CustomValue, setCustomValue] = useState(null);
    const [SaveAs, setSaveAs] = useState('Excel');
    const [PageInSheet, setPageInSheet] = useState('Single');
    const [OutputName, setOutputName] = useState(null);
    const [CharacterSize, setCharacterSize] = useState('half');

    // console.log('AllSelected: ', AllSelected);
    // console.log('CurrentFolder: ', CurrentFolder);
    // console.log('AllowChooseCharSize: ', AllowChooseCharSize);
    const AllowChooseCharSize = CurrentFolder.lang === 'jpn' || CurrentFolder.lang === 'kor' || CurrentFolder.lang === 'chi_sim' || CurrentFolder.lang === 'chi_tra';

    useEffect(()=> {
        if (ChoosePages || CustomValue || SaveAs || PageInSheet || OutputName){
            OutputMethod({'ChooseWhat': ChooseWhat,
                          'SaveAs': SaveAs, 
                          'Pages': ChoosePages==='All'?'All':CustomValue,
                          'PageInSheet': PageInSheet,
                          'OutputName': OutputName,
                          'CharacterSize': CharacterSize});
        }
    })

    return (
        <Form className="ExportViewParent">
            <Row className="ExportViewFileList">
                <div className='cell-icon cell-icon-small'>
                    {AllSelected.map((file, idx) => {
                        return (file.FileID?
                                <div id="TitleParent">
                                    {mimeIconMap(file.title)}
                                    <a id="TitleName" >{file.title}</a>
                                </div>
                                :
                            null)
                        })
                    }
                </div>
            </Row>
            <hr />
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>{translate('Export type')}</Form.Label>
                </Form.Group>
                <Col xs={8}>
                    <Form.Control as="select" onChange={e=>setChooseWhat(e.target.value)}>
                        <option selected={ChooseWhat==='Results'} value={'Results'}>Results</option>
                        <option selected={ChooseWhat==='Reports'} value={'Reports'}>Reports</option>
                        <option selected={ChooseWhat==='PDF'} value={'PDF'}>PDF - searchable</option>
                    </Form.Control>
                </Col>
            </Row>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>{translate('Save format')}</Form.Label>
                </Form.Group>
                <Col xs={8}>
                    {ChooseWhat==='PDF'?
                    <Form.Control as="select" readOnly={true} disabled={true}>
                        <option selected={true} value={'PDF'}>PDF</option>
                    </Form.Control>:
                    <Form.Control as="select" onChange={e=>setSaveAs(e.target.value)} 
                    readOnly={ChooseWhat!='Results'?true:false} disabled={ChooseWhat!='Results'?true:false}>
                        <option selected={SaveAs==='Excel'} value={'Excel'}>Excel</option>
                        <option selected={SaveAs==='CSV'} value={'CSV'}>CSV</option>
                    </Form.Control>
                    }
                </Col>
            </Row>
            {ChooseWhat==='PDF' && AllSelected.length>1?null:
                <Row>
                <Form.Group as={Col}>
                    <Form.Label>{translate('Choose pages')}</Form.Label>
                </Form.Group>
                <Col xs={8}>
                    <Form.Control as="select" onChange={e=>setChoosePages(e.target.value)} 
                    readOnly={ChooseWhat==='Reports'?true:false} disabled={ChooseWhat==='Reports'?true:false}>
                        <option selected={ChoosePages==='All'} value={'All'}>{translate('All')}</option>
                        <option selected={ChoosePages==='Custom'} value={'Custom'}>{translate('Custom')}</option>
                    </Form.Control>
                    {
                        ChoosePages === 'Custom'?
                        <Form.Control value={CustomValue} placeholder='e.g. 1-6, 8, 10-100'
                                      onChange={e=>setCustomValue(e.target.value)} 
                                      readOnly={ChooseWhat==='Reports'?true:false} disabled={ChooseWhat==='Reports'?true:false}>
                        </Form.Control>
                        :null
                    }
                </Col>
            </Row>
            }
            {ChooseWhat==='PDF'?null:
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>{translate('All pages in')}</Form.Label>
                </Form.Group>
                <Col xs={8}>
                    <Form.Control as="select" onChange={e=>setPageInSheet(e.target.value)} 
                        readOnly={ChooseWhat!='Results'}>
                        <option selected={PageInSheet==='Single'} value={'Single'}>{translate('Same sheet')}</option>
                        <option selected={PageInSheet==='Multiple'} value={'Multiple'}>{translate('Separated sheets')}</option>
                    </Form.Control>
                </Col>
            </Row>
            }
            {ChooseWhat==='PDF' || ChooseWhat==='Reports' || !AllowChooseCharSize ?null:
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>{translate('Full-half-width convert')}</Form.Label>
                </Form.Group>
                <Col xs={8}>
                    <Form.Control as="select" onChange={e=>setCharacterSize(e.target.value)}>
                        <option selected={CharacterSize==='half'} value={'half'}>{translate('Half-width')}</option>
                        <option selected={CharacterSize==='full'} value={'full'}>{translate('Full-width')}</option>
                    </Form.Control>
                </Col>
            </Row>
            }
            <hr />
            <Row>
                <Form.Group as={Col}><Form.Label>{translate('Output name')}</Form.Label></Form.Group>
                <Col xs={8}>
                    <Form.Control value={OutputName} placeholder={`Default  (.${ChooseWhat==='PDF'?'pdf':SaveAs==='Excel'?'xlsx':'csv'})`}
                                onChange={e=>setOutputName(e.target.value)} 
                                readOnly={ChooseWhat==='Reports'?true:false} disabled={ChooseWhat==='Reports'?true:false}>
                    </Form.Control>
                </Col>
            </Row>
        </Form>
    )
};

export default ExportView;