// Action types
import {
    RECEIVE_SEARCH_DATA,
} from '../helpers/action_types';

const _initalState = {
    results: null /* {
        Folders: [],
        Files: [],
    } */
};

const search_reducer = (state = _initalState, action) => {
    Object.freeze(state);
    let newState = Object.assign({}, state);

    switch (action.type) {
    case RECEIVE_SEARCH_DATA:
        newState['results'] = action.data;
        return newState;

    default:
        return state;
    }
};

export default search_reducer;
