let getPercentage = (x, y) => {
    return y === 0? 0 : x / y * 100
}

function GetData(Keyname, FileInfo, PageInfo){

    let pages_pairs = PageInfo.map(x => x.ky_pairs);
    let all_pairs = pages_pairs.flat();

    let chosenPairs;
    if (!Keyname){
        chosenPairs = all_pairs;
    } else {
        chosenPairs = all_pairs.filter(x => x.type === Keyname);
    };
    
    let non_empty_pairs, all_warnings, all_warning_corrected, all_FalsePositive, all_FalseAlarm;
    non_empty_pairs = chosenPairs.filter(x => !((x.value_conf===99 || x.value_conf===-1) && (x.value_txt === '')));
    all_warnings = non_empty_pairs.filter(x => (x.value_conf_old===undefined?x.value_conf:x.value_conf_old) < x.sensitive );
    all_warning_corrected = all_warnings.filter(x => x.value_conf_old < x.sensitive && x.value_txt != x.value_txt_old);
    all_FalsePositive = non_empty_pairs.filter(x => Object.keys(x).includes('value_txt_old') && x.value_conf_old >= x.sensitive && x.value_txt != x.value_txt_old);
    all_FalseAlarm = all_warnings.filter(x => x.value_txt === x.value_txt_old);
    let overall_accuracy = `${Math.round((getPercentage(non_empty_pairs.length - all_FalsePositive.length - all_warning_corrected.length, non_empty_pairs.length)+Number.EPSILON) * 10)/10}%`
    return ['', non_empty_pairs.length, all_warnings.length, all_FalseAlarm.length,
        all_warning_corrected.length, all_FalsePositive.length, overall_accuracy ]
}

function getReportCSV(FileInfo, PageInfo){
    if (Array.isArray(PageInfo[0])){
        PageInfo = PageInfo[0];
    };
    let total_field_types = PageInfo[0].ky_pairs.map(x => x.type);
    total_field_types = Array.from(new Set(total_field_types));
    let all_data = GetData(null, FileInfo, PageInfo)
    let csvData = [
        [FileInfo.title,'All'].concat(total_field_types),
        ['Total Non-empty'],
        ['Warnings'],
        ['False Alarm'],
        ['Corrected warnings'],
        ['False positive'],
        ['Overall accuracy'],
        [' ']
    ];
    for (let i=1; i<all_data.length; i++){
        csvData[i] = csvData[i].concat([all_data[i]])
    }
    for (let i=0; i<total_field_types.length; i++){
        let field_type = total_field_types[i];
        let current_data = GetData(field_type, FileInfo, PageInfo);
        for (let j=1; j<current_data.length; j++){
            csvData[j] = csvData[j].concat([current_data[j]])
        }
    }
    return csvData
}

function getReportCSVinOne(zipped_list){
    let combined = [];
    for (let i=0; i<zipped_list.length; i++){
        let FileInfo = zipped_list[i][0];
        let PageInfo = zipped_list[i][1];
        let singleCSVData = getReportCSV(FileInfo, PageInfo);
        combined = combined.concat(singleCSVData);
    };
    return combined
}

module.exports = {
    getReportCSV, getReportCSVinOne
}