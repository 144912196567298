import constants from '../../../helpers/constants'
import React, { useState, useRef, useEffect } from 'react'
import './DragNDrop.css'

/**
 * This component allows the user to drag files directly to the filebox, without needing to open upload first.
 * 
 * Since it's not always wanted for the user to be able to drop files, use the active prop to indicate if anything
 * should be done when files are dropped
 * 
 * Based on: https://medium.com/@650egor/simple-drag-and-drop-file-upload-in-react-2cb409d88929
 *           https://betterprogramming.pub/how-to-implement-files-drag-and-drop-in-react-22cf42b7a7ef
 */
export default function DragNDrop({ children, active, onUpload }) {
    const [dragging, setDragging] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const drop = useRef(null);

    useEffect(() => {
        setDragging(false);
        if (drop.current) {
            drop.current.addEventListener('dragover', handleDragOver);
            drop.current.addEventListener('drop', handleDrop);
            drop.current.addEventListener('dragenter', handleDragEnter);
            drop.current.addEventListener('dragleave', handleDragLeave);
        }
    
        return () => {
            if (drop.current) {
                drop.current.removeEventListener('dragover', handleDragOver);
                drop.current.removeEventListener('drop', handleDrop);
                drop.current.removeEventListener('dragenter', handleDragEnter);
                drop.current.removeEventListener('dragleave', handleDragLeave);
            }
        };
    }, [active]);
      
    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };
      
      const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setDragging(false);

        const files = [...e.dataTransfer.files];

        // check if some uploaded file is not in one of the allowed formats
        if (files.some((file) => !constants.allowedFileTypes.some((format) => file.name.toLowerCase().endsWith(format)))) {
            showErrorMessage(`Only ${constants.allowedFileTypes.join(', ')} are allowed`);
            return;
        }

        if (files && files.length) {
            onUpload(files);
        }
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
    
        if (e.target !== drop.current) {
            setDragging(true);
        }
    };
    
    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
    
        if (e.target === drop.current) {
            setDragging(false);
        }
    };

    const showErrorMessage = (text) => {
        setErrorMsg(text);
      
        setTimeout(() => setErrorMsg(''), 2000);
    };

    return active ? (
                <div ref={drop}>
                    {(dragging || errorMsg) &&
                        <div className={errorMsg ? "dnd-placeholder dnd-error" : "dnd-placeholder"} ref={drop}>
                            <div className={errorMsg ? "dnd-text dnd-error-text" : "dnd-text"}>
                            {dragging && <div>Drop your documents here</div> }
                            {errorMsg && <div>{errorMsg}</div> }
                            </div>
                        </div>
                        }
                    { children }
                </div>
            ) : <div>{ children }</div>
}
