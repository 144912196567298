import React, { Component } from "react";
import { NavLink } from "react-router-dom";

// import { RegisterUser } from '../../services/UsersService';

export default class RegisterPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: null,
      company: null,
      email: null,
      phone1: null,
      phone2: null,
      phone3: null,
      warn: null,
      notifications: null,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.displayError = this.displayError.bind(this);
    this.displayNotifications = this.displayNotifications.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.loggedIn) return this.props.history.push("/dashboard");
  }

  validateField(field, value) {
    if (value === null) return true;

    if (field === "email")
      return value.length >= 3 && /[^@]+\@[^\.]+\..*/.test(value.toLowerCase());

    if (field === "bizemail")
      return (
        value.length >= 3 &&
        /[^@]+\@[^\.]+\..*/.test(value.toLowerCase()) &&
        /\@(?!(me|mac|icloud|gmail|googlemail|hotmail|live|msn|outlook|yahoo|ymail|aol)\.)/.test(
          value.toLowerCase()
        )
      );

    if (field === "name") return value.length >= 1;

    if (field === "company") return value.length >= 1;

    console.error("must add validation for field:", field);
    return false;
  }

  handleInputChange(e) {
    let new_state = {};
    new_state[e.target.id.split("-")[1]] = e.target.value;
    this.setState(new_state);
  }

  onSubmit() {
    let name = window.$("#register-form #register-name").val() || "";
    let company = window.$("#register-form #register-company").val() || "";
    let email = window.$("#register-form #register-email").val() || "";
    // let password = window.$("#register-form #register-password").val() || "";

    var arr_name = name.split(" ").map((item) => item.trim());

    const user = {
      // firstname: arr_name[0],
      // lastname: arr_name[1] ? arr_name[1] : '',
      name: name,
      email: email,
      company: company,
    };

    this.setState({
      name: name,
      email: email,
      company: company,
    });

    if (
      this.validateField("email", email) &&
      this.validateField("name", name) &&
      this.validateField("company", company)
    ) {
      if (!this.validateField("bizemail", email)) {
        this.setState({ warn: ["Please use a business email."] });
        return false;
      }

      let agree_terms = window.$("#register-form #agree_terms").prop("checked");
      if (!agree_terms) {
        this.setState({
          warn: [
            "You must agree with our terms and conditions before registering.",
          ],
        });
        return false;
      }

      this.setState({ warn: null, notifications: null });

      this.props
        .signup(user)
        .then((result) => {
          console.log(result);
          this.setState({
            notifications: [result.user.response] || [
              "A link has been sent to your email, please verify it in order to proceed.",
            ],
          });
        })
        .catch((error) => {
          console.log(error);
          // this.setState({notifications: error.user.response})
          // this.setState({warn: ["An error occurred. Please try again later."]})
        });
    } else {
      this.setState({ warn: ["Please fill all the fields correctly."] });
    }
  }

  displayError() {
    let errors = this.state.warn ? this.state.warn : this.props.errors;

    if (errors === undefined || errors === null || errors.length === 0)
      return null;

    if (this.props.errors) {
      // this.props.clearErrors()
    }

    return (
      <p>
        <div className="login-error">
          <h6>{errors}</h6>
        </div>
      </p>
    );
  }

  displayNotifications() {
    let notifications = this.state.notifications
      ? this.state.notifications
      : null;
    console.log("Notifications to show:", notifications);

    if (notifications === undefined || notifications === null) return null;

    return (
      <p>
        <div className="login-success">
          <h6>{notifications}</h6>
        </div>
      </p>
    );
  }

  render() {
    return (
      <div className="auth-outer">
        <div className="inner">
          <form id="register-form">
            <img
              src="assets/images/DocuPal_solid_transp.png"
              class="logo"
              alt=""
            />

            <h3>Register</h3>

            <div className="form-group">
              <label>Name</label>
              <input
                type="text"
                id="register-name"
                className={
                  "form-control" +
                  (this.validateField("name", this.state.name)
                    ? ""
                    : " is-invalid")
                }
                placeholder="Your name"
                onChange={this.handleInputChange}
              />
            </div>

            <div className="form-group">
              <label>Company Name</label>
              <input
                type="text"
                id="register-company"
                className={
                  "form-control" +
                  (this.validateField("company", this.state.company)
                    ? ""
                    : " is-invalid")
                }
                placeholder="Company Name"
                onChange={this.handleInputChange}
              />
            </div>

            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                id="register-email"
                className={
                  "form-control" +
                  (this.validateField("email", this.state.email)
                    ? ""
                    : " is-invalid")
                }
                placeholder="Enter email"
                onChange={this.handleInputChange}
              />
            </div>

            <div className="form-group">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="agree_terms"
                />
                <label className="custom-control-label" htmlFor="agree_terms">
                  By checking this box, you agree with{" "}
                  <NavLink to="/auth/terms_conditions" target="_blank">
                    our Terms and Conditions
                  </NavLink>
                  .
                </label>
              </div>
            </div>

            <button
              type="button"
              onClick={() => this.onSubmit()}
              className="btn btn-dark btn-lg btn-block"
            >
              Register
            </button>

            <p className="forgot-password text-right">
              Already registered?{" "}
              <NavLink to="/auth/login">Login instead</NavLink>
            </p>

            <this.displayError />
            <this.displayNotifications />
          </form>
        </div>
      </div>
    );
  }
}
