import React, { useState, useEffect } from 'react';  
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import {FaSearch} from '@react-icons/all-files/fa/FaSearch';
import { MdFolder } from '@react-icons/all-files/md/MdFolder';
import { mimeIconMap } from '../../helpers/IconHelper';
import {translate} from '../../helpers/translate';
import SearchResult from './components/searchResult';


const Search = ({doSearch, SearchResults}) => {
  const [searchTyping, setsearchTyping] = useState(null);
  const [SearchResultsLoc, setSearchResultsLoc] = useState({Files: [], Folders: [], ContentMatches: []});
  const [Empty, setEmpty] = useState(true);
  const [TypeTime, setTypeTime] = useState(0);
  const [IsSearching, setIsSearching] = useState(false);

  useEffect(() => {
    // console.log('value changed!', SearchResults);
    setSearchResultsLoc(SearchResults)
  });

  const HandleSearch = (e) => {
    let value = e.target.value;

    if (TypeTime) clearTimeout(TypeTime);
    setIsSearching(true);
    if (value.length === 0) {
        setSearchResultsLoc({Files: [], Folders: [], ContentMatches: []});
        setEmpty(true);
        setIsSearching(false);
    } else{
        setTypeTime(setTimeout(() => {
            doSearch(value);
            setEmpty(false);
            setIsSearching(false);
        }, 500));
    }
  }

  const openResult = (e, redirect_url) => {
    window.location.assign(redirect_url);
    // setEmpty(true)
  }

  return (
    <InputGroup style={{margin: '5px 10px 5px 0px'}}>
        <InputGroup.Prepend>
            <InputGroup.Text><FaSearch /></InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl 
          onFocus={()=>setsearchTyping(true)} 
          onBlur={()=>setsearchTyping(false)} 
          onChange={(e)=>HandleSearch(e)} 
          type="search"
          placeholder={translate('Search files')} />
          {(SearchResultsLoc.Files.length > 0 || SearchResultsLoc.Folders.length > 0 || SearchResultsLoc.ContentMatches.length > 0) && !Empty && searchTyping?
              <div className="SearchDropDown">
                  {
                    /* Folders */
                      SearchResultsLoc.Folders.map((item, SearchIdx) => {
                          let redirectURL = "/#/folder/" + item.FolderID;

                          return <SearchResult item={item} i={SearchIdx} redirectURL={redirectURL} openResult={openResult} />
                      })
                  }
                  {
                    /* Files */
                      SearchResultsLoc.Files.map((item, SearchIdx) => {
                          let redirectURL = "/#/folder/" + item.PARENT_FOLDER_ID + "?highlight=" + item.FileID;

                          return <SearchResult item={item} i={SearchIdx} redirectURL={redirectURL} openResult={openResult} />
                      })
                  }
                  {
                    /* Files with contents */
                      SearchResultsLoc.ContentMatches.map((item, SearchIdx) => {
                          let redirectURL = "/#/folder/" + item.PARENT_FOLDER_ID + "?highlight=" + item.FileID;

                          return <SearchResult item={item} i={SearchIdx} redirectURL={redirectURL} openResult={openResult} />
                      })
                  }
              </div>
          :searchTyping?
            <div className="SearchDropDown">
                {IsSearching?
                    <div className="NoResult">Searching ...</div>
                    :<div className="NoResult">No results found</div>}
            </div>:null}
    </InputGroup>
  );
};
export default Search;