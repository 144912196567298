import { post, postAuth, multipartPostAuth, SimplePost, SimpleGet, Generate_req, admin_post_req, UpdateUserPut, JSONPostWithShareToken, JSONPost } from '../services/routes';

export const readAllRequests = (user, url='/requests/readAll') => postAuth(user, url);
export const readBatchRequests = (user, url='/requests/read20') => postAuth(user, url);
export const shareTokenPost = (token, body, url) => JSONPostWithShareToken(token, body, url);
export const getAllGeoInfo = (user, url='/requests/getGeo') => postAuth(user, url);
export const PostRequest = (user, url='/requests/postReq', progressCallback) => multipartPostAuth(user, url, progressCallback);
export const PostRequestGuest = (user, url='/requests/postReqGuest') => multipartPostAuth(user, url);
export const PostRequestS3 = (user, url='/requests/posts3') => multipartPostAuth(user, url);
export const simplePost = (user, url='/requests/simplePost') => SimplePost(user, url);
export const simpleGet = (user, url='/requests/get_a_request') => SimpleGet(user, url);
export const jsonPost = (data, url) => JSONPost(data, url);
export const Generate_excel_req = (data, url='/requests/generate_req') => Generate_req(data, url);
export const guest_post = (data, url='/requests/simplePostGuest') => SimplePost(data, url);
//check the endpoint
export const update_user = (data, url = '/requests/update_user') => UpdateUserPut(data, url);