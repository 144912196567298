import React from "react";
import { withRouter, Link } from "react-router-dom";
import Sidebar_module from "./Sidebar_module";

import { MdHome } from "@react-icons/all-files/md/MdHome";
// import { MdInbox } from '@react-icons/all-files/md/MdInbox';
import { MdFolderShared } from "@react-icons/all-files/md/MdFolderShared";
import { MdAccountBox } from "@react-icons/all-files/md/MdAccountBox";
import { MdHelp } from "@react-icons/all-files/md/MdHelp";
// import { MdDelete } from '@react-icons/all-files/md/MdDelete';
import { FaTrashAlt } from "@react-icons/all-files/fa/FaTrashAlt";
import { FaFolder } from "@react-icons/all-files/fa/FaFolder";
import { BsFillClockFill } from "@react-icons/all-files/bs/BsFillClockFill";
import { BsFillStarFill } from "@react-icons/all-files/bs/BsFillStarFill";

import { RiAdminFill } from "@react-icons/all-files/ri/RiAdminFill";
import { MdMail } from "@react-icons/all-files/md/MdMail";
import { MdLabel } from "@react-icons/all-files/md/MdLabel";
import { RiProfileFill } from "@react-icons/all-files/ri/RiProfileFill";
import { MdNotificationsActive } from "@react-icons/all-files/md/MdNotificationsActive";
import { HiDocumentReport } from "@react-icons/all-files/hi/HiDocumentReport";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      warn: "",
    };
    this.onClick = this.onClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    if (localStorage.getItem(`Language`)) {
      this.props.ChooseLanguage(localStorage.getItem(`Language`));
    }
  }

  getCurrentLocation() {
    return this.props.location.pathname.split("/").slice(1).filter(Boolean)[0];
  }

  update(type) {
    return (e) =>
      this.setState({
        [type]: e.currentTarget.value,
      });
  }

  handleSubmit(e) {
    e.preventDefault();
    const user = Object.assign({}, this.state);
    this.props
      .login(user)
      .then((response) => {
        if (response) {
          this.setState({
            warn: response.user.response,
          });
        }
      })
      .catch((err) => {
        this.setState({
          warn: err.responseText,
        });
      });
  }

  LogInForm() {
    return (
      <Form className="sidebar" onSubmit={this.handleSubmit}>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={this.state.email}
            onChange={this.update("email")}
          />
        </Form.Group>

        <Form.Group controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            value={this.state.password}
            onChange={this.update("password")}
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          LOG IN
        </Button>
        <Form.Text className="text-muted">{this.state.warn}</Form.Text>
      </Form>
    );
  }

  onClick(e, item) {
    this.props.history.push(item.label);
  }

  render() {
    // console.log('getCurrentLocation: ', this.getCurrentLocation());
    // console.log('side bar this.props: ', this.props);
    if (
      this.props.location.pathname === "/auth/login" ||
      this.props.location.pathname === "/freetry"
    ) {
      return null;
    }
    const { currentUser, AllPath } = this.props;
    let location = AllPath ? AllPath.path : null;
    let ownerId = location ? location[0]["ownerId"] : null;
    let items;
    // console.log('sidebar render currentUser -------: ', currentUser);
    if (currentUser && currentUser.isAuthenticated) {
      let onClickFunc = this.onClick;
      let if_admin = currentUser.role === "Admin" ? true : false;
      // let if_poc = (currentUser.role === 'Admin' | currentUser.role === 'poc') ? true : false
      items = [
        {
          name: "Home",
          label: "/dashboard",
          Icon: MdHome,
          func: onClickFunc,
          permission: true,
        },
        // {name: 'Documents', label: 'Documents', Icon: InsertDriveFileRoundedIcon, permission: true, items: [
        //   {name: 'Invoice', label: 'Invoice', func: onClickFunc, permission: true},
        //   {name: 'Logistics', label: 'Logistics', func: onClickFunc, permission: false}
        // ]},
        {
          name: "My Files",
          label: "/home",
          Icon: FaFolder,
          func: onClickFunc,
          permission: true,
        },
        {
          name: "Shared With Me",
          label: "/shared",
          Icon: MdFolderShared,
          func: onClickFunc,
          permission: true,
        },
        {
          name: "Recent",
          label: "/recent",
          Icon: BsFillClockFill,
          func: onClickFunc,
          permission: true,
        },
        {
          name: "Starred",
          label: "/starred",
          Icon: BsFillStarFill,
          func: onClickFunc,
          permission: true,
        },
        {
          name: "Trash",
          label: "/trash",
          Icon: FaTrashAlt,
          func: onClickFunc,
          permission: true,
        },
        "divider",
        {
          name: "Account",
          label: "Account",
          Icon: MdAccountBox,
          permission: true,
          items: [
            {
              name: "Profile",
              label: "/userprofile",
              Icon: RiProfileFill,
              func: onClickFunc,
              permission: true,
            },
            {
              name: "Notifications",
              label: "/notificationPreferences",
              Icon: MdNotificationsActive,
              func: onClickFunc,
              permission: true,
            },
            {
              name: "Report",
              label: "/report",
              Icon: HiDocumentReport,
              func: onClickFunc,
              permission: true,
            },
          ],
        },
        // {name: 'Upload', label: '/requests2', Icon: PublishRoundedIcon, func: onClickFunc, permission: if_poc},
        {
          name: "Help",
          label: "/tickets",
          Icon: MdHelp,
          func: onClickFunc,
          permission: true,
        },
        "divider",
        {
          name: "Admin",
          label: "/admin",
          Icon: RiAdminFill,
          func: onClickFunc,
          permission: if_admin,
        },
        {
          name: "Send Notification",
          label: "/sendCustomNotification",
          Icon: MdMail,
          func: onClickFunc,
          permission: if_admin,
        },
        {
          name: "Labelling",
          label: "/labelling",
          Icon: MdLabel,
          func: onClickFunc,
          permission: if_admin,
        },
        // {name: 'Admin Control', label: '/admin_control', Icon: FaUsersCog, func: onClickFunc, permission: if_admin},
      ];
    } else {
      items = null;
    }

    return (
      <div>
        <div>
          <div className="SidebarLogo">
            <a href="#/dashboard">
              {/* DocuPal AI */}
              <img
                src={"assets/images/DocuPal_inverse_trans.png"}
                style={{ width: "100%" }}
              />
            </a>
          </div>
          {items ? (
            <Sidebar_module
              current={this.getCurrentLocation()}
              items={items}
              folderLocation={
                ownerId ? (ownerId === currentUser.id ? "home" : "share") : null
              }
            />
          ) : (
            this.LogInForm()
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(Sidebar);
