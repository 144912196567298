import React, { useState } from 'react';  
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import {formatted_date} from '../../helpers/date_format';
import { mimeIconMap} from '../../helpers/IconHelper';
import Popup from '../Popup';
import DetailViewTMPLTM from '../FileBoX/DetailViewTMPLTM';
import {translate} from '../../helpers/translate';
import './style.css';

const Recent = ({ titleList, currentUser, simplepost }) => {
  const [DisplayFile, setDisplayFile] = useState(null);
  const [FileDetail, setFileDetail] = useState(null);

  const OpenFile = async (File) => {
    store.dispatch({type: 'LOADING_STATUS', data: true});
    let FileID = File.FileID;
    let PARENT_FOLDER_ID = File.PARENT_FOLDER_ID;
    console.log('FileID: ',FileID);
    let res = await simplepost({FileID}, '/ftree/singlefile');
    let FolderSetting = await simplepost({PARENT_FOLDER_ID}, '/ftree/getTempConf');
    res['FolderSetting'] = FolderSetting;
    await setFileDetail(res);
    await setDisplayFile(File);
    store.dispatch({type: 'LOADING_STATUS', data: false});
  }

  return (
    <div style={{width: "100%"}}>
      {DisplayFile?
        <Popup show={FileDetail} handleClose={()=>{setFileDetail(null); setDisplayFile(null)}}
              size='big' closeButtonLoc='TopRight'>
          <DetailViewTMPLTM item={DisplayFile} currentUser={currentUser}
          data={FileDetail} simplepost={simplepost}/>
        </Popup>
        : null
      }
      <h5 style={{marginBottom: 0}}>{translate('Recent Files')}</h5>
      <hr style={{marginTop: 0}} />
      <Container style={{marginLeft: '20px'}}>
          {titleList.map(file=>{
            let title = file.title;
            let USER_ID = file.USER_ID;
            let createdAt = file.createdAt;
            return (
              <Row className="RecentFileRow" onClick={()=>{OpenFile(file)}}>
                {/* <Col xs={1} className="CenterIconAlign"><div className="FileIcon CenterIconAlign">{mimeIconMap(file.title)}</div></Col> */}
                {/* <Col xs={9} className="CenterIconAlign" style={{borderBottom: 'solid lightgray 0.5px'}}>
                  <div className="FileIcon CenterIconAlign">{mimeIconMap(file.title)}</div>
                  <div className="FileTitle">{title}</div>
                </Col>
                <Col xs={3} className="CenterIconAlign" style={{borderBottom: 'solid lightgray 0.5px'}}>
                  <div style={{fontWeight: 300, color: 'gray'}}>{formatted_date(createdAt)}</div>
                </Col> */}

                {/* <Col> */}
                  <div className="FileIcon CenterIconAlign">{mimeIconMap(file.title)}</div>
                  <div className="FileTitle CenterIconAlign">{title}</div>
                  <div className="FileDate CenterIconAlign">{translate('Owner')}: {USER_ID===currentUser.id?'You':'Other'}</div>
                  <div className="FileDate CenterIconAlign">{formatted_date(createdAt)}</div>
                {/* </Col> */}
              </Row>
            )
          })}
      </Container>
    </div>
  );
};
export default Recent;