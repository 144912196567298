import { connect } from 'react-redux';

import { readUser, getApiKey, getMathUsageInfo, ReqCountInfo, getGeoInfo } from '../../actions/user_actions';
import {getBatchRequests, Generate_excel_req, SimplePost, SimpleGet2} from '../../actions/request_actions';

import { withRouter } from 'react-router-dom';

import Dashboard from './dashboard';
// import { fromPromise } from 'rxjs/observable/fromPromise';
// import { simplePost } from '../../util/request_util';

const mapStateToProps = (state, ownProps) => {
  
  const loggedIn = Boolean(state.session.currentUser);

  let currentUser = Object.keys(state.entities.user).length > 0 ? state.entities.user : state.session.currentUser;

  // let currentMathUsageInfo = state.entities.math;

  let currentReqCountInfo = state.entities.ReqCount;
  
  let requests = state.entities.requests;

  // let currentGeoInfo = state.entities.GeoInfo;

  // const requests_OCR = state.entities.requests_OCR;

  return {
    userId: currentUser.id,
    userRole: currentUser.role,
    currentUser,
    // currentMathUsageInfo,
    // currentGeoInfo,
    loggedIn,
    currentReqCountInfo,
    requests,
  };
};

const mapDispatchToProps = (dispatch, { location }) => {
  return {
    readUser: userId => dispatch(readUser({id: userId})),
    // getApiKey: userId => dispatch(getApiKey({id: userId})),
    // getMathUsageInfo: userId => dispatch(getMathUsageInfo({id: userId})),
    ReqCountInfo: userId => dispatch(ReqCountInfo({id: userId})),
    // getGeoInfo: userId => dispatch(getGeoInfo({id: userId})),
    // getBatchRequests: (userId, length) => dispatch(getBatchRequests({ id: userId, counts: length})),
    getBatchRequests: (userId) => dispatch(getBatchRequests({ id: userId})),
    download_request: (userId, month) => dispatch(Generate_excel_req({id: userId, month: month})),
    delete_send: (userId, data) => dispatch(SimplePost({id:userId, data:data, request_type:'delete_item'})),
    // simpleget: (data, url) => SimpleGet(data, url)
    simpleget: (data, url) => dispatch(SimpleGet2(data, url)),
    simplepost: (data, url) => dispatch(SimplePost(data, url)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
