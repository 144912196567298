// Action types
import {
    DOWNLOAD_PROGRESS,
} from '../helpers/action_types';
const _initalState = false;

const DownloadProgress = (state = _initalState, action) => {
Object.freeze(state);
let newState;

switch (action.type) {
    case DOWNLOAD_PROGRESS:
        newState = action.data;
        return newState
    default:
        return state
}
}

export default DownloadProgress;