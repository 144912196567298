import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { toPermissionObject, toPermissionBinary, toText } from '../permissionConverter'
import {MdPerson} from '@react-icons/all-files/md/MdPerson';
import {MdPeople} from '@react-icons/all-files/md/MdPeople';

export default function InheritedPermissionEditor({ permission, overridePermission }) {
    return (
        <Container>
            {/* <Row className="UserGroupPermission">
                <Col ms={4} style={{display: 'flex', alignItems:'center', position: 'relative'}}>
                    {accessToRole?
                        <div><MdPeople/>{permission.role} <span style={{color: '#aaa'}}>({toText(permission.permission)})</span></div>:
                        <div><MdPerson/>{permission.user} <span style={{color: '#aaa'}}>({toText(permission.permission)})</span></div>
                    }
                </Col>
                <Col>
                    <div style={{marginLeft: 'auto', float: 'right'}}>
                        <p>{JSON.stringify(permission)}</p>
                    </div>
                </Col>
            </Row> */}
            <Row className="UserGroupPermission">
                <Col ms={4} style={{display: 'flex', alignItems:'center', position: 'relative'}}>
                    {permission.role ?
                        <div><MdPeople/>{permission.role} <span style={{color: '#aaa'}}>({toText(permission.permission)})</span></div>:
                        <div><MdPerson/>{permission.user} <span style={{color: '#aaa'}}>({toText(permission.permission)})</span></div>
                    }
                </Col>
                <Col>
                    <div style={{marginLeft: 'auto', float: 'right'}}>
                        <Button size="sm" variant="outline-primary" onClick={() => overridePermission(permission.user, permission.role)}>Override here</Button>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
