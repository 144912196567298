import { connect } from 'react-redux';

import { updatePassword } from '../../actions/user_actions';
import { logout } from '../../actions/session_actions';

import { withRouter } from 'react-router-dom';

import UpdatePasswordForm from './update_password_form';

const mapStateToProps = (state, ownProps) => {
  
  const loggedIn = Boolean(state.session.currentUser);
                   
  const currentUser = loggedIn ? state.session.currentUser : null;
  const errors = state.errors.session;

  return {
    currentUser,
    loggedIn,
    errors
  };
};

const mapDispatchToProps = (dispatch, { location }) => {
  return {
    updatePassword: data => dispatch(updatePassword(data)),
    logout: () => dispatch(logout())
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UpdatePasswordForm)
);
