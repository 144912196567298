import { connect } from 'react-redux';

import { SimplePost } from '../../actions/request_actions';

import FreeTry from './FreeTry';

import io from 'socket.io-client';

import { SERVER_URL } from '../../helpers/constants';

import shortid from "shortid";


const mapStateToProps = (state, ownProps) => {

  const loggedIn = Boolean(state.session.currentUser)

  let currentUser = loggedIn ? state.session.currentUser : null;

  let language = state.entities.Language;

  let loading = state.entities.Loading;

  let socket;

  let GuestCode = `${shortid.generate()}`;

  if (state.session.socket){
    socket = state.session.socket;
  } else {
    socket = io(SERVER_URL, {
        query: {'GUEST': GuestCode}, transports: ['websocket'], upgrade: false,
        reconnection: true, reconnectionDelay: 1000, reconnectionDelayMax: 5000,
        reconnectionAttempts: Infinity
    })
  }


  return {
    currentUser,
    loggedIn,
    language,
    loading,
    socket,
    GuestCode
  };
};

const mapDispatchToProps = dispatch => ({
  isLoading: data => dispatch({type: 'LOADING_STATUS', data}),
  simplepost: (data, url) => dispatch(SimplePost(data, url)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FreeTry);
