import { postAuth, post, SimpleDelete } from '../services/routes';

export const updatePassword = (data, url='/users/updatepassword') => postAuth(data, url);
export const readUser = (data, url='/users/read') => postAuth(data, url);
export const getApiKey = (data, url='/users/requestAPIKey') => postAuth(data, url);
export const forgotPassword = (data, url='/users/forgot-password') => post(data, url);
export const testSocketIo = (data, url='/users/socketio') => postAuth(data, url);
export const updateUserProfile = (data, url='/users/update') => postAuth(data, url);
export const GetMathKeyUsage = (data, url='/math/readkey') => postAuth(data, url);
export const ReqCountInfo = (data, url='/requests/getReqCountInfo') => postAuth(data, url);
export const FetchInvitations = (data, url='/invites/fetchMyInvitations') => postAuth(data, url);
export const deleteOwnAccount = () => SimpleDelete(null, '/users/deleteOwn');