import React from 'react';  
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const Loading = () => {
    return (
        <div className='popup display-block' style={{zIndex: 100}}>
            <div className='loading_inner'>
                <FadeLoader color={'#26225b'} loading={true} css={override} height={10} />
            </div>
        </div>
    );
};

const IMGLoading = () => {
    return (
        <FadeLoader color={"#26225b"} loading={true} css={override} height={10} />
    );
};


// export default Loading;

module.exports = {
    Loading,
    IMGLoading
}