import React from 'react'
import { ListGroup, Row, Col } from 'react-bootstrap';
import notificationConverter from './notificationConverter'
import './notificationStyles.css';
import {GoPrimitiveDot} from '@react-icons/all-files/go/GoPrimitiveDot';
import {MdDoneAll} from '@react-icons/all-files/md/MdDoneAll';
import {formatted_date} from '../../helpers/date_format';

export default function NotificationItem({ notification, setAsRead }) {
    // console.log('notification: ', notification);
    return (
        <ListGroup.Item variant={notification.read ? 'dark': ''} style={{padding: ".3rem 1.25rem"}}>
            <Row className={notification.read?'read':'unread'}
                onClick={() => notification.read?null:setAsRead(notification.id)}>
                <Col>
                    <div>{ notificationConverter(notification.content) }</div>
                    <p style={{margin: '0px'}}>{formatted_date(notification.date)}</p>
                </Col>
                <Col xs={1} style={{margin: 'auto'}}>
                    { notification.read ?
                        <div><MdDoneAll /></div>
                        : 
                        <div><GoPrimitiveDot /></div>
                    }
                </Col>
            </Row>
        </ListGroup.Item>
    )
}
// export default function NotificationItem({ notification, setAsRead }) {
//     return (
//         <ListGroup.Item variant={notification.read ? 'light': ''} className="notificationSize">
//             <p className="notificationTextInfo">
//                 {notification.date.substring(0, notification.date.length - 5).replace('T', ' ')}
//             </p>
//             <div className="notificationText">
//                 { !notification.read ?
//                     <span className="markAsRead" onClick={() => setAsRead(notification.id)}><DoneIcon /></span>
//                     : ''
//                 }
//                 { notificationConverter(notification.content) }
//             </div>
//         </ListGroup.Item>
//     )
// }