import { SERVER_URL } from '../helpers/constants';

module.exports = {
  post: (data, url) => {
    return $.ajax({
      type: 'POST',
      contentType: 'application/json',
      url: `${SERVER_URL}${url}`,
      dataType: 'json',
      data: (data ? JSON.stringify(data) : null)
    });
  },

  postAuth: (data, url) => {
    return $.ajax({
      type: 'POST',
      contentType: 'application/json',
      url: `${SERVER_URL}${url}`,
      headers: { 'authorization': 'jwt-user ' + localStorage.getItem('token') },
      dataType: 'json',
      data: (data ? JSON.stringify(data) : null)
    });
  },

  // multipartPostAuth: (data, url) => {    
  //   return $.ajax({
  //     // autoUpload: true,
  //     method: 'POST',
  //     type: 'POST',
  //     cache: false,
  //     contentType: false,
  //     processData: false,
  //     url: `${SERVER_URL}${url}`,
  //     headers: { 'authorization': 'jwt-user ' + localStorage.getItem('token') },
  //     data: data,
  //   })
  // },

  multipartPostAuth: (data, url, progressCallback) => {
    return $.ajax({
      xhr: function () {
        var xhr = new window.XMLHttpRequest();
        xhr.upload.addEventListener("progress", function (evt) {
          if (evt.lengthComputable) {
            var percentComplete = evt.loaded / evt.total;
            //Do something with upload progress here
            progressCallback(percentComplete);
          }
        }, false);
        xhr.addEventListener("progress", function (evt) {
          if (evt.lengthComputable) {
            var percentComplete = evt.loaded / evt.total;
            //Do something with download progress
            progressCallback(percentComplete)
          }
        }, false);
        return xhr;
      },
      method: 'POST',
      type: 'POST',
      cache: false,
      contentType: false,
      processData: false,
      url: `${SERVER_URL}${url}`,
      headers: {
        'authorization': 'jwt-user ' + localStorage.getItem('token'),
        'Content-Length': data.size // Set the Content-Length header to the size of the file being uploaded
      },
      data: data,
    })
  },

  SimplePost: (data, url) => {
    return $.ajax({
      url: `${SERVER_URL}${url}`,
      headers: { 'authorization': 'jwt-user ' + localStorage.getItem('token') },
      type: 'POST',
      data: (data ? data : null)
    })
  },

  SimpleDelete: (data, url) => {
    return $.ajax({
      url: `${SERVER_URL}${url}`,
      headers: { 'authorization': 'jwt-user ' + localStorage.getItem('token') },
      type: 'DELETE',
      data: (data ? data : null)
    })
  },

  JSONPost: (data, url) => {
    return $.ajax({
      url: `${SERVER_URL}${url}`,
      headers: { 'authorization': 'jwt-user ' + localStorage.getItem('token') },
      type: 'POST',
      contentType: 'application/json',
      data: (data ? JSON.stringify(data) : null)
    })
  },

  JSONPostWithShareToken: (token, data, url) => {
    return $.ajax({
      url: `${SERVER_URL}${url}`,
      headers: { token },
      type: 'POST',
      contentType: 'application/json',
      data: (data ? JSON.stringify(data) : null)
    })
  },

  JSONPut: (data, url) => {
    return $.ajax({
      url: `${SERVER_URL}${url}`,
      headers: { 'authorization': 'jwt-user ' + localStorage.getItem('token') },
      type: 'PUT',
      contentType: 'application/json',
      data: (data ? JSON.stringify(data) : null)
    })
  },

  JSONPatch: (data, url) => {
    return $.ajax({
      url: `${SERVER_URL}${url}`,
      headers: { 'authorization': 'jwt-user ' + localStorage.getItem('token') },
      type: 'PATCH',
      contentType: 'application/json',
      data: (data ? JSON.stringify(data) : null)
    })
  },

  JSONDelete: (data, url) => {
    return $.ajax({
      url: `${SERVER_URL}${url}`,
      headers: { 'authorization': 'jwt-user ' + localStorage.getItem('token') },
      type: 'DELETE',
      contentType: 'application/json',
      data: (data ? JSON.stringify(data) : null)
    })
  },

  UpdateUserPut: (data, url) => {
    return $.ajax({
      url: `${SERVER_URL}${url}`,
      headers: { 'authorization': 'jwt-user ' + localStorage.getItem('token') },
      type: 'PUT',
      ContentType: 'application/json',
      dataType: 'json',
      data: (data ? data : null)
    })
  },

  // SimpleGet: (data, url) => {
  //   return $.ajax({
  //     url: `${SERVER_URL}${url}`,
  //     headers: { 'authorization': 'jwt-user ' + localStorage.getItem('token') },
  //     type: "get",
  //     contentType: 'application/json',
  //     dataType: 'json',
  //     data: (data ? data : null)
  //   })
  // },
  SimpleGet: (data, url) => {
    // return $.get(`${SERVER_URL}${url}`, data)
    return $.ajax({
      url: `${SERVER_URL}${url}`,
      data: data,
      type: "GET",
      headers: { 'authorization': 'jwt-user ' + localStorage.getItem('token') },
    })
  },

  Generate_req: (data, url) => {
    return $.ajax({
      url: `${SERVER_URL}${url}`,
      headers: { 'authorization': 'jwt-user ' + localStorage.getItem('token') },
      type: 'POST',
      ContentType: 'application/json',
      dataType: 'json',
      data: (data ? data : null)
    })
  }
}
