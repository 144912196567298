import React from "react";

import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import {MdExpandLess} from '@react-icons/all-files/md/MdExpandLess';
import {MdExpandMore} from '@react-icons/all-files/md/MdExpandMore';

import './sideBar.css';

import {translate} from '../../helpers/translate';

function SidebarItem({ current, folderLocation, depthStep = 10, depth = 0, expanded, item, idx }) {
  const [collapsed, setCollapsed] = React.useState(true);
  const { name, label, items, Icon, func, permission } = item;
  // console.log('current vs label: ', current, label);
  // console.log('folderLocation: ', folderLocation);
  // console.log('langggg: ', window.store.getState().entities.Language);
  function toggleCollapse() {
    setCollapsed(prevValue => !prevValue);
  }

  function onClick(e) {
    if (Array.isArray(items)) {
      toggleCollapse();
    }
    if (item.func) {
      item.func(e, item);
    }
  }

  let expandIcon;

  if (Array.isArray(items) && items.length) {
    expandIcon = !collapsed ? (
      <MdExpandLess className={"sidebar-item-expand-arrow" + " sidebar-item-expand-arrow-expanded"} />
    ) : (
      <MdExpandMore className="sidebar-item-expand-arrow" />
    );
  };
  return (
    <ListGroup.Item className="SidebarListItem">
      {item.permission?
      <Accordion>
        <Card className="ListItemCard">
          <Accordion.Toggle as={Card.Header} eventKey={String(idx)} onClick={onClick}
                            // className={'/'+String(current)===label? 'CardHeaderOn': 'CardHeaderOff'}>
                            className={handleFolderLocation(current, label, folderLocation)}>
            <div style={{ paddingLeft: depth * depthStep }} className="sidebar-item-content">
              {Icon && <Icon className="sidebar-item-icon" />}
              {translate(`${name}`)}
              {expandIcon} 
            </div>
          </Accordion.Toggle>
          {Array.isArray(items)
            ?
            <Accordion.Collapse eventKey={String(idx)}>
              <Card.Body style={{padding: '0px'}}>
                {Array.isArray(items)
                  ?
                  <ListGroup>
                    {items.map((subItem, index) => (
                      subItem === "divider"
                        ? 
                        <hr style={{margin: "6px 0"}} /> 
                        :
                        <SidebarItem current={current} depth={depth + 1} depthStep={depthStep} item={subItem}/>
                    ))}
                  </ListGroup>
                  :
                  null}
              </Card.Body>
            </Accordion.Collapse>
            :null}
        </Card>
      </Accordion>
      :null}
    </ListGroup.Item>
  )
}

function Sidebar_module({ current, items, folderLocation, depthStep, depth, expanded }) {
  return (
    <div className="SideBar_Inner">
      <ListGroup>
        {items.map((sidebarItem, index) => (
          
          sidebarItem === "divider"? 
            <hr style={{margin: "6px 0", borderColor: 'snow'}} />
            :
            <SidebarItem current={current} folderLocation={folderLocation} depthStep={depthStep} depth={depth} expanded={expanded} item={sidebarItem} idx={index}/>
        ))}
      </ListGroup>
    </div>
  );
}

function handleFolderLocation(current, label, folderLocation){
  // console.log('current, label, folderLocation: ', current, label, folderLocation);
  if ('/'+String(current)===label){
    return 'CardHeaderOn'
  } else if (current === 'folder') {
    if (label === '/shared' && folderLocation==='share'){
      return 'CardHeaderOn'
    } else if (label === '/home' && folderLocation==='home') {
      return 'CardHeaderOn'
    } else {
      return 'CardHeaderOff'
    }
  } else {
    return 'CardHeaderOff'
  }
}

export default Sidebar_module;