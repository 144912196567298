import React from 'react'

export default function FolderInfoModal({ folderInfo }) {
    return (
        <div>
            <div className='InfoTitle'>
                <span>{folderInfo.title} Info</span>
            </div>
            <div>
                <div className="InfoList">
                    <span>You can check out the API's documentation here: <b><a target="_blank" href="https://api.docupal.ai/api-docs">https://api.docupal.ai/api-docs</a></b>. For this folder, use the following parameters when calling the API:</span></div>
                <div className="InfoList"><div>folderId:</div><span>{folderInfo.FolderID}</span></div>
            </div>
        </div>
    )
}
