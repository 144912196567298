import React from 'react';
import Button from 'react-bootstrap/Button';
import {Loading} from '../loading'

class AcceptInvitation extends React.Component {
  constructor(props) {
    super(props);
    
    this.confirmAcceptInvitation = this.confirmAcceptInvitation.bind(this);
  }

  componentWillMount() {
    if (!this.props.invited_to_company && !this.props.invited_by_name) {
      this.props.fetchInviteData(this.props.invite_id, this.props.user_id);
    }
  }

  confirmAcceptInvitation() {
    this.props.acceptInvite(this.props.invite_id, this.props.user_id).then(() => this.props.history.push(`/verify-email?token=${this.props.user_token}&email=${this.props.user_email}`))
  }

  render() {
    if (!this.props.invited_to_company && !this.props.invited_by_name) {
      return Loading();
    }

    return (
      <div className="join-company-wrapper">
        <h4>You have been invited by {this.props.invited_by_name} to join {this.props.invited_to_company}</h4>
        <Button variant="primary" className="btn btn-primary" onClick={this.confirmAcceptInvitation}>Click to accept</Button>
      </div>
    );
  }
}

export default AcceptInvitation;