import { connect } from 'react-redux';

import { setNotificationAsRead } from '../../actions/notification_actions';

import NotificationToastList from './NotificationToastList';

const mapStateToProps = (state, ownProps) => {

  const loggedIn = Boolean(state.session.currentUser)

  let currentUser = loggedIn ? state.session.currentUser : null;

  let language = state.entities.Language;

  let loading = state.entities.Loading;

  let notifications = state.notifications.notifications;

  let latestFetchID = state.notifications.latestFetchID;

  return {
    currentUser,
    loggedIn,
    language,
    loading,
    notifications,
    latestFetchID
  };
};

const mapDispatchToProps = dispatch => ({
  setNotificationAsRead: (id) => dispatch(setNotificationAsRead(id)),
  isLoading: data => dispatch({type: 'LOADING_STATUS', data}),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationToastList);
