import React, { useEffect } from 'react';  
import './style.css';
import Button from 'react-bootstrap/Button';
import {MdClose} from '@react-icons/all-files/md/MdClose';


const Popup = ({ handleClose, closeButtonLoc, show, children, handleSubmit, submit_name, size }) => {
    const showHideClassName = show ? "popup display-block" : "popup display-none";
    // useEffect(()=>{
    //   // disable body scroll when pop up is open
    //   document.body.style.overflow = "hidden";
    //   return () =>{
    //     // body scroll back to normal when pop up is closed
    //     document.body.style.overflow = "unset";
    //   }
    // })
    let SizeStyle = null
    if (size === 'big'){
      SizeStyle = {left:'2%', right:'2%', top:'2%', bottom:'2%'}
    } else if (size === 'small') {
      SizeStyle = {left:'20%', right:'20%', top:'20%', bottom:'20%', minHeight: '600px'}
    } else if (size === 'xsmall') {
      SizeStyle = {left:'30%', right:'30%', top:'20%', bottom:'20%', minHeight: '600px'}
    } else if (size === 'tiny') {
      SizeStyle = {left:'30%', right:'30%', top:'40%', bottom:'40%'}
    } else if (size === 'SingleInput') {
      SizeStyle = {left:'30%', right: '30%', height: 'fit-content', backgroundColor: '#ffffff00'}
    }
    // Default: {left:'2%', right:'2%', top:'2%', bottom:'2%'}
    return (
      <div className={showHideClassName}>
        <section className="popup_inner" 
          style={SizeStyle}>
          {children}
          <div className={closeButtonLoc==='TopRight'?"TopRightButton":"CenterButton"}>
            {handleSubmit? 
              <Button variant="primary" onClick={handleSubmit}>
                {submit_name? submit_name:'Submit'}
              </Button>
              :
              null
            }
            {
              size === 'SingleInput'? null:
                closeButtonLoc==='TopRight'?
                <button className='closeButton' onClick={handleClose}><MdClose /></button>:
                <Button variant="outline-primary" onClick={handleClose}>Cancel</Button>
            }
          </div>
        </section>
      </div>
    );
};
export default Popup;