import React from 'react';
import { notificationTypes } from '../../helpers/constants';
import constants from '../Tickets/constants';

// This function is responsible for converting a notification content into jsx text
module.exports = notificationContent => {
    switch (notificationContent.templateID) {
        case notificationTypes.FOLDER_SHARED.code:
            return (
                <span>
                    {notificationContent.customProperties.ownerName} gave you access to <a href={`#/folder/${notificationContent.customProperties.folderID}`}><b>{notificationContent.customProperties.folderName}</b></a>.
                </span>
            )
        case notificationTypes.FILE_SHARED.code:
            return (
                <span>{notificationContent.customProperties.ownerName} gave you access to <a href="#/shared"><b>{notificationContent.customProperties.fileName}</b></a>.</span>
            )
        case notificationTypes.INVITE_ACCEPTED.code:
            return <span>The user <b>{notificationContent.customProperties.userEmail}</b> accepted your invite and is now part of the company.</span>
        case notificationTypes.CUSTOM_NOTIFICATION.code:
            return (
                <div>
                    { notificationContent.customProperties.title !== '' ?
                        <span class='bold'>{notificationContent.customProperties.title}</span>
                        : '' }
                    <div><span>{notificationContent.customProperties.message}</span></div>
                </div>
            )

        case notificationTypes.TICKET_REPLY.code:
            return <span>An admin has replied to your ticket <a href={`#/tickets/${notificationContent.customProperties.ticketId}`}><b>{notificationContent.customProperties.ticketName}</b></a>.</span>
        case notificationTypes.TICKET_STATUS_CHANGE.code:
            return <span>Your ticket <a href={`#/tickets/${notificationContent.customProperties.ticketId}`}><b>{notificationContent.customProperties.ticketName}</b></a> has been set to {constants.TicketStatus[notificationContent.customProperties.ticketStatus]} by an Admin.</span>
        case notificationTypes.PERCENTAGE_OF_QUOTA_USED.code:
            return <span>You've used <b>{notificationContent.customProperties.percentage}%</b> of your quota!</span>
        default:
            return <span>Invalid notification</span>;
    }
}