import { connect } from 'react-redux';

import { readUser } from '../../actions/user_actions';
import { showCustomError, setShareToken } from '../../actions/session_actions';
import { getBatchRequests, GetSearchResults, shareTokenRequest, shareTokenPost } from '../../actions/request_actions';

import { withRouter } from 'react-router-dom';

import FileBox from './filebox';
import { data } from 'jquery';


const mapStateToProps = (state, ownProps) => {
  const shareToken = state.session.shareToken;

  let currentUser = state.session.currentUser;
  
  let error = state.errors.session;

  let socket = state.session.socket;

  let AllPath = state.entities.AllPath;

  let fbtype = 'regular';
  let noSearchResults = null;

  console.log("ownProps:", ownProps)

  if (ownProps.location.pathname.split('/')[1] == 'search') {
    let searchResults = state.entities.Search.results;
    AllPath = searchResults || {Folders: [], Files: []};
    fbtype = "search";
    noSearchResults = (searchResults !== null && searchResults.Folders.length === 0 && searchResults.Files.length === 0);
  }

  return {
    socket,
    userId: currentUser ? currentUser.id : null,
    currentUser,
    shareToken,
    error,
    AllPath,
    fbtype,
    noSearchResults,
  };
};

const mapDispatchToProps = (isFile) => (dispatch, { location }) => {
  // If it is a folder, then the endpoint is /ftree/token/fetchContents
  let getFolderContents = (currentFolderId, page, token) => dispatch(shareTokenRequest(token, { currentFolderId, page }, '/ftree/token/fetchContents'));
  // Otherwise, use the files endpoint
  if (isFile) {
    getFolderContents = (fileId, token) => dispatch(shareTokenRequest(token, { fileId }, '/ftree/token/fetchContentsFile'));
  }
  return {
    setShareToken: token => dispatch(setShareToken(token)),
    getFolderContents,
    showCustomError: (str) => dispatch(showCustomError(str)),
    simplepost: (data, url, token) => dispatch(shareTokenPost(token, data, url)),
    ReceiveError: msg => {dispatch({type:'RECEIVE_FETCH_ERROR', errors: msg}); 
                          setTimeout(()=>{dispatch({type:'RECEIVE_FETCH_ERROR', errors: ''})}, 3000)},
    ModifyPath: data => dispatch({type:'MODIFY_PATH', requests: data}),
    doSearch: (query) => dispatch(GetSearchResults(query, '/ftree/simpleSearch')),
    getTotalNumberOfResources: (currentFolderId, token) => dispatch(shareTokenPost(token, { currentFolderId }, '/ftree/token/countContents')),
  };
};

/**
 * isFile determines if this Filebox is used for a shared folder or a shared file.
 * This is important since the endpoint for fetching the contents is different for the two resources.
 */
export default (isFile) => withRouter(
  connect(mapStateToProps, mapDispatchToProps(isFile))(FileBox)
);