// API Util
import * as Util from '../util/request_util';

// Action types
import {
  RECEIVE_ALL_REQUESTS,
  RECEIVE_A_REQUEST,
  RECEIVE_BATCH_REQUEST,
  CLEAR_ALL_REQUESTS,
  RECEIVE_SESSION_ERRORS,
  RECEIVE_ALL_USERS,
  RECEIVE_ADMIN_DATA,
  RECEIVE_ADMIN_COMPANY_DETAILS,
  RECEIVE_SEARCH_DATA,
  RECEIVE_RATE_LIMIT,
} from '../helpers/action_types';

/*** ************************************
 *** Action POJO with type and payload ***
 *** *************************************
 */
const receiveAllRequests = requests => ({
  type: RECEIVE_ALL_REQUESTS,
  requests: requests.requests
});

const receiveRequest = request => ({
  type: RECEIVE_A_REQUEST,
  request
});

const clearAllRequests = () => ({
  type: CLEAR_ALL_REQUESTS
});

const receiveErrors = errors => ({
  type: RECEIVE_SESSION_ERRORS,
  errors
});

const receiveBatchRequests = requests => ({
  type: RECEIVE_BATCH_REQUEST,
  requests: requests
});

const receiveAdminAllUsers = requests => ({
  type: RECEIVE_ALL_USERS,
  all_users: requests.result
})

const receiveAdminData = requests => ({
  type: RECEIVE_ADMIN_DATA,
  data: requests.response
})

const receiveAdminCompanyData = requests => ({
  type: RECEIVE_ADMIN_COMPANY_DETAILS,
  data: requests.response
})

const receiveSearchResults = requests => ({
  type: RECEIVE_SEARCH_DATA,
  data: requests
})

const receiveRateLimit = requests => ({
  type: RECEIVE_RATE_LIMIT,
  rateLimit: requests
})

/*** ************************************
 *** Apply Redux thunk middle   *********
 *** Including calling API, and *********
 ********* POJO to reducers *************
 *** *************************************
 */

export const getAllRequests = data => dispatch => {
  return Util.readAllRequests(data)
    .then(requests => dispatch(receiveAllRequests(requests)))
    .fail(errors => dispatch(receiveErrors(errors.responseText)));
};

export const fetchRequest = data => dispatch => {
  return dispatch(receiveRequest(data));
};

export const clearRequests = () => dispatch => {
  return dispatch(clearAllRequests());
};

export const getBatchRequests = (data, url) => dispatch => {
  return Util.readBatchRequests(data, url)
  .then(requests => dispatch(receiveBatchRequests(requests)))
  .fail(errors => dispatch(receiveErrors(errors.responseText)));
};

export const shareTokenRequest = (token, data, url) => dispatch => {
  return Util.shareTokenPost(token, data, url)
  .then(requests => dispatch(receiveBatchRequests(requests)))
  .fail(errors => dispatch(receiveErrors(errors.responseText)));
};

export const shareTokenPost = (token, data, url) => dispatch => {
  return Util.shareTokenPost(token, data, url)
  .fail(errors => dispatch(receiveErrors(errors.responseText)))
};

export const postRequest = data => dispatch => {
  return Util.PostRequest(data)
};

export const SimplePost = (data, url) => dispatch => {
  return Util.simplePost(data, url)
  .fail(errors => dispatch(receiveErrors(errors.responseText)))
};

export const jsonPost = (data, url) => dispatch => {
  return Util.jsonPost(data, url)
  .fail(errors => dispatch(receiveErrors(errors.responseText)))
};

export const SimpleGet = data => dispatch => {
  return Util.simpleGet(data)
};

export const Generate_excel_req = data => dispatch => {
  return Util.Generate_excel_req(data)
};

export const admin_post = (data, url) => dispatch => {
  return Util.simplePost(data, url)
  .fail(errors => dispatch(receiveErrors(errors.responseText)))
}

export const update_user = data => dispatch => {
  return Util.update_user(data).then(requests => dispatch(receiveAdminAllUsers(requests)))
}

export const uploadS3 = data => dispatch => {
  return Util.PostRequestS3(data)
}

export const Guest_POST = data => dispatch =>{
  return Util.guest_post(data)
}

export const PostRequest_Guest = data => dispatch =>{
  return Util.PostRequestGuest(data)
}

export const SimpleGet2 = (data, url) => dispatch => {
  return Util.simpleGet(data, url)
  .fail(errors => dispatch(receiveErrors(errors.responseText)));
}

export const getAdminData = (url) => dispatch => {
  return Util.simpleGet(null, url)
    .then(data => dispatch(receiveAdminData(data)))
    // .fail(errors => dispatch(receiveErrors(errors.responseText)));
};

export const getAdminCompanyDetails = (url, uid) => dispatch => {
  return Util.simpleGet({uid: uid}, url)
    .then(data => dispatch(receiveAdminCompanyData(data)))
    // .fail(errors => dispatch(receiveErrors(errors.responseText)));
};

export const GetSearchResults = (query, url) => dispatch => {
  console.log("Doing SEARCH!")
  return Util.simplePost({query: query}, url)
    .then(data => {dispatch(receiveSearchResults(data)); console.log("received:", data)})
    // .fail(errors => dispatch(receiveErrors(errors.responseText)));
};

export const getRateLimit = () => dispatch => {
  return Util.simpleGet(null, "/rateLimit/rateLimit")
    .then(data => dispatch(receiveRateLimit(data)))
    .fail(errors => dispatch(receiveErrors(errors.responseText)));
};