import {
  RECEIVE_RATE_LIMIT,
  ADD_PROCESSED_PAGES
} from '../helpers/action_types';

const _initialState = {};

const rateLimitReducer = (state = _initialState, action) => {
  Object.freeze(state);
  
  switch (action.type) {
    case RECEIVE_RATE_LIMIT:
      return Object.assign({}, _initialState, action.rateLimit);
    
    case ADD_PROCESSED_PAGES:
      const currentProcessedPages = state.processedPages;
      const newProcessedPages = currentProcessedPages + action.newlyProcessedPages;
      return Object.assign({}, state, { processedPages: newProcessedPages });
    
    default:
      return state;
  }
}

export default rateLimitReducer;