import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';

import EmailSent from './email_sent';

const mapStateToProps = (state, ownProps) => {
  
  const loggedIn = Boolean(state.session.currentUser);
  const email = localStorage.getItem('email-sent');
  
  return {
    loggedIn,
    email
  };
};

export default withRouter(
  connect(mapStateToProps, null)(EmailSent)
);
