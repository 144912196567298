import { connect } from 'react-redux';

import { verifyThirdPartyLogin } from '../../actions/session_actions';

import { withRouter } from 'react-router-dom';

import VerifyThirdPartyLogin from './verify_third_patry_login';

const mapStateToProps = (state, ownProps) => {
  
  const loggedIn = Boolean(state.session.currentUser);
  const errors = state.errors.session;

  return {
    loggedIn,
    errors
  };
};

const mapDispatchToProps = (dispatch, { location }) => {
  return {
    verifyThirdPartyLogin: link => dispatch(verifyThirdPartyLogin(link)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VerifyThirdPartyLogin)
);
