import React from 'react';

import { withRouter } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import {LanguagesSelect, LanguagesSelectDeepOCR} from '../LanguagesDropDown';
import ReactDropzone from "react-dropzone";
import {FaFileImage} from '@react-icons/all-files/fa/FaFileImage';
import * as Util from "../../util/request_util";
import mime from 'mime';
import {translate} from '../../helpers/translate';

class CreateView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: translate(`New Folder`),
            // StorageOnly: false,
            lang: store.getState().entities.Language||'eng',
            type:'first',
            template_file_preview_url: '',
            template_filename: '',
            template_file_url: '',
            template_file: null,
            upload_progress: 0
        }
        this.HandleCreate = this.HandleCreate.bind(this);
        this.uploadFilePreview = this.uploadFilePreview.bind(this);
        this.HandleUpload = this.HandleUpload.bind(this);
    }

    componentWillMount() {
    }

    uploadFilePreview(files) {
        let file = files[0];
        let reader = new FileReader();
        reader.onloadend = (f) => {
            let template_file_preview_url = reader.result;
            let template_filename = file.name;
            let template_file = file;
            this.setState({
                template_file_preview_url, template_filename, template_file
            })
        }
        reader.readAsDataURL(file);
    }

    HandleUpload(){
        let url = '/ftree/templateupload'
        let formData = new FormData();
        formData.append('file', this.state.template_file);
        formData.append('parentFolder', this.props.CurrentFolder?this.props.CurrentFolder.FolderID:'');
        Util.PostRequest(formData, url).then((res) => {
            console.log('upload res: ', res);
            this.setState({upload_progress: 100, template_file_url: res})
        }).catch((err)=>{
            window.alert('An error occured when upload documents, please contact manager.')
        })
    }

    displayUploadTemplate(){
        return(
            <Row className="fullHeight">
                <Col style={{height: '100%'}}>
                    { this.state.template_file_preview_url.length > 0
                    ?
                        <div style={{display: 'flex'}}>
                            <div className='FileUpFilenameDown cell-icon'>
                                <div>
                                    <FaFileImage/><p>{this.state.template_filename}</p>
                                </div>
                            </div>
                            <Button style={this.state.upload_progress === 100 ? {borderRadius: '0px 8px 8px 0px', pointerEvents: "none"}: {borderRadius: '0px 8px 8px 0px'}} onClick={this.HandleUpload}>
                                {this.state.upload_progress === 100?'✔️':'Upload'}
                            </Button>
                        </div>
                    :
                        <ReactDropzone type="file" name='pdf' className="dropzone fullHeight"
                            onDrop={(accepted, rejected) => { 
                                if (accepted.length > 0 ){
                                    this.uploadFilePreview(accepted)
                                } else {
                                    window.alert("Please Upload a single file (Support format: PNG, JPEG, and SVG")
                                }
                            }}
                            accept={this.props.accept? this.props.accept : "image/*, .pdf"}
                            >
                            <div className="logUploadicon">
                                <p className="uploadicon_text">{"Drag and drop files\n- or -\nBrowse"}</p>
                            </div>
                        </ReactDropzone>
                    }
                </Col>
                <Col style={{height: '100%'}}>
                    {this.state.template_file_url.length > 0 ?
                    <div className="fullHeight">
                        {mime.getType(this.state.template_file_url).split('/')[0] === 'image'
                        ?
                        <img src={this.state.template_file_url} className='template_img'/>
                        :
                        <div className='template_img'>preview unavailable</div>
                        }
                    </div>
                    :null}
                </Col>
            </Row>
        )
    }

    HandleCreate(){
        let loadingtic = mime.getType(this.state.template_file_url)? 2000:200;
        let currentfolder = this.props.CurrentFolder;
        store.dispatch({type: 'LOADING_STATUS', data: true});
        this.props.simplepost({'name': this.state.name, 
                            //    'storageonly': this.state.StorageOnly, 
                               'currFolder': currentfolder?currentfolder.FolderID:null, 
                               'type': this.order2type(this.state.type), 'lang': this.state.lang,
                               'template_file_url': this.state.template_file_url}, '/ftree/createpath').then(res => {
            setTimeout(() => {
                this.props.closePopup();
                store.dispatch({type: 'LOADING_STATUS', data: false});
            }, loadingtic);
        })
    }

    order2type(order){
        let folderCode;
        switch (order) {
            case "first":
                folderCode = "GPOCR0001";
                break;
            case "second":
                folderCode = "TMPLTM01";
                break;
            case "third":
                folderCode = "GS0000001";
                break;
        }
        console.log('folderCode: ', folderCode);
        return folderCode
    }

    render() {        
        return (
            <Container fluid className='detailDocContainer'>
                <Form.Group className='FormContainer'>
                    <Form.Label>{translate('Folder Name')}</Form.Label>
                    <Form.Control autoFocus type="text" placeholder="New Folder" onChange={(e)=>this.setState({name:e.target.value})}/>
                    {/* <input
                        type="checkbox"
                        onClick={()=>this.setState({StorageOnly: !this.state.StorageOnly})}
                        checked={this.state.StorageOnly} /> */}
                    {/* <label className='CheckContainer'>
                        <input type="checkbox" 
                            onChange={() => this.setState({StorageOnly: !this.state.StorageOnly})} 
                            checked={this.state.StorageOnly} />
                        <span className="checkmark"></span>
                    </label>
                    <Form.Label>
                        {translate('Is this for storage only')}?
                    </Form.Label> */}
                </Form.Group>
                {/* <div className='FormContainer' style={this.state.StorageOnly?{opacity: 0.4, pointerEvents: "none"}: {opacity: 1, pointerEvents: "all"}}> */}
                <div className='FormContainer'>
                    <Form.Label>{translate('Language')} You can change later.</Form.Label>
                    <LanguagesSelect style={{padding: '8px 0', margin: '0px'}} current_lang={this.state.lang} selectOutput={(e)=>{this.setState({lang: e.target.value})}} />
                    {/* {this.state.type==='second'?
                        <LanguagesSelectDeepOCR selectOutput={(e)=>{this.setState({lang: e.target.value})}} />
                        :
                        <LanguagesSelect style={{padding: '8px 0', margin: '0px'}} current_lang={this.state.lang} selectOutput={(e)=>{this.setState({lang: e.target.value})}} />
                    } */}
                </div>
                {/* <div style={this.state.StorageOnly?{opacity: 0.4, pointerEvents: "none", height: '40%'}: {opacity: 1, pointerEvents: "all", height: '40%'}}> */}
                <div style={{opacity: 1, pointerEvents: "all", height: '40%'}}>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first" style={{height: '100%'}}>
                        <Row style={{height: '100%'}}>
                            <Col sm={3} style={{height: '100%'}}>
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item className={this.state.type==='first'?'Tablist_title_active':'Tablist_title'}>
                                        <Nav.Link eventKey="first" onClick={()=>this.setState({type: 'first'})}>{this.state.type==='first'? '✓ ': ''}{translate('Full text OCR')}</Nav.Link>
                                    </Nav.Item>
                                    {/* <Nav.Item className={this.state.type==='second'?'Tablist_title_active':'Tablist_title'}>
                                        <Nav.Link eventKey="second" onClick={()=>this.setState({type: 'second'})}>{this.state.type==='second'? '✓ ': ''}{translate('DeepOCR (Beta)')}</Nav.Link>
                                    </Nav.Item> */}
                                    <Nav.Item className={this.state.type==='second'?'Tablist_title_active':'Tablist_title'}>
                                        <Nav.Link eventKey="second" onClick={()=>this.setState({type: 'second'})}>{this.state.type==='second'? '✓ ': ''}{translate('Fixed format')}</Nav.Link>
                                    </Nav.Item>
                                    {/* <Nav.Item className={this.state.type==='third'?'Tablist_title_active':'Tablist_title'}>
                                        <Nav.Link eventKey="third" onClick={()=>this.setState({type: 'third'})}>{this.state.type==='third'? '✓ ': ''}{translate('Fixed format')}</Nav.Link>
                                    </Nav.Item> */}
                                    <Nav.Item className={this.state.type==='third'?'Tablist_title_active':'Tablist_title'}>
                                        <Nav.Link eventKey="third" onClick={()=>this.setState({type: 'third'})}>{this.state.type==='third'? '✓ ': ''}{translate('Storage only')}</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col sm={9} style={{height: '100%'}}>
                                <Tab.Content style={{height: '100%'}}>
                                    <Tab.Pane className='Tablist_content' eventKey="first" style={{height: '100%'}}>
                                        Choose this to run all text extraction OCR (optical character recognition) to all documents upload to this folder.
                                    </Tab.Pane>
                                    {/* <Tab.Pane className='Tablist_content' eventKey="second" style={{height: '100%'}}>
                                        {"Perform whole page OCR(optical character recognition).\nExtract all possible key-value pairs, tables, handwrittens, signitures, tables, etc."}
                                    </Tab.Pane> */}
                                    <Tab.Pane className='Tablist_content' eventKey="second" style={{height: '100%'}}>
                                        Choose this to run text extraction based on a configurable template to all documents upload to this folder.
                                        {this.state.type === 'second'? this.displayUploadTemplate() : null}
                                    </Tab.Pane>
                                    {/* <Tab.Pane className='Tablist_content' eventKey="third" style={{height: '100%'}}>
                                        Choose this to run text extraction based on a configurable template to all documents upload to this folder.
                                        {this.state.type === 'third'? this.displayUploadTemplate() : null}
                                    </Tab.Pane> */}
                                    <Tab.Pane className='Tablist_content' eventKey="third" style={{height: '100%'}}>
                                        Choose this to use the folder as a storage, all documents upload to this folder will be stored and able to view.
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
                <div className='RightBottomButton'>
                    <Button onClick={()=>this.HandleCreate()}>
                        {translate('Done')}
                    </Button>
                </div>
            </Container>
        )
    }
}

export default withRouter(CreateView);