import React, { useState, useEffect } from "react";

import { Route, Redirect } from "react-router-dom";

import { Container, Row, Col, Button, Form } from "react-bootstrap";

import QuickOCRview from "../dashboard/QuickOCRview";

import { RiFileExcel2Fill } from "@react-icons/all-files/ri/RiFileExcel2Fill";
import { RiFileWord2Fill } from "@react-icons/all-files/ri/RiFileWord2Fill";
import { MdCloudDone } from "@react-icons/all-files/md/MdCloudDone";
import { GoGear } from "@react-icons/all-files/go/GoGear";

import "./FreeTry.css";

export default function PermissionManager(props) {
  const [Warning, setWarning] = useState(null);

  const [FormWarning, setFormWarning] = useState(null);
  const [SendEmailSuccess, setSendEmailSuccess] = useState(false);

  const [Error, setError] = useState(null);

  const [RawText, setRawText] = useState(null);
  const [Tables, setTables] = useState([]);
  const [Ky_pairs, setKy_pairs] = useState([]);
  const [ImageURL, setImageURL] = useState(null);
  const [PageID, setPageID] = useState(null);

  // const [RawText, setRawText] = useState("1213213213213  sda iaidaisd iajd kaj l");
  // const [Tables, setTables] = useState([1,2]);
  // const [Ky_pairs, setKy_pairs] = useState([1,2]);
  // const [ImageURL, setImageURL] = useState('https://data2.unhcr.org/images/documents/big_4cda85d892a5c0b5dd63b510a9c83e9c9d06e739.jpg');
  // const [PageID, setPageID] = useState("7b2861a7-855e-49a8-b18d-ab4efd4762b0PAGE0");

  const [Status, setStatus] = useState(null);
  // const [Status, setStatus] = useState('processing');

  const [Email, setEmail] = useState("");
  const [Name, setName] = useState("");

  useEffect(() => {
    props.socket.on(`FreeTryRes`, (msg) => {
      console.log("receive socket result back", msg);
      if (typeof msg === "object") {
        setRawText(msg.raw_text || null);
        setTables(msg.tables || []);
        setKy_pairs(msg.ky_pairs || []);
        setImageURL(msg.IMG_URL || null);
        setPageID(msg.PageID || null);
        setStatus("finished");
      } else if (msg === "Init") {
        setStatus("processing");
      }
    });
  }, []);

  const display_Table_Ky_pairs_info = () => {
    if (Tables.length > 0 && Ky_pairs.length > 0) {
      return (
        <div>
          {`We also found `}{" "}
          <span className="bold">{Tables.length} tables</span> {` and `}{" "}
          <span className="bold">{Ky_pairs.length} key values</span>{" "}
          {` in your document `}
          can be export to <RiFileExcel2Fill id="excel" /> and{" "}
          <RiFileWord2Fill id="word" />.
        </div>
      );
    } else if (Tables.length > 0 && Ky_pairs.length === 0) {
      return (
        <div>
          {`We also found `}{" "}
          <span className="bold">{Tables.length} tables</span>{" "}
          {` in your document `}
          can be export to <RiFileExcel2Fill id="excel" /> and{" "}
          <RiFileWord2Fill id="word" />.
        </div>
      );
    } else if (Tables.length === 0 && Ky_pairs.length > 0) {
      return (
        <div>
          {`We also found `}{" "}
          <span className="bold">{Tables.length} key values</span>{" "}
          {` in your document `}
          can be export to <RiFileExcel2Fill id="excel" /> and{" "}
          <RiFileWord2Fill id="word" />.
        </div>
      );
    } else {
      return null;
    }
  };

  const display_raw_txt = () => {
    return (
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
          display: "flex",
          flexFlow: "column",
          paddingTop: "10px",
        }}
      >
        <div className="FreeTryResultImageContainer">
          <img src={ImageURL} />
        </div>
        <article className="FreeTryPlainText">
          <div>
            <p style={{ fontSize: "smaller" }}>{RawText}</p>
          </div>
        </article>
        {SendEmailSuccess ? (
          <Col style={{ margin: "20px 0" }}>
            <MdCloudDone
              style={{ fontSize: "xx-large", marginRight: "10px" }}
              id="MdCloudDone"
            />
            <Form.Label>
              The full result has been sent to your Email.
              <a href="#/auth/login" style={{ textDecoration: "underline" }}>
                Log in
              </a>{" "}
              to upload more.
            </Form.Label>
          </Col>
        ) : (
          <div>
            <div style={{ margin: "10px 0" }}>
              {display_Table_Ky_pairs_info()}
            </div>
            <Row>
              <Col>
                <Form.Control
                  type="text"
                  name="MERGE1"
                  id="MERGE1"
                  placeholder="Name"
                  onChange={(e) => setName(e.target.value)}
                />
              </Col>
              <Col>
                <Form.Control
                  type="email"
                  name="MERGE0"
                  id="MERGE0"
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Col>
            </Row>
            <div>
              <Col>
                <Button
                  type="submit"
                  style={{ margin: "10px 10px 10px 0px" }}
                  onClick={SendToEmail}
                >
                  Send
                </Button>
                <Form.Label>Send the result to Email</Form.Label>
                <Form.Text className="warning">{FormWarning}</Form.Text>
              </Col>
            </div>
          </div>
        )}
      </div>
    );
  };

  const SendToEmail = async () => {
    if (Email.length > 0 && Email.indexOf("@") != -1 && Name.length > 0) {
      // send to mailchimp email list
      $.ajax({
        type: "post",
        contentType: "application/json; charset=utf-8",
        url: "https://docupal.us5.list-manage.com/subscribe/post-json?u=80a4ace393700d95c64a99ff7&id=a718071aef&c=?",
        dataType: "json",
        data: { MERGE1: Name, MERGE0: Email },
        cache: false,
        error: function (err) {
          console.log("err: ", err);
        },
        success: function (data) {
          if (data.result != "success") {
            console.log("fail: ", data);
          } else {
            console.log("success: ", data);
          }
        },
      });

      let SendToEmail = await props.simplepost(
        { Name, Email, PageID },
        "/ftree/GuestSendToEmail"
      );
      if (SendToEmail) {
        setSendEmailSuccess(true);
      }
    } else {
      setFormWarning("Please fill correct Email and Name.");
    }
  };

  if (props.loggedIn) {
    console.log("logged in");
    return <Route render={() => <Redirect to={{ pathname: "/" }} />} />;
  } else {
    return (
      <Container fluid className="FreeTryContainer">
        <Row className="FreeTryContainer_inner">
          <Col className="FreeTryContainer-left-side">
            <img
              src="assets/images/DocuPal_solid_transp.png"
              className="LOGO"
            />
            <img
              src="assets/images/DocupalFreeTry_small.png"
              className="FreeTryIMG"
              alt=""
            />
            <div className="FreeTryTagline">
              <h5>
                {
                  "#1 AI document management platform.\nDiscover your AI assistant."
                }
              </h5>
            </div>
            <Row style={{ margin: "35px 0px" }}>
              <Col>
                <Button
                  variant="outline-light"
                  style={{ width: "100%" }}
                  href="#/auth/login"
                >
                  Log in
                </Button>
              </Col>
              <Col>
                <Button
                  variant="outline-light"
                  style={{ width: "100%" }}
                  href="#/auth/register"
                >
                  Register
                </Button>
              </Col>
            </Row>
          </Col>
          <Col
            style={{ height: "100%" }}
            className={Status === "processing" ? "Blur-me" : ""}
          >
            {Status === "processing" ? (
              <div className="cell-icon OCR-IN-PROCESS">
                <GoGear id="GoGear" />
                <span>Please wait ...</span>
              </div>
            ) : null}
            {RawText ? (
              display_raw_txt()
            ) : Error ? (
              <div className="SYSINFO">{Error}</div>
            ) : (
              <div>
                <QuickOCRview
                  simplepost={props.simplepost}
                  isGuest={true}
                  setWarning={setWarning}
                  setError={setError}
                  GuestCode={props.GuestCode}
                />
                {Warning ? (
                  <div className="FreeTryWarning">{Warning}</div>
                ) : null}
              </div>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}
