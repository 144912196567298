import React from 'react';
// import '../../assets/css/components/app.css';
import { withRouter } from 'react-router-dom';
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";

class HomePage extends React.Component {
  constructor(props) {
    super(props);
  }
  
  componentWillMount() {
  }

  render() {
    return (
      <div className="home-page">      
        <ButtonGroup vertical block>
          <Button href="#/signup" bsStyle="primary" bsSize="large" block>Sign Up</Button>
        </ButtonGroup>

        <ButtonGroup vertical block>
          <Button href="#/auth/login" bsStyle="info" bsSize="large" block>Log In</Button>
        </ButtonGroup>

      </div>
    );
  }
}

export default withRouter(HomePage);
