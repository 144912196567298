import React from 'react';

import { withRouter } from 'react-router-dom';

import {translate} from '../../helpers/translate';

class Tools extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        
    };
  }

  componentWillMount() {
  }

  componentDidMount() {
  }

  render() {
    return (
        <div className='dash_title'>
            <h5>{translate('Tools')}</h5>
            <hr />
            <div className="ComingSoon">
              Coming soon ...
            </div>
        </div>
    );
  }
}

export default withRouter(Tools);
