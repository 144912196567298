// Action types
import {
  SAVE_INVOCIE_GEN,
} from '../helpers/action_types';
const _initalState = [];
  
const InvoiceGenReducer = (state = _initalState, action) => {
  Object.freeze(state);
  let newState;

  switch (action.type) {
    case SAVE_INVOCIE_GEN:
      console.log('invoice reducer: ', state, action);
      
      newState = action.data;
      return newState
    default:
      return state
  }
}
  
export default InvoiceGenReducer;