import React from 'react';
import { Alert } from 'react-bootstrap';
const { useEffect, useState } = React;
import './notificationStyles.css';
import notificationConverter from './notificationConverter';

export default function NotificationToastList(props) {
    const [notifications, setNotifications] = useState([]);

    // If there is a new unread notification
    if (
        props.notifications.length > 0 &&
        props.notifications[0].id !== props.latestFetchID &&
        !props.notifications[0].read &&
        !notifications.some(n => n.id === props.notifications[0].id)
    ) {
        setNotifications([props.notifications[0], ...notifications]);
    }

    const handleClose = (id, index) => {
        // Set as read in the backend and in the store
        props.setNotificationAsRead(id);

        // Remove the notification from state
        const clone = [...notifications];
        clone.splice(index, 1);
        setNotifications(clone);
    }

    return (
        <div
            aria-live="polite"
            aria-atomic="true"
            className="toastList">
            <div className="toastListPos">
                { notifications.map((notification, index) => (
                    <Alert index={notification.id} variant="primary" onClose={() => handleClose(notification.id, index)} dismissible className="notificationSize">
                        <p>{ notificationConverter(notification.content) }</p>
                    </Alert>
                )) }
            </div>
        </div>
    )
}
