import React from 'react'
import { mimeIconMap } from '../../../helpers/IconHelper';
import { MdFolder } from '@react-icons/all-files/md/MdFolder';
import './searchResult.css';

export default function SearchResult({ item, i, redirectURL, openResult }) {
    return (
        <div className={"SearchResultSection"+" StripColor"+i%2} onClick={(e) => {openResult(e, redirectURL)}}>
            <div className='SearchResCell cell-icon'>
                {Object.keys(item).includes('FileID')?mimeIconMap(item.title):<MdFolder />}
                <div className='search-result-text'>
                    <a href={redirectURL} onMouseDown={(e) => {openResult(e, redirectURL)}}>{item.title}</a>
                    { item.pagesWithHighlight && item.pagesWithHighlight.map(val => (
                        <div className="pageHighlightContainer">
                            { pageWithHighlightGenerator(val.pageNumber, val.highlight.raw_text) }
                        </div>
                    )) }
                </div>
            </div>
        </div>
    )
}

const pageWithHighlightGenerator = (pageNumber, highlightArray) => {
    return highlightArray.map(highlightText => (
        <p className="pageHighlightParagraph">
            Page {pageNumber} - "
            { highlightText.split('§§§§').reduce((acc, cur, i) => {
                if (i % 2) { // Bold
                    acc.push(<b>{cur}</b>)
                } else {
                    acc.push(<span>{cur}</span>)
                }
                return acc
            }, []) }"
        </p>
    ))
}
