import {translate} from './translate';


export function TimeBasedGreeting(){
    var now = new Date();
    var hrs = now.getHours();
    var msg = translate("Hello");

    if (hrs > 5) msg = translate("Good morning"); // After 5am
    if (hrs > 12) msg = translate("Good afternoon");    // After 12pm
    if (hrs > 18) msg = translate("Good evening");      // After 6pm

    return msg
}