
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';

import { clearErrorsAction, logout } from '../../actions/session_actions';

import { fetchAllNotifications, fetchUnreadCount, switchNotificationCenter } from '../../actions/notification_actions';

import { updateUser } from '../../actions/user_actions';

import { SimplePost, GetSearchResults, getRateLimit } from '../../actions/request_actions';

import Navbar from './navbar';

const mapStateToProps = (state, ownProps) => {

  const loggedIn = Boolean(state.session.currentUser)

  let currentUser = loggedIn ? state.session.currentUser : null;

  let error = state.errors.session;

  let language = state.entities.Language;

  let loading = state.entities.Loading;

  let unreadCount = state.notifications.count;

  let DownloadProgress = state.entities.DownloadProgress;

  let socket = state.session.socket;

  // let SearchResults = state.entities.Search ? state.entities.Search.results : {Files: [], Folders: []};

  let searchResults = state.entities.Search.results;
  let SearchResults = searchResults || {Folders: [], Files: [], ContentMatches: []};
  let noSearchResults = (searchResults !== null && searchResults.Folders.length === 0 && searchResults.Files.length === 0);

  return {
    socket,
    currentUser,
    loggedIn,
    error,
    language,
    loading,
    unreadCount,
    DownloadProgress,
    SearchResults,
    noSearchResults
  };
};

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout()),
  clearErrors: () => dispatch(clearErrorsAction()),
  updateUser: user => dispatch(updateUser(user)),
  ChooseLanguage: data => dispatch({type: 'CHOOSE_A_LANGUAGE', data}),
  isLoading: data => dispatch({type: 'LOADING_STATUS', data}),
  fetchAllNotifications: data => dispatch(fetchAllNotifications()),
  fetchUnreadCount: data => dispatch(fetchUnreadCount()),
  switchNotificationCenter: () => dispatch(switchNotificationCenter()),
  setDownloadProgress: data => dispatch({type: 'DOWNLOAD_PROGRESS', data}),
  doSearch: (query) => dispatch(GetSearchResults(query, '/ftree/simpleSearch')),
  getRateLimit: () => dispatch(getRateLimit()),
  simplepost: (data, url) => dispatch(SimplePost(data, url)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar));
