import { connect } from 'react-redux';

import { readUser } from '../../actions/user_actions';
import { getBatchRequests, SimplePost, GetSearchResults } from '../../actions/request_actions';

import { withRouter } from 'react-router-dom';

import FileBox from '../FileBoX/filebox';

const mapStateToProps = (state, ownProps) => {
  
  const loggedIn = Boolean(state.session.currentUser);

  let currentUser = Object.keys(state.entities.user).length > 0 ? state.entities.user : state.session.currentUser;
  
  let error = state.errors.session;

  let socket = state.session.socket;

  let searchResults = state.entities.Search.results;
  let AllPath = searchResults || {Folders: [], Files: []};
  let fbtype = "search";
  let noSearchResults = (searchResults !== null && searchResults.Folders.length === 0 && searchResults.Files.length === 0);

  return {
    socket,
    userId: currentUser.id,
    userRole: currentUser.role,
    currentUser,
    loggedIn,
    error,
    AllPath,
    fbtype,
    noSearchResults,
  };
};

const mapDispatchToProps = (dispatch, { location }) => {
  return {
    readUser: userId => dispatch(readUser({id: userId})),
    getRootOwnFolders: () => dispatch(getBatchRequests({}, '/ftree/fetchOwn')),
    getRootSharedFolders: () => dispatch(getBatchRequests({}, '/ftree/fetchShared')),
    getFolderContents: (currentFolderId, page) => dispatch(getBatchRequests({ currentFolderId, page }, '/ftree/fetchContents')),
    simplepost: (data, url) => dispatch(SimplePost(data, url)),
    doSearch: (query) => dispatch(GetSearchResults(query, '/ftree/simpleSearch')),
    ReceiveError: msg => {
        dispatch({type:'RECEIVE_FETCH_ERROR', errors: msg}); 
        setTimeout(()=>{dispatch({type:'RECEIVE_FETCH_ERROR', errors: ''})}, 3000)
    },
    ModifyPath: data => dispatch({type:'MODIFY_PATH', requests: data})
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FileBox)
);