import React, { useState, useEffect } from 'react';  

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';


import {translate} from '../../helpers/translate';


import ReactDropzone from "react-dropzone";

import { MdPictureAsPdf } from '@react-icons/all-files/md/MdPictureAsPdf';
import { MdPhotoLibrary } from '@react-icons/all-files/md/MdPhotoLibrary';
import { FaFolderOpen } from '@react-icons/all-files/fa/FaFolderOpen';
import { FaLanguage } from '@react-icons/all-files/fa/FaLanguage';
import { FaCheck } from '@react-icons/all-files/fa/FaCheck';
import { FaSpinner } from '@react-icons/all-files/fa/FaSpinner';

import {LanguagesSelect} from '../LanguagesDropDown';

import {PostRequest} from '../../util/request_util';

import {checkGuestRestriction} from '../../helpers/checkGuestRestriction';

const QuickOCRview = ({ simplepost, isGuest=false, setWarning=null, 
                        setError=null, GuestCode=null }) => {
    const [Loading, setLoading] = useState(false);
    const [warning, setwarning] = useState(null);
    const [SubmitButton, setSubmitButton] = useState(null);
    const [imageName, setimageName] = useState([]);
    const [filelist, setfilelist] = useState([]);
    const [progressMap, setprogressMap] = useState({});
    const [lang, setlang] = useState('eng');
    const [FolderList, setFolderList] = useState([]);
    const [SelectedFolder, setSelectedFolder] = useState(null);
    const [SuccessFolderID, setSuccessFolderID] = useState(null);
    const [NewFolderName, setNewFolderName] = useState(translate('New Folder'));
    const [UploadedList, setUploadedList] = useState([]);    

    useEffect( async ()=>{
        const fetchData = async () => {
            let MyFolders = await simplepost({}, '/ftree/fetchOwn').then(res => {
                return res.Folders
            });
            let SharedFolders = await simplepost({}, '/ftree/fetchSharedFolders').then(res => {
                return res.Folders
            });
            let combined = MyFolders.concat(SharedFolders)
            setFolderList(['Create a new folder ...', ...combined]);
            if (combined.length > 0){
                setSelectedFolder('1');
            } else {
                setSelectedFolder('0');
            }

        };
        if (!isGuest){
            fetchData();
        }
    }, []);

    const uploadFilePreview = async (files) => {
        let imageName_lst = imageName;
        if (isGuest){
            if (imageName_lst.length > 0 || files.length > 1){
                setWarning('Your can only upload one document a time.\nLog in to upload more.')
                return
            }
        }
        let filelist_lst = filelist;
        files.forEach((file)=>{
            imageName_lst = imageName_lst.concat(file.name);
            filelist_lst = filelist_lst.concat(file);
            setimageName(imageName_lst);
            setfilelist(filelist_lst);
            setSubmitButton(true);
        })
    }

    const HandleUpload = async () => {
        setLoading(true);
        let Target_FolderID, url, SelectedFolder_Data;
        if (isGuest){
            let allowed = checkGuestRestriction();
            if (!allowed){
                setError('Your daily allowance to upload has reached limit.\nLog in to upload more.')
                return
            }
            Target_FolderID = "";
            url = '/ftree/guestupload';
            SelectedFolder_Data = {CODE: 'GPOCR0001'};
        } else {
            SelectedFolder_Data = FolderList[SelectedFolder];
            Target_FolderID = SelectedFolder_Data.FolderID;
            if (SelectedFolder === '0'){
                let created_folder = await simplepost({'name': NewFolderName, 'currFolder': null,
                                'type': 'GPOCR0001', 'lang': lang, 
                                'template_file_url': ''}, '/ftree/createpath');
                console.log('created_folder: ', created_folder);
                if (created_folder) {
                    Target_FolderID = created_folder.FolderID;
                }
            };
            url = '/ftree/authupload';
        }
        let fieldname = 'file';
        let count = 0;
        filelist.forEach( async (f, i) => {
            if (UploadedList.includes(i)){
                console.log('already uploaded, skip');
                setLoading(false);
            } else{
                let formData = new FormData();
                formData.append(`${fieldname}`, f);
                formData.append('method', Target_FolderID);
                formData.append('CODE', SelectedFolder_Data.CODE?SelectedFolder_Data.CODE:"GPOCR0001");
                formData.append('lang', lang?lang:SelectedFolder_Data.lang);
                formData.append('status', 'sent');
                formData.append('type', 'dropin');
                if (GuestCode){
                    formData.append('GuestCode', GuestCode);
                }
                await PostRequest(formData, url, (prog) => {
                    progressMap[`${i}`] = prog * 100;
                    setprogressMap(progressMap);
                }).then(res => {
                    count ++;
                    setUploadedList(oldArray => [...oldArray, i])
                    if (count === filelist.length) {
                        setTimeout(() => {
                            setSuccessFolderID(Target_FolderID);
                            setLoading(false);
                        }, 100);
                    }
                })
            }
        })
    }

    const displaySelectFolder = () => {
        return (
            <div>
                <span><FaFolderOpen style={{fontSize: '20px'}}/> Save to folder:</span>
                <Form.Group className="SelectMenu" style={{padding: '8px 0', margin: '0px'}}>
                    <Form.Control className='SelectMenuInner' as="select" onChange={(e)=>setSelectedFolder(e.target.value)}>
                        {FolderList.map((folder, idx)=>{   
                            return(
                                <option value={`${idx}`} selected={SelectedFolder===`${idx}`}>
                                    {folder.title||folder}
                                </option>
                            )
                        })}
                    </Form.Control>
                    {SelectedFolder==='0'?
                        <Form.Control type="text" placeholder={NewFolderName} 
                            onChange={(e)=>setNewFolderName(e.target.value)} />:null
                    }
                </Form.Group>
            </div>
        )
    }


    return (
        <div style={{padding: '35px'}}>
            <ReactDropzone type="file" name='pdf' className="dropzone" style={{minHeight: '150px', marginBottom: '10px'}}
                onDrop={(accepted, rejected) => {
                    if (accepted.length > 0){
                        uploadFilePreview(accepted)
                    } else {
                        setwarning('Please Upload file with format of image or PDF')
                    }
                }}
                accept="image/*, .pdf"
                >
                    <div style={{lineHeight: '150px', textAlign: 'center', color: 'var(--SecondaryColor)'}}>Upload document here</div>
            </ReactDropzone>
            <span className='warning'>{warning?`*${warning}`:null}</span>
            <div>
                <span><FaLanguage style={{fontSize: '25px'}}/> Choose language:</span>
                <LanguagesSelect style={{padding: '8px 0', margin: '0px'}} current_lang={lang} selectOutput={(e)=>{setlang(e.target.value)}} />
            </div>
            <ol style={{overflowY: 'auto', maxHeight: '150px'}}>
                {imageName.map((fileurl, i)=> {
                    return (
                        <li style={{marginBottom: '5px'}}>
                            {fileurl.substring(fileurl.length-3, fileurl.length).toLowerCase() === 'pdf'?
                                <MdPictureAsPdf style={{color: 'darkred'}}/>
                                :
                                <MdPhotoLibrary style={{color: 'darkred'}}/>   
                            }
                            {fileurl}
                            <div className='pullright'>
                                {Loading&&progressMap[`${i}`]!=100?<FaSpinner className='spin_loading' style={{color: 'var(--SecondaryColor)'}} />:null}
                                {progressMap[`${i}`]===100?<FaCheck style={{color: 'darkgreen'}} />:null}
                            </div>
                        </li>
                    )
                })}
            </ol>
            {isGuest ? null : displaySelectFolder()}
            
            {SubmitButton? 
            <div>
                <Button onClick={HandleUpload} disabled={Loading? true : false}>{translate('Upload')}</Button>
            </div>:null}
            <div style={{position:'absolute', bottom: '10px'}}>
                {SuccessFolderID?
                    <p style={{color: 'var(--SecondaryColor)'}}>
                        <FaCheck style={{color: 'darkgreen'}} />{' '}Upload success, check out the result in the{' '}
                        <a href={`#/folder/${SuccessFolderID}`} style={{fontWeight: 'bold'}}>Folder</a>. or upload more. </p>  : null
                }
            </div>
        </div>
    )
};

export default QuickOCRview;