import React, { Component } from "react";
import { NavLink } from "react-router-dom";

export default class ForgotPasswordPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: null,
      company: null,
      email: null,
      phone1: null,
      phone2: null,
      phone3: null,
      warn: null,
      notifications: null,
    };

    this.recoverPassword = this.recoverPassword.bind(this);
    this.displayError = this.displayError.bind(this);
    this.displayNotifications = this.displayNotifications.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.loggedIn) return this.props.history.push("/dashboard");
  }

  validateField(field, value) {
    if (value === null) return true;

    if (field === "email")
      return value.length >= 3 && /[^@]+\@[^\.]+\..*/.test(value.toLowerCase());

    if (field === "bizemail")
      return (
        value.length >= 3 &&
        /[^@]+\@[^\.]+\..*/.test(value.toLowerCase()) &&
        /\@(?!(me|mac|icloud|gmail|googlemail|hotmail|live|msn|outlook|yahoo|ymail|aol)\.)/.test(
          value.toLowerCase()
        )
      );

    console.error("must add validation for field:", field);
    return false;
  }

  recoverPassword() {
    let email = window.$("#recover-form #recover-email").val() || "";

    if (this.validateField("email", email)) {
      this.setState({ warn: null, notifications: null });

      this.props
        .forgotPassword({ email: email })
        .then((user) => {
          console.log(user);
          this.setState({
            notifications: "Reset password link has been sent to your email.",
          });
        })
        .catch((err) => {
          this.setState({ warn: err.responseText });
        });
    } else {
      this.setState({ warn: "Oops, emails do not match." });
    }
  }

  displayError() {
    let errors = this.state.warn ? this.state.warn : this.props.errors;

    if (errors === undefined || errors === null || errors.length === 0)
      return null;

    return (
      <p>
        <div className="login-error">
          <h6>{errors}</h6>
        </div>
      </p>
    );
  }

  displayNotifications() {
    let notifications = this.state.notifications
      ? this.state.notifications
      : null;
    console.log("Notifications to show:", notifications);

    if (notifications === undefined || notifications === null) return null;

    return (
      <p>
        <div className="login-success">
          <h6>{notifications}</h6>
        </div>
      </p>
    );
  }

  render() {
    return (
      <div className="auth-outer">
        <div className="inner">
          <form id="recover-form">
            <img
              src="assets/images/DocuPal_solid_transp.png"
              class="logo"
              alt=""
            />

            <h3>Forgot Password?</h3>

            <p className="form-subtitle">
              Enter the email address associated with your account and we will
              send you a password reset link.
            </p>

            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                id="recover-email"
                className="form-control"
                placeholder="Type your email"
              />
            </div>

            <button
              type="button"
              onClick={() => this.recoverPassword()}
              className="btn btn-dark btn-lg btn-block"
            >
              Submit
            </button>

            <p className="forgot-password text-right">
              New user?{" "}
              <NavLink to="/auth/register">Register an account</NavLink>
            </p>
            <p className="forgot-password text-right">
              <NavLink to="/auth/login">Login instead</NavLink>
            </p>

            <this.displayError />
            <this.displayNotifications />
          </form>
        </div>
      </div>
    );
  }
}
