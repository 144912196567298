const  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
function formatted_date(input, type=1){
    // console.log('run formatted_date');
    
    let result="";
    let d = input ? new Date(input) : new Date();
    let FullYear = d.getFullYear();
    let Month = d.getMonth() + 1;
    let Week = weekdays[d.getDay()];
    let Day = d.getDate();
    let Hour = d.getHours();
    let Minute = d.getMinutes();

    if (type===1){
        result = `${FullYear}/${("0"+Month).slice(-2)}/${("0"+Day).slice(-2)} ${("0"+Hour).slice(-2)}:${("0"+Minute).slice(-2)}`
    } else if (type===2) {
        result = `${("0"+Hour).slice(-2)}:${("0"+Minute).slice(-2)}, ${Week}, ${months[Month-1].slice(0, 3)} ${Day}, ${FullYear}`
    } else if (type===3) {
        result = `${FullYear}/${("0"+Month).slice(-2)}/${("0"+Day).slice(-2)}`
    } else {
        result = `${Week}, ${months[Month-1]} ${Day}, ${FullYear}`
    }

    return result;
}

const datesAreOnSameDay = (first, second) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();


export{
    formatted_date,
    datesAreOnSameDay
}