import React from 'react'
import { BsTriangleFill } from '@react-icons/all-files/bs/BsTriangleFill';
import { BsFillSquareFill } from '@react-icons/all-files/bs/BsFillSquareFill';
import { BsFillCircleFill } from '@react-icons/all-files/bs/BsFillCircleFill';

const options = [
    { value: '0', label: 'tri', color: '#00B8D9' }, // blue
    { value: '1', label: 'tri', color: '#FF5630' }, // red
    { value: '2', label: 'tri', color: '#36B37E' }, // green
    { value: '3', label: 'rec', color: '#00B8D9' },
    { value: '4', label: 'rec', color: '#FF5630' },
    { value: '5', label: 'rec', color: '#36B37E' },
    { value: '6', label: 'cir', color: '#00B8D9' },
    { value: '7', label: 'cir', color: '#FF5630' },
    { value: '8', label: 'cir', color: '#36B37E' },
];

const TagsToOptions = (storedTags) => { // "0,1,2" => [option0, option1, option2]
    if (storedTags){
        // let idx_list = storedTags.split(',');
        let idx_list = storedTags;
        let optionList = idx_list.map(ele=>{return options[Number(ele)]})
        return optionList
    } else {
        return []
    }
}

const OptionsToTags = (allOptions) => { // [option0, option1, option2] => "0,1,2"
    if (allOptions.length > 0){
        let NewTags = allOptions.map(ele=>{return ele['value']});
        // return NewTags.join(',')
        return NewTags
    } else {
        return null
    }
}

function tags2icons(tags) {
    let options = TagsToOptions(tags);
    return (
        <div style={{position: 'absolute', right: '0px'}}>
            {
                options.map(ele => {
                    let label = ele.label;
                    let color = ele.color;
                    let style = {
                        color, marginRight: '5px', fontSize: 'unset', 
                        padding: 'unset'
                    }
                    return(
                        label === 'tri'?
                            <BsTriangleFill style={style} />:
                                label === 'rec'?
                                    <BsFillSquareFill style={style} />:
                                        <BsFillCircleFill style={style} />
                    )
                })
            }
        </div>
    )
}

module.exports = {
    options, tags2icons
}