import React from 'react';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import App from './app';

import './style.css';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripe_key = (process.env.NODE_ENV==='production')
    ? 'pk_live_51JS5tyHb9TUZVvB6FVGCcN8hQLVSTC7kSPAuF46MgPE9IXH2ysARTHoXTEu4CpDSIWzPgY7ch0oQXZp8WtfbBaVV00YFoJgnmc'
    : 'pk_test_51JS5tyHb9TUZVvB6xIWjmfN5AqA2sJsgqUwNQMw7W3iccm2oSgu8hdQuSs8a8qbXsHP1qivZp5lGPhFyO8IVdYpj00wE5bnjjc';

const Root = ({ store }) => (
    <Provider store={store}>
        <HashRouter>
            <Elements stripe={loadStripe(stripe_key)}>
                <App />
            </Elements>
        </HashRouter>
    </Provider>
);

export default Root;
