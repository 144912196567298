export default {
    TicketStatus: {
        'CLOSED': 'Closed',
        'CLOSED_BY_USER': 'Closed by User',
        'OPENED': 'Opened',
        'WAITING_RESPONSE': 'Waiting Response',
        'REOPENED': 'Reopened',
    },
    TicketStatusCodes: {
        'CLOSED': 'CLOSED',
        'CLOSED_BY_USER': 'CLOSED_BY_USER',
        'OPENED': 'OPENED',
        'WAITING_RESPONSE': 'WAITING_RESPONSE',
        'REOPENED': 'REOPENED',
    },
    TicketCategory: {
        'Bug': 'Bug',
        'OCR': 'OCR',
        'Files/Folders': 'Files/Folders',
        'Suggestion': 'Suggestion'
    }
}