// import React from 'react';

export function checkGuestRestriction(){
    // return true
    let GuestUsage = localStorage.getItem('GUEST_USAGE');
    console.log('GuestUsage: ', GuestUsage);
    const d = new Date();
    const newDate = `${d.getMonth() + 1}${d.getDate()}`;
    const _initalState = `${newDate}_1`;
    if (!GuestUsage){
        localStorage.setItem('GUEST_USAGE', _initalState);
        return true
    } else {
        // check if guest is allow to upload 
        const DATE_USAGE = GuestUsage.split('_');
        const DATE = DATE_USAGE[0];
        const USAGE = DATE_USAGE[1];
        if (DATE === newDate) {
            if (Number(USAGE) >= 2){
                return false
            } else {
                // Increment
                let newUsage = Number(USAGE) + 1;
                localStorage.setItem('GUEST_USAGE', `${newDate}_${newUsage}`);
                return true
            }
        } else {
            localStorage.setItem('GUEST_USAGE', `${newDate}_1`);
            return true
        }
    }
}