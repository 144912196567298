// API Util
import * as Util from '../util/session_api_util';

// Action types
import {
  RECEIVE_CURRENT_USER,
  RECEIVE_SIGNUP_USER,
  LOGOUT_USER,
  RECEIVE_SESSION_ERRORS,
  CLEAR_SESSION_ERRORS,
  SET_SHARE_TOKEN
} from '../helpers/action_types';

/*** ************************************
 *** Action POJO with type and payload ***
 *** *************************************
 */
const receiveCurrentUser = user => ({
  type: RECEIVE_CURRENT_USER,
  user
});

const receiveSignupUser = user => ({
  type: RECEIVE_SIGNUP_USER,
  user
});

const logoutUser = () => ({
  type: LOGOUT_USER
});

const receiveErrors = errors => ({
  type: RECEIVE_SESSION_ERRORS,
  errors
});

const clearErrors = () => ({
  type: CLEAR_SESSION_ERRORS
});

const setShareTokenAction = shareToken => ({
  type: SET_SHARE_TOKEN,
  shareToken
})

/*** ************************************
 *** Apply Redux thunk middle   *********
 *** Including calling API, and *********
 ********* POJO to reducers *************
 *** *************************************
 */
export const localStorageLogin = (user, token) => dispatch => {
  return dispatch(receiveCurrentUser({ user, token }));
}

export const login = formUser => dispatch => {
  return Util.login(formUser)
    .then(user => dispatch(receiveCurrentUser(user)))
    .fail(errors => dispatch(receiveErrors(errors.responseText)));
};

export const googleLogin = token => dispatch => {
  return Util.googleLogin(token)
    .then(user => dispatch(receiveCurrentUser(user)))
    .fail(errors => dispatch(receiveErrors(errors.responseText)));
};

export const googleSignup = token => dispatch => {
  return Util.googleSignup(token);
};

export const signup = formUser => dispatch => {
  return Util.signup(formUser)
    .then(user => dispatch(receiveSignupUser(user)))
    .fail(errors => dispatch(receiveErrors(errors.responseText)));
};

export const verifyEmailAction = data => dispatch => {
  return Util.verifyEmail(data)
    .then(user => dispatch(receiveCurrentUser(user)))
    .fail(errors => dispatch(receiveErrors(errors.responseText)));
};

export const verifyThirdPartyLogin = user => dispatch => {
  return dispatch(receiveCurrentUser(user))
};

export const showCustomError = errorStr => dispatch => {
  return dispatch(receiveErrors(errorStr));
}

export const setShareToken = shareToken => dispatch => {
  return dispatch(setShareTokenAction(shareToken))
}

export const logout = () => dispatch => dispatch(logoutUser());
export const clearErrorsAction = () => dispatch => dispatch(clearErrors());
