import React, { useState } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import './style.css';

import { connect } from 'react-redux';
import { updateUser } from '../../actions/user_actions';
import { SimplePost } from '../../actions/request_actions';

const mapStateToProps = (state, ownProps) => {
  return {
  };
};

const mapDispatchToProps = (dispatch, { location }) => {
  return {
    updateUser: user => dispatch(updateUser(user)),
    simplepost: (data, url) => dispatch(SimplePost(data, url)),
  };
};

const ReActivate = (props) => {
  const [reactivated, setreactivated] = useState(false);

  const handleClick = async (e) => {
    e.preventDefault();
    const {subscription} = await props.simplepost({subscriptionId: props.location.state.subscription}, '/reactivate-subscription');
    if (subscription.status === 'active'){
        setreactivated(true);
    } else {
      window.alert('Re-activate failed, please try again.')
    }
  };

  if(reactivated) {
    return <Redirect to={`/account-details`} />
  }

  return (
    <div>
      <h1>Re-activate subscription</h1>
      <button onClick={handleClick}>Confirm</button>
    </div>
  )
}


export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReActivate)
);