import translation from '../assets/translate/DocuPal_Web_Translate_02.json'


export const translate = (txt) => {
    //Access redux store as below
    const lang = window.store.getState().entities.Language||'eng';
    // let TranslateJSON = {};
    // console.log('lang: ', lang);
    // var Papa = require("../assets/PapaParse-5.3.0/papaparse.min.js");
    // Papa.parse("../assets/translate/DocuPal_Web_Translate_01.csv", {
    //     header: true,
    //     download: true,
    //     skipEmptyLines: true,
    //     complete: (res)=>{
    //         // console.log('res: ', res);
    //         let data = res.data;
    //         for (let i=0; i<data.length; i++){
    //             let item = data[i];
    //             let ENG_word = item['eng'];
    //             TranslateJSON[`${ENG_word}`] = item;
    //         };
    //         console.log('TranslateJSON: ', TranslateJSON);
    //     }
    // });
    // console.log('translation: ', translation);
    if (lang != 'eng'){
        if (!translation[`${txt.toLowerCase()}`]){
            return txt
        }
        return (translation[`${txt.toLowerCase()}`][`${lang}`]) || txt
    } else {
        return txt
    }
}