import React from 'react';

import {MdPictureAsPdf} from '@react-icons/all-files/md/MdPictureAsPdf';
import {MdDeleteForever} from '@react-icons/all-files/md/MdDeleteForever';
import {MdFileUpload} from '@react-icons/all-files/md/MdFileUpload';
import {MdFileDownload} from '@react-icons/all-files/md/MdFileDownload' ;
import {MdError} from '@react-icons/all-files/md/MdError';
import {MdSync} from '@react-icons/all-files/md/MdSync';
import {MdAssessment} from '@react-icons/all-files/md/MdAssessment';
import {MdVerifiedUser} from '@react-icons/all-files/md/MdVerifiedUser';

import {FaSearch} from '@react-icons/all-files/fa/FaSearch';
import {FaFileExcel} from '@react-icons/all-files/fa/FaFileExcel';
import {FaFileWord} from '@react-icons/all-files/fa/FaFileWord';
import {FaFilePdf} from '@react-icons/all-files/fa/FaFilePdf';
import {FaFileImage} from '@react-icons/all-files/fa/FaFileImage';
import {FaRegFile} from '@react-icons/all-files/fa/FaRegFile';
import {FaCheck} from '@react-icons/all-files/fa/FaCheck';
import {FaFile} from '@react-icons/all-files/fa/FaFile';

import {GoGear} from '@react-icons/all-files/go/GoGear';

import {TiInputChecked} from '@react-icons/all-files/ti/TiInputChecked';
import {TiExport} from '@react-icons/all-files/ti/TiExport';
import {Cancel} from '@react-icons/all-files/ti/TiCancel';

import mime from 'mime';

function iconMap(hash){
    switch (hash){
      case 'FaSearch':
        return <FaSearch id='FaSearch'/>
      case 'MdPictureAsPdf':
        return <MdPictureAsPdf id='MdPictureAsPdf'/>
      case 'MdDeleteForever':
        return <MdDeleteForever id='MdDeleteForever'/>
      case 'DirectionsBoatIcon':
        return <DirectionsBoatIcon id='DirectionsBoatIcon'/>
      case 'GavelIcon':
        return <GavelIcon id='GavelIcon'/>
      case 'LocalShippingIcon':
        return <LocalShippingIcon id='LocalShippingIcon'/>
      case 'AssignmentIcon':
        return <AssignmentIcon id='AssignmentIcon'/>
      case 'MdFileUpload':
        return <MdFileUpload id='MdFileUpload'/>
      case 'MdFileDownload':
        return <MdFileDownload id='MdFileDownload'/>
      case 'TiInputChecked':
        return <TiInputChecked id='TiInputChecked'/>
      case 'Check':
        return <FaCheck id='Check'/>
      case 'MdError':
        return <MdError id='MdError'/>
      case 'MdSync':
        return <MdSync id='MdSync'/>
      case 'GoGear':
        return <GoGear id='GoGear'/>
      case 'MdAssessment':
        return <MdAssessment id='MdAssessment'/>
      case 'TiExport':
        return <TiExport id='TiExport'/>
      case 'MdVerifiedUser':
        return <MdVerifiedUser id='MdVerifiedUser'/>
      case 'Cancel':
        return <Cancel id='Cancel'/>
      default:
        return null
    }
  }

  function mimeIconMap(path){
    let mime_1, mime_2
    let TheType = mime.getType(path);
    if (!TheType){
      return <FaFile id="IMG" />
    }
    mime_1 = mime.getType(path).split("/")[0];
    mime_2 = mime.getType(path).split("/")[1];
    
    switch (mime_1){
      case 'image':
        return <FaFileImage id="IMG"/>
      case 'application':
        if (mime_2.includes('excel') || mime_2.includes('spreadsheet')){
          return <FaFileExcel id="EXCEL"/>
        } else if (mime_2.includes('pdf')){
          return <FaFilePdf id="PDF"/>
        } else if (mime_2.includes('word')){
          return <FaFileWord id="WORD"/>
        }
      case 'text':
        return <FaRegFile id="TEXT"/>
      default:
        return <FaRegFile />
    }
  }

  module.exports = {
      iconMap, mimeIconMap
  }