import { connect } from "react-redux";

import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { Form, Col, Row } from "react-bootstrap";
import { SimplePost, SimpleGet2 } from "../../actions/request_actions";
import { updateUser } from "../../actions/user_actions";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import "./style.css";

import { formatted_date } from "../../helpers/date_format";

const mapStateToProps = (state, ownProps) => {
  const currentUser = Object.keys(state.entities.user).includes("id")
    ? state.entities.user
    : state.session.currentUser;
  return {
    currentUser,
  };
};

const mapDispatchToProps = (dispatch, { location }) => {
  return {
    simplepost: (data, url) => dispatch(SimplePost(data, url)),
    simpleget: (data, url) => dispatch(SimpleGet2(data, url)),
    updateUser: (user) => dispatch(updateUser(user)),
  };
};

const Account = (props) => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [MySubscription, setMySubscription] = useState(null);
  const [payment_methods, setPayment_methods] = useState([]);
  const [customer, setcustomer] = useState(null);
  const [membership, setMembership] = useState(props.currentUser.membership);
  const [plans, setPlans] = useState([]);
  const [AddNew, setAddNew] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [AddrState, setAddrState] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      // await props.readUser(props.currentUser.id);
      store.dispatch({ type: "LOADING_STATUS", data: true });
      const { subscriptions } = await props.simplepost(
        { customerId: props.currentUser.customerID },
        "/subscriptions"
      );
      const { paymentMethods } = await props.simplepost(
        { customerId: props.currentUser.customerID },
        "/payment-method"
      );
      const { customer } = await props.simplepost(
        { customerId: props.currentUser.customerID },
        "/get-customer"
      );
      const { prices } = await props.simpleget({}, "/stripe-config");
      console.log("customer: ", customer);
      console.log("subscriptions: ", subscriptions);
      console.log("prices: ", prices);
      let non_cancel = subscriptions.filter(
        (ele) => ele.status === "active" || ele.status === "trialing"
      );
      console.log("non_cancel: ", non_cancel);
      setSubscriptions(subscriptions);
      if (non_cancel.length > 0) {
        setMySubscription(non_cancel[0]);
      }
      setPayment_methods(paymentMethods);
      setcustomer(customer);
      setPlans(prices);
      store.dispatch({ type: "LOADING_STATUS", data: false });
    };
    fetchData();
  }, []);

  if (!subscriptions) {
    return "";
  }

  const stripe = useStripe();
  const elements = useElements();

  const createMethod = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return "";
    }
    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name: name,
        email: email,
        phone: phone || null,
        address: {
          city: city,
          line1: address,
          line2: address2,
          state: AddrState,
        },
      },
    });
    if (error) {
      console.log("[error]", error);
      store.dispatch({ type: "RECEIVE_FETCH_ERROR", errors: error.message });
    } else {
      let paymentMethodId = paymentMethod.id;
      const { error, attachedpaymentMethod, updateCustomer } =
        await props.simplepost(
          {
            paymentMethodId: paymentMethodId,
            customerId: props.currentUser.customerID,
          },
          "/attach-payment-method"
        );
      console.log("updateCustomer: ", updateCustomer);
      if (!error) {
        console.log("attach payment method success!");
        setPayment_methods([...payment_methods, attachedpaymentMethod]);
        setAddNew(false);
      } else {
        console.log("[error]", error);
        store.dispatch({ type: "RECEIVE_FETCH_ERROR", errors: error.message });
      }
    }
  };

  const removeMethod = async (e, pm_id) => {
    e.preventDefault();
    const { error, detachpaymentMethod } = await props.simplepost(
      { paymentMethodId: pm_id },
      "/detach-payment-method"
    );
    if (!error) {
      console.log("detach payment method success!");
      setPayment_methods(
        payment_methods.filter((ele) => ele.id != detachpaymentMethod.id)
      );
    } else {
      console.log("[error]", error);
      store.dispatch({ type: "RECEIVE_FETCH_ERROR", errors: error.message });
    }
  };

  const createSubscription = async (priceId) => {
    if (props.currentUser.membership === "pro") {
      setmembership("pro");
    } else {
      if (customer) {
        if (
          subscriptions.some(
            (elem) =>
              elem.plan.id === priceId &&
              (elem.status === "active" || elem.status === "trialing")
          )
        ) {
          window.alert("You have an active plan exists, please cancel first.");
        } else {
          if (customer.invoice_settings.default_payment_method) {
            const { error, subscription, clientSecret } =
              await props.simplepost(
                {
                  customerId: props.currentUser.customerID,
                  priceId: priceId,
                  default_payment_method:
                    customer.invoice_settings.default_payment_method ||
                    payment_methods[0],
                  allowtrial: props.currentUser.allowtrial ? 1 : 0,
                },
                "/create-subscription"
              );
            // console.log('clientSecret: ', clientSecret);
            if (!clientSecret && !error) {
              // Start trial
              await props.updateUser({ membership: "pro", allowtrial: false });
              setSubscriptions([...subscriptions, subscription]);
              setMySubscription(subscription);
            } else if (clientSecret && !error) {
              // Subscribe with no trial, continue confirm payment
              let { error, paymentIntent } = await stripe.confirmCardPayment(
                clientSecret,
                {
                  payment_method:
                    customer.invoice_settings.default_payment_method ||
                    payment_methods[0],
                }
              );
              if (paymentIntent && paymentIntent.status === "succeeded") {
                await props.updateUser({ membership: "pro" });
                window.location.reload();
              }
            } else {
              window.alert(`Subscribe failed. Please try again later`);
            }
          } else {
            console.log("No payment method find");
            store.dispatch({
              type: "RECEIVE_FETCH_ERROR",
              errors:
                "Please add a payment method first. You will not be charged during the free trail",
            });
            setAddNew(true);
          }
        }
      } else {
        console.log("Error! No customer found");
      }
    }
  };

  const HandleCancel = async (e, SubID) => {
    e.preventDefault();
    if (SubID.length > 0) {
      if (window.confirm("Are you sure that you want to cancel?")) {
        const { subscription } = await props.simplepost(
          { subscriptionId: SubID },
          "/cancel-subscription"
        );
        if (subscription.status === "canceled") {
          await props.updateUser({ membership: "basic" });
          window.location.reload();
        } else {
          window.alert("Cancel failed, please try again.");
        }
      }
    } else {
      window.alert("Subscription does not exist.");
      window.location.reload();
    }
  };

  return (
    <div className="AccountDetails">
      <div className="AccountDetailsHead">
        <img src="assets/images/DocuPal_solid_transp.png" />
      </div>
      <h5 className="headline">Account overview</h5>
      <div>
        Current Plan:{" "}
        <span id="bolder">{props.currentUser.membership.toUpperCase()}</span>
      </div>
      {props.currentUser.membership.toUpperCase() === "BASIC" ? (
        <div>
          <div>
            Price: <span id="bolder">$0 / month</span>
          </div>
          <div>
            {plans.map((price) => {
              return (
                <div key={price.id} className="PlanCard">
                  <h5>{price.product.name}</h5>
                  {price.recurring.interval === "year" ? (
                    <p>
                      <span id="strikethrough">$240 / year</span> {"  "}$
                      {price.unit_amount / 100} / {price.recurring.interval}
                    </p>
                  ) : (
                    <p>
                      ${price.unit_amount / 100} / {price.recurring.interval}
                    </p>
                  )}
                  <button
                    id="PrimaryButton"
                    onClick={() => createSubscription(price.id)}
                  >
                    Upgrade now
                  </button>{" "}
                  7 days free trial for the first time. You can cancel anytime.
                </div>
              );
            })}
          </div>
        </div>
      ) : MySubscription ? (
        <div>
          <div>
            Status: <span id="bolder">{MySubscription.status}</span>
          </div>
          <div>
            Price:{" "}
            <span id="bolder">
              {MySubscription.plan.amount / 100}{" "}
              {MySubscription.plan.currency.toUpperCase()} /{" "}
              {MySubscription.plan.interval}
            </span>
          </div>
          <div>
            Payment Method:{" "}
            <span id="bolder">
              {MySubscription.default_payment_method ? (
                MySubscription.default_payment_method.card ? (
                  <span>
                    {MySubscription.default_payment_method.card.brand}*****
                    {MySubscription.default_payment_method.card.last4}
                  </span>
                ) : null
              ) : null}
            </span>
          </div>
          {MySubscription.status === "trialing" ? (
            // <div>Trial ends: {(new Date(MySubscription.trial_end * 1000).toString())}</div>:null}
            <div>
              Trial ends:{" "}
              <span id="bolder">
                {formatted_date(MySubscription.trial_end * 1000, 2)}
              </span>
            </div>
          ) : null}
          {/* <div>Next Billing Date: {(new Date(MySubscription.current_period_end * 1000).toString())}</div> */}
          <div>
            Next Billing Date:{" "}
            <span id="bolder">
              {formatted_date(MySubscription.current_period_end * 1000, 2)}
            </span>
            {/* <Link id='SecondaryButton' className='pullright' to={{pathname: '/cancel', state: {subscription: MySubscription.id }}}>Cancel Subscription</Link></div> */}
            <a
              id="SecondaryButton"
              className="pullright"
              href=""
              onClick={(e) => HandleCancel(e, MySubscription.id)}
            >
              Cancel Subscription
            </a>
          </div>
        </div>
      ) : null}

      <h5 className="headline">My Payment methods</h5>
      {payment_methods.length === 0 ? (
        <span style={{ fontSize: "small" }}>
          You do not have any saved payment method. Please add one, we will not
          charge before the trial finishes.
        </span>
      ) : (
        payment_methods.map((m) => {
          return (
            <div>
              {m.card.brand} **** {m.card.last4}
              {customer
                ? customer.invoice_settings.default_payment_method === m.id
                  ? " (Default)"
                  : null
                : null}
              <a
                id="SecondaryButton"
                className="pullright"
                href=""
                onClick={(e) => removeMethod(e, m.id)}
              >
                Remove
              </a>
            </div>
          );
        })
      )}
      {AddNew ? (
        <form className="PaymentForm" onSubmit={createMethod}>
          <Row>
            <Form.Label column="sm" lg={2}>
              Name
            </Form.Label>
            <Col>
              <Form.Control
                className="PayFormInput"
                size="sm"
                type="text"
                placeholder="Name"
                required
                onChange={(e) => setName(e.target.value)}
              />
            </Col>
          </Row>
          <Row>
            <Form.Label column="sm" lg={2}>
              Email
            </Form.Label>
            <Col>
              <Form.Control
                className="PayFormInput"
                size="sm"
                type="email"
                placeholder="Email"
                required
                onChange={(e) => setEmail(e.target.value)}
              />
            </Col>
          </Row>
          <Row>
            <Form.Label column="sm" lg={2}>
              Phone
            </Form.Label>
            <Col>
              <Form.Control
                className="PayFormInput"
                size="sm"
                type="tel"
                placeholder="Phone (Optional)"
                onChange={(e) => setPhone(e.target.value)}
              />
            </Col>
          </Row>
          <Row>
            <Form.Label column="sm" lg={2}>
              Address
            </Form.Label>
            <Col>
              <Form.Control
                className="PayFormInput"
                size="sm"
                type="text"
                placeholder="Address"
                required
                onChange={(e) => setAddress(e.target.value)}
              />
            </Col>
          </Row>
          <Row>
            <Form.Label column="sm" lg={2}>
              Address 2
            </Form.Label>
            <Col>
              <Form.Control
                className="PayFormInput"
                size="sm"
                type="text"
                placeholder="Apartment, floor, or unit number"
                onChange={(e) => setAddress2(e.target.value)}
              />
            </Col>
          </Row>
          <Row>
            <Form.Label column="sm" lg={2}>
              City
            </Form.Label>
            <Col>
              <Form.Control
                className="PayFormInput"
                size="sm"
                type="text"
                placeholder="City"
                required
                onChange={(e) => setCity(e.target.value)}
              />
            </Col>
            <Form.Label column="sm" lg={2}>
              State/Province
            </Form.Label>
            <Col>
              <Form.Control
                className="PayFormInput"
                size="sm"
                type="text"
                placeholder="State"
                required
                onChange={(e) => setAddrState(e.target.value)}
              />
            </Col>
          </Row>
          <Row>
            <Form.Label column="sm" lg={2}>
              Credit/Debit card
            </Form.Label>
          </Row>
          <Row>
            <Col>
              <CardElement
                options={{
                  style: { base: { color: "#ffffff", fontSize: "16px" } },
                }}
              />
            </Col>
          </Row>
          <button id="PrimaryButton" type="submit" disabled={!stripe}>
            Add
          </button>{" "}
          <a
            id="SecondaryButton"
            href=""
            onClick={(e) => {
              e.preventDefault();
              setAddNew(false);
            }}
          >
            Cancel
          </a>
        </form>
      ) : (
        <button
          id="PrimaryButton"
          onClick={(e) => {
            e.preventDefault();
            setAddNew(true);
          }}
        >
          Add a new card
        </button>
      )}
      <div>
        <a id="exit" href="#/userprofile">
          Go back
        </a>
      </div>
    </div>
  );
};

// export default withRouter(Account);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Account)
);
