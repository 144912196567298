import React from 'react';

class SessionForm extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    if (this.props.loggedIn) {
      this.props.history.push('/dashboard');
    } else if (!this.props.email) {
      this.props.history.push('/');
    }
  }

  render() {
    return (
      <div className="email-sent">
        <h4>We have sent an email to you at <strong id="email-sent-email">{this.props.email}</strong> </h4>
      </div>
    );
  }
}

export default SessionForm;