import React from 'react';
import { Switch, Route } from "react-router-dom";

import LoginPage from './login_page_container';
import RegisterPage from './register_page_container';
import ForgotPasswordPage from './forgot_password_container';
import TermsConditionsPage from './terms_conditions';

export default class AuthPage extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="Auth-Container-Backdrop"></div>
                <div className="Auth-Container">
                    <Switch>
                        <Route path="/auth/login" component={LoginPage} />
                        <Route path="/auth/register" component={RegisterPage} />
                        <Route path="/auth/forgot_password" component={ForgotPasswordPage} />
                        <Route path="/auth/terms_conditions" component={TermsConditionsPage} />
                        <Route exact path="/auth" component={LoginPage} />
                    </Switch>
                </div>
            </React.Fragment>
        )
    }
}