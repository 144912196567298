import React from 'react';
import Form from 'react-bootstrap/Form'

const LanguagesSelect = ({current_lang, selectOutput, loggedIn, style}) => {
    let languages = [['English', 'eng'], ['Français', 'fra'], 
                     ['Deutsch', 'deu'], ['Hindi', 'hin'],
                     ['日本語', 'jpn'], ['한국어', 'kor'],
                     ['简体中文', 'chi_sim'], ['繁體中文', 'chi_tra'],
                     ['Spanish', 'spa'], ['Portuguese', 'por'],
                     ['italian', 'ita'],
                     ['Polish', 'pol'], ['Hebrew', 'heb'],
                     ['Norsk', 'nor'], ['Russian', 'rus'], 
                     ['Arabic', 'ara'], ['Turkish', 'tur'],
                     ['Bengali', 'ben'], ['Esperanto', 'epo'],
                     ['Thai', 'tha'], ['Indonesian', 'ind'],
                     ['Vietnamese', 'vie'], ['Mongolian', 'mon'],
                     ['Large Format ', 'large_eng']
                    ]
    let newstyle = Object.assign({zIndex: 9}, style);
    return (
        // <Form.Group className="SelectMenu" style={loggedIn?null:{zIndex: 9}}>
        <Form.Group className="SelectMenu" style={loggedIn?null:newstyle}>
            <Form.Control className='SelectMenuInner' as="select" onChange={(e)=>{selectOutput(e)}}>
                {languages.map((lang, idx)=>{                    
                    return(
                        <option value={lang[1]} selected={lang[1]===current_lang}>{lang[0]}</option>
                    )
                })}
            </Form.Control>
        </Form.Group>
    )
};

const LanguagesSelectDeepOCR = ({current_lang, selectOutput}) => {
    let languages = [['English', 'eng'], ['日本語', 'jpn']]
    return (
        <Form.Group className="SelectMenu">
            <Form.Control className='SelectMenuInner' as="select" onChange={(e)=>{selectOutput(e)}}>
                {languages.map((lang, idx)=>{                    
                    return(
                        <option value={lang[1]} selected={lang[1]===current_lang}>{lang[0]}</option>
                    )
                })}
            </Form.Control>
        </Form.Group>
    )
};

// export default LanguagesSelect;

module.exports = {
    LanguagesSelect,
    LanguagesSelectDeepOCR
}