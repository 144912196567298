import React, { useState, useEffect } from "react";
import SocialAccountCreateConfirmation from "./components/create_social_account_confirmation";
import SocialAccountCreation from "./components/social_account_creation";

export default function social_register_page(props) {
  const [create, setCreate] = useState(false);

  useEffect(() => {
    // Confirm that the route contains information about the third party service
    // If it doesn't, go to the login page
    if (!props.location.state || !props.location.state.response) {
      goBack();
    }
  }, [props]);

  const goBack = () => {
    props.history.push("/auth");
  };

  return (
    <div className="auth-outer">
      <div className="inner">
        <form id="register-form">
          <img
            src="assets/images/DocuPal_solid_transp.png"
            class="logo"
            alt=""
          />

          {create ? (
            <SocialAccountCreation
              name={props.location.state.response.profileObj.name}
              email={props.location.state.response.profileObj.email}
              auth={props.location.state.response}
              history={props.history}
              signup={props.signup}
              googleLogin={props.googleLogin}
            />
          ) : (
            <SocialAccountCreateConfirmation
              goBack={goBack}
              startAccountCreation={() => setCreate(true)}
            />
          )}
        </form>
      </div>
    </div>
  );
}
