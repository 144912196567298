import React from 'react'
import Form from 'react-bootstrap/Form'
import ListGroup from 'react-bootstrap/ListGroup'

export default function PermissionOptions(props) {
    return (
        // <div>
        //     <Form.Check type="checkbox" label="Read" className="renderCheckbox" checked={props.readPermission} onChange={() => props.setReadPermission(!props.readPermission)} />
        //     <p className="sublabel">Allows the user to read the contents of a folder, see the files and their OCR results.</p>
        //     <Form.Check type="checkbox" label="Write" className="renderCheckbox" checked={props.writePermission} onChange={() => props.setWritePermission(!props.writePermission)} />
        //     <p className="sublabel">Allows the user to upload files to the folder.</p>
        //     <Form.Check type="checkbox" label="Download" className="renderCheckbox" checked={props.downloadPermission} onChange={() => props.setDownloadPermission(!props.downloadPermission)} />
        //     <p className="sublabel">Allows the user to download files in the folder and export their OCR results.</p>
        //     <Form.Check type="checkbox" label="Delete" className="renderCheckbox" checked={props.deletePermission} onChange={() => props.setDeletePermission(!props.deletePermission)} />
        //     <p className="sublabel">Allows the user to delete files.</p>
        //     <Form.Check type="checkbox" label="Edit Permission" className="renderCheckbox" checked={props.editPermissionsPermission} onChange={() => props.setEditPermissionsPermission(!props.editPermissionsPermission)} />
        //     <p className="sublabel">Allows the user to edit the permission of this folder (just like this dialog).</p>
        // </div>
        <ListGroup style={{width: '25em', position: 'fixed', paddingRight: '15px', zIndex: '10'}}>
            <ListGroup.Item>
                <label className='CheckContainer' style={{margin: '0', fontSize: 'small'}}>Read
                    <p className="sublabel">Allows the user to read the contents of a folder, see the files and their OCR results.</p>
                    <input type="checkbox" checked={props.readPermission} onChange={() => props.setReadPermission(!props.readPermission)} />
                    <span className="checkmark"></span>
                </label>
            </ListGroup.Item>
            <ListGroup.Item>
                <label className='CheckContainer' style={{margin: '0', fontSize: 'small'}}>Write
                    <p className="sublabel">Allows the user to upload files to the folder.</p>
                    <input type="checkbox" checked={props.writePermission} onChange={() => props.setWritePermission(!props.writePermission)}  />
                    <span className="checkmark"></span>
                </label>
            </ListGroup.Item>
            <ListGroup.Item>
                <label className='CheckContainer' style={{margin: '0', fontSize: 'small'}}>Download
                    <p className="sublabel">Allows the user to download files in the folder and export their OCR results.</p>
                    <input type="checkbox" checked={props.downloadPermission} onChange={() => props.setDownloadPermission(!props.downloadPermission)} />
                    <span className="checkmark"></span>
                </label>
            </ListGroup.Item>
            <ListGroup.Item>
                <label className='CheckContainer' style={{margin: '0', fontSize: 'small'}}>Delete
                    <p className="sublabel">Allows the user to delete files.</p>
                    <input type="checkbox" checked={props.deletePermission} onChange={() => props.setDeletePermission(!props.deletePermission)} />
                    <span className="checkmark"></span>
                </label>
            </ListGroup.Item>
            <ListGroup.Item>
                <label className='CheckContainer' style={{margin: '0', fontSize: 'small'}}>Admin
                    <p className="sublabel">Allows the user to edit the permission of this folder (just like this dialog).</p>
                    <input type="checkbox" checked={props.editPermissionsPermission} onChange={() => props.setEditPermissionsPermission(!props.editPermissionsPermission)} />
                    <span className="checkmark"></span>
                </label>
            </ListGroup.Item>
        </ListGroup>
    )
}
