import React, { Component } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';

// Styles
// main css
import '../assets/css/components/app.css';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

// Routes Auth and Protect
import { AuthRoute, ProtectedRoute } from '../util/route_util';

/*************
 * Containers*
 * ***********
 */

// Home page
import HomePage from './home_page/home_page';
// import HomePageContainer from './home_page/home_page_container';

// Sessions
import SessionPageContainer from './session_form/session_page_container'; // new changes
import EmailSentContainer from './session_form/email_sent_container';
import VerifyEmailContainer from './session_form/verify_email_container';
import VerifyThirdPartyLogin from './session_form/verify_third_party_login_container';

// Users
import UpdatePasswordContainer from './account/update_password_container';
import DashboardContainer from './dashboard/dashboard_container.js';
const UserProfile_container = React.lazy(/* webpackPrefetch: true */ () => import('./UserProfile/UserProfile_container'));

// // Admin control
const AdminIndexContainer = React.lazy(/* webpackPrefetch: true */ () => import('./admin/request_index_container'));
const AdminCheckUserDetailsView = React.lazy(/* webpackPrefetch: true */ () => import('./admin/AdminCheckUserDetailsView'));

// Search Feature
import SearchContainer from './search/search_container';

// Invoice Generater 
const InvoiceIndex = React.lazy(/* webpackPrefetch: true */ () => import('./invoice/request_index_container'));

// Notifications
import NotificationCenterContainer from './notifications/NotificationCenter_container';
import NotificationToastListContainer from './notifications/NotificationToastList_container';
import SendNotificationView from './notifications/SendNotificationView';
const NotificationPreferencesView = React.lazy(/* webpackPrefetch: true */ () => import('./notifications/NotificationPreferencesView'));

// Sidebar
import Sidebar from '../components/sidebar/sidebar_container';
// Navbar
import Navbar from '../components/navbar/navbar_container';
// file box
import FileBoxContainer from '../components/FileBoX/filebox_container';
import FileboxTokenContainer from '../components/FileBoX/filebox_token_container'

//Setting
const FolderSettingContainer = React.lazy(/* webpackPrefetch: true */ () => import('./FolderSetting/FolderSetting_container'));
import AcceptInvitation from './session_form/accept_invitation_container';
const TicketsMainView = React.lazy(/* webpackPrefetch: true */ () => import('./Tickets/TicketsMainView'));
const CreateTicketView = React.lazy(/* webpackPrefetch: true */ () => import('./Tickets/CreateTicketView'));
const TicketView = React.lazy(/* webpackPrefetch: true */ () => import('./Tickets/TicketView_container'));

// stripe payment
import AccountDetails from './payment/AccountDetails';
import Cancel from './payment/Cancel';
import Prices from './payment/Prices';
import ReActivate from './payment/ReActivate';
import Subscribe from './payment/Subscribe';

// Free try
import FreeTry from '../components/FreeTry/FreeTryContainer';

// Summary for usage
const Report = React.lazy(/* webpackPrefetch: true */ () => import('./Report/Report_container'));

class App extends Component{
  constructor(props){
    super(props);
  }

  componentDidMount(){
  }

  componentWillMount(){
  }

  componentWillReceiveProps(nextProps){
  }

  render() {
    return (
      <Route>
        <Container fluid>
          <NotificationToastListContainer />
          <NotificationCenterContainer />
          <Row>
            <div className='SideBar'>
              <Sidebar />
            </div>
            <div className='ParentApp' id="ParentApp">
              <Navbar />
              <div className='MainContent'>
                <React.Suspense fallback={<div>Loading...</div>}>
                  <Switch>
                    {/* <AuthRoute path="/login" component={LoginFormContainer} />
                    <AuthRoute path="/register" component={RegisterFormContainer} />
                    <AuthRoute path="/forgot-password" component={ForgotPasswordContainer} /> */}
                    <AuthRoute path="/auth" component={SessionPageContainer} />
                    <Route exact path="/email-sent" component={EmailSentContainer} />
                    <Route exact path="/verify-email" component={VerifyEmailContainer} />
                    <Route path="/verify-third-party-login" component={VerifyThirdPartyLogin} />

                    <Route exact path="/accept-invite" component={AcceptInvitation} />

                    {/* <Route path="/invoice" component={InvoiceIndex} />    */}
                    <Route exact path="/invoice/:param?" component={InvoiceIndex} />   
                    {/* <ProtectedRoute exact path="/tsf/:param?" component={fml} /> */}
                    {/* <ProtectedRoute exact path="/shippingdoc/:param?" component={shippingdoc} /> */}
                    <ProtectedRoute path="/home" component={FileBoxContainer} />
                    <ProtectedRoute path="/shared" component={FileBoxContainer} />
                    <ProtectedRoute path="/trash" component={FileBoxContainer} />
                    <ProtectedRoute path="/starred" component={FileBoxContainer} />
                    <ProtectedRoute path="/search" component={FileBoxContainer} />
                    <ProtectedRoute path="/recent" component={FileBoxContainer} />
                    <ProtectedRoute path="/folder/:param?" component={FileBoxContainer} />
                    <ProtectedRoute path="/foldersetting/:param?" component={FolderSettingContainer} />
                    {/* <Route path="/pay" exact>
                      <PaymentView />
                    </Route> */}
                    {/* <Route exact path="/payment-Register">
                      <Register />
                    </Route> */}
                    <ProtectedRoute path="/prices" component={Prices} />
                    <ProtectedRoute path="/subscribe" component={Subscribe} />
                    <ProtectedRoute path="/account-details" component={AccountDetails} />
                    <ProtectedRoute path="/cancel" component={Cancel} />
                    <ProtectedRoute path="/re-active" component={ReActivate} />
                    {/* <Route path="/prices">
                      <Prices />
                    </Route>
                    <Route path="/subscribe">
                      <Subscribe />
                    </Route>
                    <Route path="/account-details">
                      <AccountDetails />
                    </Route>
                    <Route path="/cancel">
                      <Cancel />
                    </Route>
                    <Route path="/re-active">
                      <ReActivate />
                    </Route> */}
                    
                    <ProtectedRoute exact path="/update-password" component={UpdatePasswordContainer} />
                    <ProtectedRoute exact path="/dashboard" component={DashboardContainer} />
                    <ProtectedRoute exact path="/userprofile" component={UserProfile_container} />
                    <ProtectedRoute exact path="/" component={HomePage} />

                    {/* <ProtectedRoute exact path="/requests" component={RequestIndexContainer} /> */}
                    {/* <ProtectedRoute exact path="/invoice" component={InvoiceIndex} /> */}
                    {/* <ProtectedRoute exact path="/requests2" component={Request2IndexContainer} /> */}
                    {/* <ProtectedRoute exact path="/requests/:userid/:id" component={Request3IndexContainer} /> */}
                    <ProtectedRoute path="/admin" component={AdminIndexContainer} />
                    <ProtectedRoute path="/user-details/:id" component={AdminCheckUserDetailsView} />
                    <ProtectedRoute path="/sendCustomNotification" component={SendNotificationView} />
                    <ProtectedRoute path="/notificationPreferences" component={NotificationPreferencesView} />
                    {/* <ProtectedRoute exact path="/labelling" component={labeling} /> */}

                    <ProtectedRoute path="/search" component={SearchContainer} />

                    <ProtectedRoute path="/tickets/new" component={CreateTicketView} />
                    <ProtectedRoute path="/tickets/:id" component={TicketView} />
                    <ProtectedRoute path="/tickets" component={TicketsMainView} />

                    {/* Summary */}
                    <ProtectedRoute path="/report" component={Report} />

                    {/* <ProtectedRoute exact path="/admin_control" component={AdminControl} /> */}

                    {/* Token routes */}
                    <Route exact path="/share/:token/folder/:param" component={FileboxTokenContainer(false)} />
                    <Route exact path="/share/:token/file/:param" component={FileboxTokenContainer(true)} />
                    
                    {/* Free try here */}
                    <Route exact path="/freetry" component={FreeTry} />

                    {/* Default Route */}
                    <Route render={() => <Redirect to={{pathname: "auth/login"}} />} />
                    {/* Don't add any routes below this point */}
                  </Switch>
                </React.Suspense>
              </div>
            </div>
          </Row>
        </Container>
      </Route>
    )
  }
}
export default withRouter(App);