import React from 'react';

import { withRouter } from 'react-router-dom';

import { uniqueId } from '../../helpers/id_generator';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import Popup from '../Popup';

import './style.css';

class UpdatePassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      password1: "",
      password2: "",
      errors: [],
      success: ""
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    // update-password only apply to local users, not for third party login users
    if (nextProps.loggedIn && nextProps.currentUser.loginProvider) 
      this.props.history.push('/dashboard');
  }

  componentWillMount() {
    // update-password only apply to local users, not for third party login users
    if (this.props.loggedIn && this.props.currentUser && this.props.currentUser.loginProvider)
      this.props.history.push('/dashboard'); 
  }

  
  validateForm() {
    let {resetedPassword} = this.props.currentUser;
    if (resetedPassword){
      return  this.state.password1.length >= 8 &&
              this.state.password2.length >= 8 &&
              this.state.password.length >= 8 &&
              this.state.password1 === this.state.password2;
    } else {
      return  this.state.password1.length >= 8 &&
              this.state.password2.length >= 8 &&
              this.state.password1 === this.state.password2;
    }
  }

  update(type) {
    return e =>
      this.setState({
        [type]: e.currentTarget.value
      });
  }

  displayError() {
    const errors = this.state.errors.length > 0 ? this.state.errors : this.props.errors;
    if (errors.length > 0) {
      return (
        <div className="login-error">
        { 
          errors.map((error, idx) => {
            return (
              <span key={idx + uniqueId()}>{error}</span>
            )
          })
        }
        </div>
      );
    }
  }

  displaysuccess() {
    if (this.state.success.length > 0) {
      return (
        <div className="login-success">
          <span>{this.state.success}</span>
        </div>
      );
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    const data = { password: this.state.password,password1: this.state.password1, password2: this.state.password2 };
    this.props.updatePassword(data)
      .then((user) =>{this.setState({success: 'Update password succeed, redirect ...'}, ()=>{
        setTimeout(()=>{this.props.history.push('/dashboard')}, 1000
        );
      });})
      .catch((err)=> this.setState({errors: [err.responseText]}));
  }

  handleLogout(e) {
    e.preventDefault();
    this.props.logout();
    this.props.history.push('/');
  }

  render() {
    let {resetedPassword} = this.props.currentUser;
    return (
      // <div className="update-password">
      <Popup show={true}
            handleClose={() => { this.props.history.goBack(); }}
            size='small' closeButtonLoc='TopRight'>
        <div style={{padding: '50px'}}>
        <h4 style={{marginBottom: '1.5rem'}}>Choose your password</h4>
        <Form onSubmit={this.handleSubmit} className='update-password-container'>
          {resetedPassword?
            <Form.Group className="mb-3">
              <Form.Label>Current Password:</Form.Label>
              <Form.Control autoFocus type="password" value={this.state.password} 
                            onChange={this.update('password')} />
            </Form.Group>: null
          }
          <Form.Group className="mb-3">
            <Form.Label>New Password:</Form.Label>
            <Form.Control autoFocus type="password" value={this.state.password1} 
                          onChange={this.update('password1')} />
            <Form.Text className="text-muted">
              Password must be at least 8 length and contain 1 upper-case letter, 1 symbol, and 1 number.
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Confirm Password:</Form.Label>
            <Form.Control type="password" value={this.state.password2} 
                          onChange={this.update('password2')} />
          </Form.Group>
          {this.displayError()}
          {this.displaysuccess()}
          <Button disabled={!this.validateForm()} type="submit">
            Update Password
          </Button>
        </Form>
        </div>
      </Popup>
      // </div>
    );
  }
}

export default withRouter(UpdatePassword);
