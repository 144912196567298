import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FileDrop from './FileDrop'

const mapStateToProps = (state, ownProps) => {
    return Object.assign({}, { rateLimit: state.entities.rateLimit }, ownProps);
}

const mapDispatchToProps = (dispatch) => {}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FileDrop));