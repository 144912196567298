// Action types
import {
  OPEN_NOTIFICATION_CENTER,
  CLOSE_NOTIFICATION_CENTER,
  SWITCH_NOTIFICATION_CENTER,
  RECEIVED_NOTIFICATION,
  FETCH_ALL_NOTIFICATIONS,
  SET_NOTIFICATION_AS_READ,
  FETCH_UNREAD_COUNT,
  RECEIVE_NOTIFICATION_ERRORS
} from '../helpers/action_types';

const initialState = {
  count: 0,
  notifications: [],
  latestFetchID: "", // Allows components that receive new notifications to know when the fetch happened
  error: {},
  openNotificationCenter: false
}

const NotificationsReducer = (state = initialState, action) => {
  const cloneState = Object.assign({}, state);

  switch (action.type) {
    case OPEN_NOTIFICATION_CENTER:
      cloneState.openNotificationCenter = true;
      return cloneState;
    case CLOSE_NOTIFICATION_CENTER:
      cloneState.openNotificationCenter = false;
      return cloneState;
    case SWITCH_NOTIFICATION_CENTER:
      cloneState.openNotificationCenter = !cloneState.openNotificationCenter;
      return cloneState;
    case RECEIVED_NOTIFICATION:
      cloneState.notifications = [action.notification, ...state.notifications];
      cloneState.count++;
      return cloneState;
    case FETCH_UNREAD_COUNT:
      cloneState.count = action.count;
      return cloneState;
    case FETCH_ALL_NOTIFICATIONS:
      cloneState.notifications = [...action.notifications];
      cloneState.latestFetchID = action.notifications[0].id;
      return cloneState;
    case SET_NOTIFICATION_AS_READ:
      console.log(action)
      // Deep copy of the updated state
      const newNotificationsState = cloneState.notifications.map(val => {
        if (action.id === val.id) {
          val.read = true;
        }
        return val;
      })
      cloneState.notifications = newNotificationsState;
      cloneState.count--;
      return cloneState;
    default:
      return state;
  }
};

export default NotificationsReducer;
