import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { acceptInvitation, getInviteData } from '../../actions/user_actions';
import * as queryString from 'query-string';

import AcceptInvitation from './accept_invitation';

const mapStateToProps = (state, ownProps) => {
  const params = queryString.parse(ownProps.location.search)
  
  const loggedIn = Boolean(state.session.currentUser);
  
  const invite_id = params.invite;
  const user_id = params.uid;

  const invited_by_name = (state.entities.user.invite_data) ? state.entities.user.invite_data.invited_by_name : null;
  const invited_to_company = (state.entities.user.invite_data) ? state.entities.user.invite_data.invited_to_company : null;
  const user_email = (state.entities.user.invite_data) ? state.entities.user.invite_data.invited_email : null;
  const user_token = (state.entities.user.invite_data) ? state.entities.user.invite_data.authToken : null;
  
  return Object.assign(
    {}, {
      loggedIn,
      invite_id,
      user_id,
      invited_to_company,
      invited_by_name,
      user_email, 
      user_token,
    },
    ownProps
  );
};

const mapDispatchToProps = (dispatch, { location }) => {
    return {
      acceptInvite: (invite_id, user_id) => dispatch(acceptInvitation({invite_id: invite_id, user_id: user_id}, "/invites/acceptInvite")),
      fetchInviteData: (invite_id, user_id) => dispatch(getInviteData({invite_id: invite_id, user_id: user_id}, "/invites/getInviteData")),
    };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AcceptInvitation)
);
