import { combineReducers } from 'redux';

// Reducers
import UserReducer from './user_reducer';
import RequestReducer from './requests_reducer';
import ReqCountReducer from './ReqCount_reducer';
import RateLimitReducer from './RateLimit_reducer';
import admin_reducer from './admin_reducer';
import search_reducer from './search_reducer';

import InvoiceGenReducer from './InvoiceGen_reducer';
import ChooseALanguageReducer from './Language_reducer';
import Loading_status from './Loading_reducer';
import DownloadProgress from './DownloadProgress_reducer';


const entitiesReducer = combineReducers({
  user: UserReducer,
  rateLimit: RateLimitReducer,
  AllPath: RequestReducer,
  Search: search_reducer,
  ReqCount: ReqCountReducer,
  AdminManage: admin_reducer,
  InvoiceGen: InvoiceGenReducer,
  Language: ChooseALanguageReducer,
  Loading: Loading_status,
  DownloadProgress: DownloadProgress,
});

export default entitiesReducer;
