import {
  // RECEIVE_CURRENT_USER,
  READ_CURRENT_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD_USER,
  RECEIVE_SESSION_ERRORS,
  UPDATE_CURRENT_USER,
  RECEIVE_INVITE_USER,
  RECEIVE_INVITE_DATA,
  RECEIVE_INVITATION_CONFIRMATION,
  RECEIVE_SENT_INVITATIONS,
  UPDATE_KEY,
} from '../helpers/action_types';

const _initialState = {};

const usersReducer = (state = _initialState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case READ_CURRENT_USER:
      const currentUser  = action.user.user;
      return Object.assign({}, _initialState, currentUser);

    case FORGOT_PASSWORD_USER:
      localStorage.setItem('email-sent', action.user.email);
      return _initialState;

    case LOGOUT_USER:
      return _initialState;

    case UPDATE_CURRENT_USER:
      const updatedUser = action.user.user;      
      return updatedUser;

    case UPDATE_KEY: 
      // const user_key = action.user.apiKey;
      // var unfreezed_user = {};

      // for (var property in state){
      //   unfreezed_user[property] = state[property];
      // }

      // unfreezed_user.apiKey = user_key;
      // return unfreezed_user;

      console.log(action)
      return Object.assign({}, state, {apiKey: action.user.user.apiKey});

    case RECEIVE_INVITE_USER:
      return Object.assign({}, state, {invites_sent: action.data.response});

    case RECEIVE_INVITE_DATA:
      console.log(action)
      return Object.assign({}, state, {invite_data: action.data.response});

    case RECEIVE_INVITATION_CONFIRMATION:
      return Object.assign({}, state, {invite_confirmation: action.data.response});

    case RECEIVE_SENT_INVITATIONS:
      return Object.assign({}, state, {sent_invitations: action.data.invites});

    default:
      return state;
  }
};

export default usersReducer;
