import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import { RiSuitcaseFill } from '@react-icons/all-files/ri/RiSuitcaseFill';
import { GoPerson } from '@react-icons/all-files/go/GoPerson';
// import { RegisterUser } from '../../services/UsersService';

const SocialAccountCreation = (props) => {
    const [name, setName] = useState(props.name);
    const [company, setCompany] = useState("");
    const [error, setError] = useState("");
    const [individual, setIndividual] = useState(true);
    const [termsConditions, setTermsConditions] = useState(false);

    const validateFields = () => {
        return (
            name.length >= 1 && // Check name
            (individual || company.length >= 1) && // Check company name (if aplicable)
            termsConditions
        )
    }

    const register = async () => {
        if (validateFields()) {
            try {
                await props.signup({
                    name,
                    company,
                    individual,
                    token: props.auth.tokenId
                })
                await props.googleLogin(props.auth.tokenId)
            } catch (err) {
                setError(err.responseText);
            }
        } else {
            setError("Please enter all fields correctly.")
        }
    }
    
    return (
        <div>
            <h6>Account type</h6>

            {/* <div className="register-box-wrapper">
                <div
                    className={`register-box individual-box ${individual ? 'register-box-selected' : ''}`}
                    onClick={() => setIndividual(true)}>
                    <GoPerson style={{fontSize: '2.8em'}}/>
                    <h6>Personal</h6>
                </div>
                <div
                    className={`register-box enterprise-box ${!individual ? 'register-box-selected' : ''}`}
                    onClick={() => setIndividual(false)}>
                    <RiSuitcaseFill style={{fontSize: '2.8em'}}/>
                    <h6>Business</h6>
                </div>
            </div> */}
            <div className="register-box-wrapper">
                <div className={`register-box individual-box ${individual ? 'register-box-selected' : ''}`}
                    onClick={() => setIndividual(true)}>
                    <GoPerson style={{fontSize: '2.8em'}}/>
                    <h6>Personal</h6>
                    {/* <span>Manage files for yourself and share them with other individuals.</span> */}
                </div>
                <div className={`register-box enterprise-box ${!individual ? 'register-box-selected' : ''}`}
                    onClick={() => setIndividual(false)}>
                    <RiSuitcaseFill style={{fontSize: '2.8em'}}/>
                    <h6>Business</h6>
                    {/* <span>Manage files for your business and set up accounts for your team.</span> */}
                </div>
            </div>

            <h5>Account information:</h5>

            <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter name"
                    value={name}
                    onChange={e => setName(e.target.value)} />
            </Form.Group>

            { !individual && <Form.Group>
                <Form.Label>Company Name</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter company name"
                    value={company}
                    onChange={e => setCompany(e.target.value)} />
            </Form.Group>}

            <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                    type="email"
                    value={props.email}
                    disabled />
            </Form.Group>

            <Form.Group>
                <Form.Check type="checkbox" >
                    <Form.Check.Input type="checkbox" value={termsConditions} onClick={() => setTermsConditions(!termsConditions)} />
                    <Form.Check.Label>By checking this box, you agree with <NavLink to="/auth/terms_conditions" target="_blank">our Terms and Conditions</NavLink>.</Form.Check.Label>
                </Form.Check>
            </Form.Group>

            <button type="button" onClick={register} className="btn btn-dark btn-lg btn-block">Register</button>

            <p className="forgot-password text-right">
                Already registered? <NavLink to="/auth/login">Login instead</NavLink>
            </p>

            {error && <p>
             <div className="login-error">
                 <h6>{error}</h6>
             </div>
           </p>}
        </div>
    );
}

export default SocialAccountCreation;
