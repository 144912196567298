export const PermissionTypes = {
    READ: 0b1,
    WRITE: 0b10,
    DOWNLOAD: 0b100,
    DELETE: 0b1000,
    EDIT_PERMISSIONS: 0b10000
};

export const toPermissionObject = number => ({
    read: (number & 0b1) !== 0,
    write: (number & 0b10) !== 0,
    download: (number & 0b100) !== 0,
    delete: (number & 0b1000) !== 0,
    editPermissions: (number & 0b10000) !== 0
})

export const toPermissionBinary = obj => (
    (obj.read ? 0b1 : 0) +
    (obj.write ? 0b10 : 0) +
    (obj.download ? 0b100 : 0) +
    (obj.delete ? 0b1000 : 0) +
    (obj.editPermissions ? 0b10000 : 0)
)

export const toText = num => {
    if (num === 0) return 'None';
    const obj = toPermissionObject(num);
    return (
        (obj.read ? 'R ' : '') +
        (obj.write ? 'W ' : '') +
        (obj.download ? 'Dl ' : '') +
        (obj.delete ? 'De ' : '') +
        (obj.editPermissions ? 'E' : '')
    )
}

/**
 * Checks if, for a certain permission number, the user has a certain permission
 * @param {Number} permissionRaw The number representing the permissions
 * @param {PermissionTypes} permissionTypes A permission type
 */
export const checkPermission = (permissionRaw, permissionTypes) => {
    return !!(permissionRaw & permissionTypes);
}

/**
 * Converts permission objects that came from a folder, to the default DTO
 */
export const fromFolderToDTO = permissionObj => ({
    id: permissionObj.id,
    user: permissionObj.user,
    role: permissionObj.role,
    permission: permissionObj.permission,
    folderID: permissionObj.FolderFolderID,
    fileID: permissionObj.FileInFolderFileID,
    protected: permissionObj.protected
})