import React, { useRef, useEffect } from 'react'
import { ListGroup } from 'react-bootstrap';
import NotificationItem from './NotificationItem'

import {translate} from '../../helpers/translate';


export default function NotificationCenter(props) {
    const dropDownRef = useRef(null);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [props.notifications.openNotificationCenter]);

    const handleClickOutside = (e) => {
        if (props.notifications.openNotificationCenter && dropDownRef && dropDownRef.current) {
            if (!dropDownRef.current.contains(e.target)) {
                props.closeNotificationCenter()
            }
        }
    }

    return (
        <div
            ref={dropDownRef}
            aria-live="polite"
            aria-atomic="true"
            className="notificationCenter"
            hidden={!props.notifications.openNotificationCenter}>
            <div className="notificationCenterPos">
                <ListGroup>
                    <ListGroup.Item style={{position: 'sticky', top: '0px', zIndex: '2'}} >
                        <span class="bold">{translate('Notifications')}</span>
                    </ListGroup.Item>
                    { props.notifications.notifications.length > 0 ?
                        props.notifications.notifications.map((notification, i) => (
                            <NotificationItem notification={notification} index={i} setAsRead={props.setNotificationAsRead} />
                        ))
                        : <ListGroup.Item><span style={{color: 'darkgray'}}>There are no new notifications!</span></ListGroup.Item>
                    }
                </ListGroup>
            </div>
        </div>
    )
}
