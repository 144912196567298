import React, { useState, useEffect } from 'react'
import { Form, Button, Container, Row, Col, ButtonGroup } from 'react-bootstrap'
import { toPermissionObject, toPermissionBinary, toText } from '../permissionConverter'
import '../permissionComponents.css'
import PermissionOptions from './PermissionOptions';
import {MdPerson} from '@react-icons/all-files/md/MdPerson' ;
import {MdPeople} from '@react-icons/all-files/md/MdPeople' ;
import { MdModeEdit } from '@react-icons/all-files/md/MdModeEdit';
import { MdRemoveCircle } from '@react-icons/all-files/md/MdRemoveCircle';

const roles = ['Manager', 'Operator'];

export default function PermissionsEditor({ currentUser, permission, updatePermission, removePermission }) {
    const canAssignRole = !!currentUser.companyUid;
    const [notify, setNotify] = useState(!permission.id);
    const [accessToRole, setAccessToRole] = useState(false);
    const [roleAccess, setRoleAccess] = useState(roles[0]);
    const [userAccess, setUserAccess] = useState('');
    const [readPermission, setReadPermission] = useState(false);
    const [writePermission, setWritePermission] = useState(false);
    const [downloadPermission, setDownloadPermission] = useState(false);
    const [deletePermission, setDeletePermission] = useState(false);
    const [editPermissionsPermission, setEditPermissionsPermission] = useState(false);
    const [SelectPerm, setSelectPerm] = useState(false);

    useEffect(() => {
        setAccessToRole(permission.role !== null);
        setRoleAccess(permission.role !== null ? permission.role : roles[0]);
        setUserAccess(permission.user !== null ? permission.user : '');
        const convertedPermissions = toPermissionObject(permission.permission);
        setReadPermission(convertedPermissions.read);
        setWritePermission(convertedPermissions.write);
        setDownloadPermission(convertedPermissions.download);
        setDeletePermission(convertedPermissions.delete);
        setEditPermissionsPermission(convertedPermissions.editPermissions);
    }, [permission])

    const accessesField = accessToRole ? (
        <Form.Control onChange={(e) => setRoleAccess(e.target.value)} as="select">
            {roles.map(val => <option selected={roleAccess === val} value={val}>{val}</option>)}
        </Form.Control>
    ) : (
        <Form.Control value={userAccess} onChange={(e) => setUserAccess(e.target.value)} type="email" placeholder="example@learningpal.com" />
    );

    const currentPermissionsBinary = () => {
        return toPermissionBinary({
            read: readPermission,
            write: writePermission,
            download: downloadPermission,
            delete: deletePermission,
            editPermissions: editPermissionsPermission
        });
    }

    const hasChanged = () => {
        const permissionBinary = currentPermissionsBinary();

        return (
            // Check if the user now has access to the role, and if it changed
            (accessToRole !== (permission.role !== null) ? roleAccess !== permission.role : false) ||
            // Check if the user has changed
            (!accessToRole ? userAccess !== permission.user : false) ||
            // Check if the permission changed
            permission.permission !== permissionBinary
        );
    }

    const stateToObject = () => ({
        id: permission.id,
        user: !accessToRole ? userAccess : null,
        role: accessToRole ? roleAccess : null,
        token: null,
        permission: currentPermissionsBinary(),
        notify: !permission.id && currentPermissionsBinary() > 0 ? notify : undefined 
    })

    const handleUpdatePermission = () => {
        updatePermission(stateToObject());
        setSelectPerm(false);
    }

    return (
        <Container>
            <Row className="UserGroupPermission">
                <Col ms={4} style={{display: 'flex', alignItems:'center', position: 'relative'}}>
                    {!permission.id ?<div className='NewPerm'>NEW</div>:null}
                    {accessToRole?
                        <div><MdPeople/>{permission.role} <span style={{color: '#aaa'}}>({toText(permission.permission)})</span></div>:
                        <div><MdPerson/>{permission.user} <span style={{color: '#aaa'}}>({toText(permission.permission)})</span></div>
                    }
                </Col>
                <Col>
                    <div style={{marginLeft: 'auto', float: 'right'}}>
                        {SelectPerm? 
                            <div>
                                <ButtonGroup>
                                    <Button size="sm" variant="primary" onClick={handleUpdatePermission} disabled={hasChanged()?false:true}>Confirm</Button>
                                    <Button size="sm" variant="outline-primary" onClick={()=>{setSelectPerm(!SelectPerm)}}>Cancel</Button>
                                </ButtonGroup>
                                <PermissionOptions
                                readPermission={readPermission} setReadPermission={setReadPermission}
                                writePermission={writePermission} setWritePermission={setWritePermission}
                                downloadPermission={downloadPermission} setDownloadPermission={setDownloadPermission}
                                deletePermission={deletePermission} setDeletePermission={setDeletePermission}
                                editPermissionsPermission={editPermissionsPermission} setEditPermissionsPermission={setEditPermissionsPermission}
                                />
                            </div>:
                            permission.protected?
                                <Button size="sm" variant="outline-primary" disabled={true}>Owner</Button>
                            :
                                <ButtonGroup>
                                    <Button size="sm" variant="primary" onClick={()=>{setSelectPerm(!SelectPerm)}} disabled={permission.protected}>
                                        <MdModeEdit />
                                    </Button>
                                    <Button size="sm" onClick={() => removePermission(stateToObject())} variant="danger">
                                        <MdRemoveCircle />
                                    </Button>
                                </ButtonGroup>
                        }
                        </div>
                </Col>
            </Row>
        </Container>
        // <div>
        //     <Form.Group>
        //         {/* Targets */}
        //         <Form.Label>Give access to...</Form.Label>
        //         <br />
        //         { canAssignRole ?
        //             <div>
        //                 <Form.Check inline checked={accessToRole} onChange={() => setAccessToRole(true)} label="Specific Role" type="radio" />
        //                 <Form.Check inline checked={!accessToRole} onChange={() => setAccessToRole(false)} label="Specific User" type="radio" />
        //             </div>: '' }
        //         <br />
        //         { accessesField }
        //         {/* If it's a new permission, allow the user to notify */}
        //         { !permission.id ?
        //             <Form.Check type="checkbox" disabled={currentPermissionsBinary() === 0} label="Notify people" className="renderCheckbox notifyCheckbox" checked={notify} onChange={() => setNotify(!notify)} />
        //             : '' }
        //         <hr />
        //         {/* Permissions */}
        //         <PermissionOptions
        //             readPermission={readPermission} setReadPermission={setReadPermission}
        //             writePermission={writePermission} setWritePermission={setWritePermission}
        //             downloadPermission={downloadPermission} setDownloadPermission={setDownloadPermission}
        //             deletePermission={deletePermission} setDeletePermission={setDeletePermission}
        //             editPermissionsPermission={editPermissionsPermission} setEditPermissionsPermission={setEditPermissionsPermission}
        //         />
        //         { hasChanged() ? <Button onClick={() => updatePermission(stateToObject())} variant="success">Update Permission</Button> : '' }
        //         <Button onClick={() => removePermission(stateToObject())} variant="light">Delete Permission</Button>
        //     </Form.Group>
        // </div>
    )
}