import React from 'react';
import ReactDOM from 'react-dom';

//Components
import Root from './components/root';
import configureStore from './store/store';
import { localStorageLogin } from './actions/session_actions';

// Utils
import * as UserUtil from './util/user_api_util';
import * as RequestUtil from './util/request_util';

document.addEventListener('DOMContentLoaded', () => {
  let store = configureStore();

  try {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    const token = localStorage.getItem('token');
    
    if (currentUser && token) {
      localStorageLogin(currentUser, token)(store.dispatch);
    }
  } catch (e) {
    console.log('Something is wrong with the local storage.')
  }

  window.UserUtil = UserUtil;
  window.RequestUtil = RequestUtil;

  window.store = store;
  const root = document.getElementById('root');

  // const CreateRoot = ReactDOM.createRoot(root);
  // CreateRoot.render(<Root store={store} />);
  ReactDOM.render(
    <Root store={store} />, root
  );
}); 
