function string2csv(input, delimiter='||'){
    let output = [];
    if (!input){
        return output
    }
    let rowList = input.split('\n');
    for (let i=0; i < rowList.length; i++){
        let colList = rowList[i].split(delimiter)
        output.push(colList)
    }
    return output
}

function toColumnName(num){
    // Convert number to excel Column name
    for (var ret = '', a = 1, b = 26; (num -= a) >= 0; a = b, b *= 26) {
        ret = String.fromCharCode(parseInt((num % b) / a) + 65) + ret;
    }
    return ret;
}

function ColumnNameToNumber(letters){
    for(var p = 0, n = 0; p < letters.length; p++){
        n = letters[p].charCodeAt() - 64 + n * 26;
    }
    return n;
}

module.exports = {
    string2csv,
    toColumnName,
    ColumnNameToNumber
}