import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ProgressBar from 'react-bootstrap/ProgressBar';

import { bytesToSize } from '../../helpers/others';
import { formatted_date } from '../../helpers/date_format';
import { mimeIconMap } from '../../helpers/IconHelper';

import {MdArrowDropDown} from '@react-icons/all-files/md/MdArrowDropDown';
import {MdArrowDropUp} from '@react-icons/all-files/md/MdArrowDropUp';

import { CSVLink, CSVDownload } from "react-csv";

import './style.css';

const MoreInfoView = ({FileInfo, PageInfo, CurrentUser}) => {
    const [ShowAll, setShowAll] = useState(false);
    const [HideCorrect, setHideCorrect] = useState(true);
    const [ChooseKeyname, setChooseKeyname] = useState(false);
    
    let getPercentage = (x, y) => {
        return y === 0? 0 : x / y * 100
    }

    // Variables filled if PageInfo exists
    let total_field_types;
    let all_pairs;
    
    if (Array.isArray(PageInfo[0])){
        PageInfo = PageInfo[0]
    }
    // PageInfo might be unknown if the document is still processing
    if (PageInfo.length) {
        total_field_types = (PageInfo[0]['ky_pairs']||[]).map(x => x.type);
        total_field_types = Array.from(new Set(total_field_types));

        let pages_pairs = PageInfo.some(x=>x.ky_pairs)?PageInfo.map(x => x.ky_pairs):[];
        all_pairs = pages_pairs.flat();
    }

    
    let non_empty_pairs, all_warnings, all_warning_corrected, all_FalsePositive, all_FalseAlarm;

    const GetData = (Keyname) => {
        let chosenPairs;
        if (!Keyname){
            chosenPairs = all_pairs;
        } else {
            chosenPairs = all_pairs.filter(x => x.type === Keyname);
        };
        // non_empty_pairs = chosenPairs.filter(x => !(x.value_txt === '' && x.value_conf=== -1) && !(x.value_txt === 'H' && x.value_conf >= 99));
        non_empty_pairs = chosenPairs.filter(x => !((x.value_conf===99 || x.value_conf===-1) && (x.value_txt === '')));
        all_warnings = non_empty_pairs.filter(x => (x.value_conf_old===undefined?x.value_conf:x.value_conf_old) < x.sensitive );
        all_warning_corrected = all_warnings.filter(x => x.value_conf_old < x.sensitive && x.value_txt != x.value_txt_old);
        all_FalsePositive = non_empty_pairs.filter(x => Object.keys(x).includes('value_txt_old') && x.value_conf_old >= x.sensitive && x.value_txt != x.value_txt_old);
        all_FalseAlarm = all_warnings.filter(x => x.value_txt === x.value_txt_old);
        let overall_accuracy = `${Math.round((getPercentage(non_empty_pairs.length - all_FalsePositive.length - all_warning_corrected.length, non_empty_pairs.length)+Number.EPSILON) * 10)/10}%`
        return ['', non_empty_pairs.length, all_warnings.length, all_FalseAlarm.length,
            all_warning_corrected.length, all_FalsePositive.length, overall_accuracy ]
    }


    const GetCSV = () => {
        let csvData = [
            ['','All'].concat(total_field_types),
            ['Total Non-empty'],
            ['Warnings'],
            ['False Alarm'],
            ['Corrected warnings'],
            ['False positive'],
            ['Overall accuracy']
        ];
        let all_data = GetData(false);
        for (let i=1; i<all_data.length; i++){
            csvData[i] = csvData[i].concat([all_data[i]])
        }
        // csvData.push(all_data)
        for (let i=0; i<total_field_types.length; i++){
            let field_type = total_field_types[i];
            let current_data = GetData(field_type);
            for (let j=1; j<current_data.length; j++){
                csvData[j] = csvData[j].concat([current_data[j]])
            }
        }
        return csvData
    }
    GetData(ChooseKeyname);
    
    return (
        <div>
            <div className='InfoTitle'>
                <span>{FileInfo.title} Info</span>
            </div>
            <Row className="InfoRow">
                <Col>
                    <div className="GeneralInfo"><MdArrowDropDown/>General Info: </div>
                    <div>
                        <div className="InfoList cell-icon"><div id='ICON'>{mimeIconMap(FileInfo.title)}</div><span>{FileInfo.title}</span></div>
                        <div className="InfoList"><div>Created by:</div><span>{FileInfo.USER_ID === CurrentUser.id?'You':'Other user'}</span></div>
                        <div className="InfoList"><div>Created at:</div><span>{formatted_date(FileInfo.createdAt)}</span></div>
                        <div className="InfoList"><div>Last Modified:</div><span>{formatted_date(FileInfo.updatedAt)}</span></div>
                        <div className="InfoList"><div>Size:</div><span>{bytesToSize(FileInfo.size)}</span></div>
                        <div className="InfoList"><div>Total pages:</div><span>{PageInfo.length ? PageInfo.length : Unknown}</span></div>
                    </div>
                    <div className="GeneralInfo"><MdArrowDropDown/>More Info: </div>
                    <select onChange={(e)=>setChooseKeyname(e.target.value==='All'?false:e.target.value)}>
                        <option value="All">All</option>
                        {total_field_types.map(x => {return (<option value={x}>{x}</option>)})}
                    </select>
                    <div>
                        <div className="InfoList">
                            <div>All fields:</div><span>{all_pairs.length}</span>
                        </div>
                        <div className="InfoList">
                            <div>Non-empty fields:</div><span>{non_empty_pairs.length}</span>
                        </div>
                        <div className="InfoList">
                            <div>Warnings:</div>
                            <ProgressBar now={getPercentage(all_warnings.length, non_empty_pairs.length)} style={{padding: '0px'}}/>
                            <span>{`${Math.round((getPercentage(all_warnings.length, non_empty_pairs.length)+Number.EPSILON) * 10)/10}%`}
                                <span id='pullright'>{`${all_warnings.length} / ${non_empty_pairs.length}`}</span>
                            </span>
                        </div>
                        <div className="InfoList">
                            <div>False alarms:</div>
                            <ProgressBar now={getPercentage(all_FalseAlarm.length, non_empty_pairs.length)} style={{padding: '0px'}}/>
                            <span>{`${Math.round((getPercentage(all_FalseAlarm.length, non_empty_pairs.length)+Number.EPSILON) * 10)/10}%`}
                                <span id='pullright'>{`${all_FalseAlarm.length} / ${non_empty_pairs.length}`}</span>
                            </span>
                        </div>
                        <div className="InfoList">
                            <div>Corrected warnings:</div>
                            <ProgressBar now={getPercentage(all_warning_corrected.length, non_empty_pairs.length)} style={{padding: '0px'}}/>
                            <span>{`${Math.round((getPercentage(all_warning_corrected.length, non_empty_pairs.length)+Number.EPSILON) * 10)/10}%`}
                                <span id='pullright'>{`${all_warning_corrected.length} / ${non_empty_pairs.length}`}</span>
                            </span>
                        </div>
                        <div className="InfoList">
                            <div>False positive:</div>
                            <ProgressBar now={getPercentage(all_warning_corrected.length, non_empty_pairs.length)} style={{padding: '0px'}}/>
                            <span>{`${Math.round((getPercentage(all_FalsePositive.length, non_empty_pairs.length)+Number.EPSILON) * 10)/10}%`}
                                <span id='pullright'>{`${all_FalsePositive.length} / ${non_empty_pairs.length}`}</span>
                            </span>
                        </div>
                        <div className="InfoList">
                            <div>Overall Accuracy:</div>
                            <ProgressBar now={getPercentage((non_empty_pairs.length - all_FalsePositive.length - all_warning_corrected.length), non_empty_pairs.length)} style={{padding: '0px'}}/>
                            <span>{`${Math.round((getPercentage((non_empty_pairs.length - all_FalsePositive.length - all_warning_corrected.length), non_empty_pairs.length)+Number.EPSILON) * 10)/10}%`}
                                <span id='pullright'>{`${non_empty_pairs.length - all_FalsePositive.length - all_warning_corrected.length} / ${non_empty_pairs.length}`}</span>
                            </span>
                        </div>
                        <div className="InfoList">
                            <CSVLink data={GetCSV()} filename={`${FileInfo.title.split('.').slice(0, -1).join('.')}_report.csv`}>Export</CSVLink>
                        </div>
                    </div>
                </Col>
                <Col>
                    <div className="GeneralInfo"><MdArrowDropDown/>API Info: </div>
                    <div>
                        <div className="InfoList"><span>You can check out the API's documentation here: <b><a target="_blank" href="https://api.docupal.ai/api-docs">https://api.docupal.ai/api-docs</a></b>. For this file, use the following parameters when calling the API:</span></div>
                        <div className="InfoList"><div>fileId:</div><span>{FileInfo.FileID}</span></div>
                    </div>
                    <div className="GeneralInfo Clickable" onClick={()=>setShowAll(!ShowAll)}>{ShowAll?<MdArrowDropDown/>:<MdArrowDropUp/>}Show all: </div>
                    {ShowAll?
                    <Table style={{marginTop: '20px'}} striped bordered hover size="sm" className='ListTable'>
                        <div>
                            <div style={{whiteSpace: 'nowrap'}}><input type="checkbox" onChange={(e) => {setHideCorrect(!HideCorrect)}} checked={HideCorrect} />Hide unchanged</div>
                            {`Total: ${GetData(ChooseKeyname)[0]}`}
                        </div>
                        <tr>
                            <th>type</th>
                            <th>Field name</th>
                            <th>Result</th>
                            <th>Correction</th>
                            <th>Image</th>
                        </tr>
                        {
                            non_empty_pairs.map((x, idx)=>{
                                let old_conf = x.value_conf_old===undefined?x.value_conf:x.value_conf_old;
                                let color = old_conf >= x.sensitive?{backgroundColor: null}:old_conf>=x.sensitive/2?{backgroundColor:'rgb(239, 239, 124)'}:{backgroundColor: 'rgb(255, 132, 132)'};
                                if (HideCorrect && !Object.keys(x).includes('value_txt_old')){ return null}
                                else{
                                    return (
                                        <tr style={color}>
                                            <td style={{overflow: 'auto'}}>{x.type}</td>
                                            <td style={{overflow: 'auto'}}>{x.key_txt}</td>
                                            <td style={{overflow: 'auto'}}>{Object.keys(x).includes('value_txt_old')?x.value_txt_old:x.value_txt}</td>
                                            <td style={{overflow: 'auto'}}>{Object.keys(x).includes('value_txt_old')?x.value_txt:'N/A'}</td>
                                            <td style={{overflow: 'auto'}}><img style={{width:'64px'}} src={x.value_img}/></td>
                                        </tr>
                                    )
                                }
                            })
                        }
                    </Table>
                    :null}
                </Col>
            </Row>
        </div>
    )
};

export default MoreInfoView;