import React from 'react';

import { withRouter } from 'react-router-dom';

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import constants from '../../helpers/constants';

// icons
import { MdFolder } from '@react-icons/all-files/md/MdFolder';
import { MdFolderShared } from '@react-icons/all-files/md/MdFolderShared';
import { MdFileDownload } from '@react-icons/all-files/md/MdFileDownload' ;
import { VscTriangleRight } from '@react-icons/all-files/vsc/VscTriangleRight';
import { VscTriangleDown } from '@react-icons/all-files/vsc/VscTriangleDown';
import { MdVerifiedUser } from '@react-icons/all-files/md/MdVerifiedUser';
import { MdKeyboardArrowRight } from '@react-icons/all-files/md/MdKeyboardArrowRight';
import { MdAutorenew } from '@react-icons/all-files/md/MdAutorenew';
import { MdDoneAll } from '@react-icons/all-files/md/MdDoneAll';
import { FaFileSignature } from '@react-icons/all-files/fa/FaFileSignature';
import { FaFileExport } from '@react-icons/all-files/fa/FaFileExport';
import { IoMdRepeat } from '@react-icons/all-files/io/IoMdRepeat';
import { IoMdSettings } from '@react-icons/all-files/io/IoMdSettings';
import { GiWaxSeal } from '@react-icons/all-files/gi/GiWaxSeal';
import { TiWarning } from '@react-icons/all-files/ti/TiWarning';
import { BsFilterLeft } from '@react-icons/all-files/bs/BsFilterLeft';
import { MdMoreVert } from '@react-icons/all-files/md/MdMoreVert';
import { FaTrashAlt } from '@react-icons/all-files/fa/FaTrashAlt';
import { FaUndo } from '@react-icons/all-files/fa/FaUndo';
import { FaStar } from '@react-icons/all-files/fa/FaStar';
import { FaRegStar } from '@react-icons/all-files/fa/FaRegStar';
import { FaSearch } from '@react-icons/all-files/fa/FaSearch';
import { FaChevronLeft } from '@react-icons/all-files/fa/FaChevronLeft';
import { FaChevronRight } from '@react-icons/all-files/fa/FaChevronRight';

import { JSONDelete } from '../../services/routes';

import "babel-polyfill";

import { formatted_date } from '../../helpers/date_format';
import { iconMap, mimeIconMap } from '../../helpers/IconHelper';
import { bytesToSize } from '../../helpers/others';
import { code2mode } from '../../helpers/ModeMapper';

import Popup from '../Popup';
import FileDrop from '../FileDrop_container';

import DetailViewTMPLTM from './DetailViewTMPLTM';
import ExportView from './ExportView';
import MoreInfoView from './MoreInfo';
import RetryView from './RetryView';

import PermissionManager from '../permissions/PermissionManager';
import { checkPermission, PermissionTypes } from '../permissions/permissionConverter'

import CreateView from './CreateView';
import * as requestUtil from '../../util/request_util';

// Others
import { zfill, FilterObjectByKey } from '../../helpers/others';
import ActionMenu from '../ActionMenu';
import DragNDrop from './components/DragNDrop';
import { CSVLink, CSVDownload } from "react-csv";
import {getReportCSV, getReportCSVinOne} from './ReportCSVDownloader';
import RenameModal from './components/RenameModal';
import TagModal from './components/TagModal';
import {translate} from '../../helpers/translate';
import FolderInfoModal from './FolderInfoModal';

// Tag options
import { tags2icons } from './components/TagOptions';


class FileBox extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

            expandedPages: [],
            detailRes: {},
            fileFilters: {},
            sorting: {},
            AllPath: props.AllPath,

            selected: null, // The most recent selected (folder/file)
            selectedFile: null, // List of page data of selected file
            selectedItems: [], // List of all selected items
            UploadView: false,
            uploadViewFiles: [],
            DetailView: null,
            createView: false,
            permissionsView: null,
            SingleInputView: false,
            NewName: null,
            ExportViewEnabled: false,
            RetryViewEnabled: false,
            page_index: 0,

            ActionMenuLoc: null,

            MoreInfoView: null,
            csvData: [],
            csvFilename: '',
            showStarButton: null,

            paginationNumber: 0,
            numberOfContents: 0,
        };

        // dropDown Reference: once clicking on page, code will check if current ref should be closed or not
        this.dropDownRef = React.createRef();
        this.cvsLink = React.createRef();

        this.handleSocketUpdate = this.handleSocketUpdate.bind(this);
        this.selectFolder = this.selectFolder.bind(this);
        this.displayFileDetail = this.displayFileDetail.bind(this);
        this.OpenPage = this.OpenPage.bind(this);
        this.RightSideButtons = this.RightSideButtons.bind(this);
        this.MultiExport = this.MultiExport.bind(this);
        this.finishStatus = this.finishStatus.bind(this);
        this.filterFile = this.filterFile.bind(this);
        this.showFileFilter = this.showFileFilter.bind(this);
        this.toggleCheckboxFilter = this.toggleCheckboxFilter.bind(this);
        this.sortFiles = this.sortFiles.bind(this);
        this.ExportSetting = null;
        this.RetrySetting = null;
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.HandleAction = this.HandleAction.bind(this);
        this.handleStar = this.handleStar.bind(this);
        this.handleRename = this.handleRename.bind(this);
        this.handleMoreInfo = this.handleMoreInfo.bind(this);
        this.handleRestore = this.handleRestore.bind(this);
        this.handleDragDropFiles = this.handleDragDropFiles.bind(this);
        this.do_search = this.do_search.bind(this);
        this.isPaginationPreviousAvailable = this.isPaginationPreviousAvailable.bind(this);
        this.isPaginationNextAvailable = this.isPaginationNextAvailable.bind(this);
        this.handlePaginationPrevious = this.handlePaginationPrevious.bind(this);
        this.handlePaginationNext = this.handlePaginationNext.bind(this);
        this.getTotalNumberOfResources = this.getTotalNumberOfResources.bind(this);

        this.confident_threshold = [95, 65, 45, 5];
        this.noResults = null;

        this.AllPath_copy = {};
    }

    /**
     * This function is responsible for loading the files and folders that need to be displayed
     * It receives props as a parameter since it uses the next props to be rendered
     */
    async loadContents(props, paginationNumber) {
        store.dispatch({ type: 'LOADING_STATUS', data: true });
        if (this.getLocationPathname(props) !== 'search') {
            if (this.getLocationPathname(props) === 'trash' && this.getNextLocationParams(props).length === 0) {
                // The user wants the root of the trash folder
                await props.getRootTrash();
            } else if (this.getLocationPathname(props) === 'trash') {
                // The user wants a trashed folder
                await props.getTrashContents(this.getNextLocationParams(props)[0], paginationNumber);
                this.getTotalNumberOfResources(this.getNextLocationParams(props)[0]);
            } else if (this.getLocationPathname(props) === 'shared' && this.getNextLocationParams(props).length === 0) {
                // The user wants the root of the shared folders
                await props.getRootSharedFolders();
            } else if (this.getLocationPathname(props) === 'starred' && this.getNextLocationParams(props).length === 0) {
                // The user wants the root of the shared folders
                await props.getStarredResources();
            } else if (this.getLocationPathname(props) === 'home' && this.getNextLocationParams(props).length === 0) {
                // The user wants the root of its folders
                await props.getRootOwnFolders();
            } else if (this.getLocationPathname(props) === 'recent'){
                // The user wants recent 
                await props.getRecentFiles();
            } else {
                // The user wants a certain folder (get by the url id)
                await props.getFolderContents(props.match.params.param, paginationNumber, props.match.params.token);
                this.getTotalNumberOfResources(props.match.params.param);
            }

        } else {
            if (props.noSearchResults === true) {
                this.setState({noResults: null})
            }
        }
        store.dispatch({ type: 'LOADING_STATUS', data: false });
    }

    async componentWillReceiveProps(nextProps) {        
        // Setup token in the session if the token hasn't been specified
        if (this.isSharedToken() && !this.props.shareToken) {
            this.props.setShareToken(this.props.match.params.token);
        }

        // Check for any changes in the location
        if (JSON.stringify(nextProps.location) !== JSON.stringify(this.props.location)) {
            this.setState({ selectedItems: [], selected: null, expandedPages: [], DetailView: null, paginationNumber: 0 });
            this.loadContents(nextProps, 0);
        }

        this.setState({AllPath: nextProps.AllPath})

        // Check if the user can't access to folder
        if (!nextProps.AllPath) {
            if (nextProps.error.length !== 0) {
                if (this.props.history.length) {
                    // Go back
                    this.props.history.goBack();
                } else {
                    console.log(this.props.history)
                    // Go home
                    this.props.history.push('/dashboard');
                }
            }

            else if (nextProps.fbtype === "search") {
                this.setState({noResults: true})
            }
        } else {
            // If it's a shared file link, open the file
            if (this.state.AllPath === null && this.isSharedToken() && this.getLocationPathname(this.props) === 'file') {
                this.selectFileOnSharedLink(nextProps.AllPath.Files[0]);
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        // In shared links, socket is null
        this.props.socket && this.props.socket.off('FileInFolderUpdate', this.handleSocketUpdate);
    }

    isSharedToken() {
        return this.props.location.pathname.split('/')[1] === 'share'
    }

    async componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
      
        // Get folders
        this.loadContents(this.props, 0);
        // Setup socket io (In shared links, socket is null)
        this.props.socket && this.props.socket.on('FileInFolderUpdate', this.handleSocketUpdate);
    }

    handleSocketUpdate(data){
        if (data) {
            console.log('socket get data: ', data);
            let CurrentFolder_FolderID = this.props.match.params.param;
            CurrentFolder_FolderID = CurrentFolder_FolderID?CurrentFolder_FolderID:null;
            let data_parentFolderID = Object.keys(data).includes('PARENT_FOLDER_ID')?data['PARENT_FOLDER_ID']:null;
            if (CurrentFolder_FolderID === data_parentFolderID || Object.keys(data).includes('PageID')){
                // User is currently at the folder
                let data_FileID = Object.keys(data).includes('FileID')?data['FileID']:null;
                let data_PageID = Object.keys(data).includes('PageID')?data['PageID']:null;
                // let data_PageIDs = Object.keys(data).includes('PageIDs')?data['PageIDs']:null;
                let data_FolderID = Object.keys(data).includes('FolderID')?data['FolderID']:null;
                let CopyPath = Object.assign({}, this.state.AllPath);
                
                if (data_FileID){
                    // Socket receive File upload callback
                    let before = CopyPath['Files'];
                    let foundFileIndex = before.findIndex(x => x.FileID === data_FileID);
                    if (foundFileIndex === -1) {
                        // Socket receive process callback (first page)
                        data.new = true;
                        before.unshift(data);
                        return
                    } else {
                        // Socket receive process callback (not first page)
                        let FileData = before[foundFileIndex];
                        let status = Object.keys(data).includes('status')?data['status']:null;
                        FileData['status'] = status.includes('processing')?'processing':status
                        FileData['PageProgress'] = status.includes('processing_')?status.replace('processing_', ''):'';
                        before[foundFileIndex] = FileData;
                        this.props.ModifyPath(CopyPath);
                        return
                    }
                } else if (data_FolderID) {
                    // Socket Folder creation
                    let before = CopyPath['Folders'];
                    let foundIndex = before.findIndex(x => x.FolderID === data_FolderID);
                    if (foundIndex != -1) {
                        before[foundIndex] = data; // update folder
                    } else {
                        data.new = true;
                        before.unshift(data);
                    };
                } else {
                    console.log('unknown socket');
                }
            }
        }
    }

    /**
     * Returns the location of the route (could be "home", "shared", "folder" or "trash")
     */
    getLocationPathname(props) {
        const locationArray = props.location.pathname.split('/');
        if (locationArray[1] === 'share') {
            return locationArray[3];
        }
        return locationArray[1];
    }

    getNextLocationParams(nextProps) {
        if (nextProps.match.params.token) {
            return nextProps.location.pathname.split('/').slice(4).filter(Boolean);
        }
        return nextProps.location.pathname.split('/').slice(2).filter(Boolean);
    }

    async expandPages(e, file, fecthRes = true) {
        e.stopPropagation();
        e.preventDefault();
        if (fecthRes) {
            await this.getDetailRes(file.FileID);
        }
        let prev_expandedPages = this.state.expandedPages;
        if (prev_expandedPages.includes(file.FileID)) {
            prev_expandedPages.splice(prev_expandedPages.indexOf(file.FileID), 1);
            this.setState({
                expandedPages: prev_expandedPages
            })
        } else {
            this.setState({
                expandedPages: [...prev_expandedPages, file.FileID]
            })
        }
    }

    async getDetailRes(FileID) {
        store.dispatch({ type: 'LOADING_STATUS', data: true });
        let res;
        let prevDetailRes = this.state.detailRes;
        let fetchDetailRes = false;
        if (Object.keys(this.state.detailRes).includes(FileID)) {
            let detailResList = this.state.detailRes[`${FileID}`];
            let lastItem = detailResList.length > 0 ? detailResList[detailResList.length - 1] : {};
            if (lastItem.status != 'finished') {
                fetchDetailRes = true;
            }
        } else {
            fetchDetailRes = true;
        };

        // if (!Object.keys(this.state.detailRes).includes(FileID)){
        if (fetchDetailRes) {
            res = await this.props.simplepost({ FileID }, this.isSharedToken() ? '/ftree/token/singlefile' : '/ftree/singlefile', this.props.match.params.token);
            let FolderID = this.state.AllPath.CurrentFolder?this.state.AllPath.CurrentFolder.FolderID:null;
            let FolderSetting
            if (!FolderID){
                FolderSetting = null
            } else {
                FolderSetting = await this.props.simplepost({ FolderID }, this.isSharedToken() ? '/ftree/token/getTempConf' : '/ftree/getTempConf', this.props.match.params.token);
            }
            res["filterOpen"] = false;
            res["FolderSetting"] = FolderSetting;
            Object.assign(prevDetailRes, { [FileID]: res });
            let AllPath = Object.assign({}, this.state.AllPath);
            let before = AllPath.Files;
            before = before.filter(x => !(x.FileID === FileID && Object.keys(x).includes('PageID')));
            let foundFileIDIndex = before.findIndex(x => x.FileID === FileID);
            before.splice.apply(before, [foundFileIDIndex + 1, 0].concat(Array.isArray(res[0])?res[0]:res));
            this.props.ModifyPath(Object.assign(AllPath, { Files: before }));
            this.setState({ detailRes: prevDetailRes });
            store.dispatch({ type: 'LOADING_STATUS', data: false });
            return res
        } else {
            store.dispatch({ type: 'LOADING_STATUS', data: false });
            return this.state.detailRes[`${FileID}`]
        }
    }

    async OpenPage(e, file) {
        e.preventDefault();
        let PageID = file.PageID;
        let PageIDs = file.PageIDs;
        let res = await this.getDetailRes(file.FileID);
        this.setState({
            DetailView: file['ROUTE'] ? file['ROUTE'] : true,
            page_index: PageIDs.indexOf(PageID),
            selected: file,
            selectedFile: res[0]
        })
    }

    async selectFileOnSharedLink(file) {
        const e = { stopPropagation: () => {}, preventDefault: () => {} }

        this.selectFile(e, file);
    }

    async selectFile(e, file) {
        // display file detail info with popup
        e.stopPropagation();
        e.preventDefault();
        let res = await this.getDetailRes(file.FileID);
        this.setState({
            page_index: 0,
            selected: file,
            selectedFile: res,
            DetailView: Object.keys(res[0]).includes('ROUTE') ? res[0]['ROUTE'] : true
        })
    }

    async selectNoPop(e, file) {
        e.preventDefault();
        e.stopPropagation();
        await this.handleCheckBoxClick(e, file);
    }

    async selectFolder(e, folder) {
        e.preventDefault();
        store.dispatch({ type: 'LOADING_STATUS', data: true });
        if (this.getLocationPathname(this.props) === 'trash') {
            this.props.history.push(`/trash/${folder.FolderID}`);
        } else {
            this.props.history.push((this.isSharedToken() ? '/share/' + this.props.match.params.token : '') + `/folder/${folder.FolderID}`);
        }
        store.dispatch({ type: 'LOADING_STATUS', data: false });
    }

    handleCheckBoxClick(e, file) {
        const updateArray = this.state.selectedItems.includes(file) ? this.state.selectedItems.filter(x => x != file) : [...this.state.selectedItems, file];
        this.setState({
            selectedItems: updateArray,
            selected: updateArray.length > 0 ? updateArray[updateArray.length - 1] : null
        })
    }

    handleSelectAllCheckBox(e) {
        const filelist = this.state.AllPath.Files ? this.state.AllPath.Files : [];
        const folderlist = this.state.AllPath.Folders ? this.state.AllPath.Folders : [];
        const updateArray = this.state.selectedItems.length === filelist.length + folderlist.length ? [] : [...folderlist, ...filelist];
        this.setState({
            selectedItems: updateArray,
            selected: updateArray.length > 0 ? updateArray[updateArray.length - 1] : null
        })
    }

    handleClickOutside(event) {
        if (this.dropDownRef && this.dropDownRef.current) {
            if (!this.dropDownRef.current.contains(event.target)) {
                // check which files have open filters and "close" them by updating state
                let newState = Object.freeze(this.state);
                
                Object.keys(newState.detailRes).map(key => {
                    if (Object.keys(newState.detailRes[key]).includes("filterOpen") && newState.detailRes[key]["filterOpen"]) {
                        newState.detailRes[key]["filterOpen"] = false;
                    }
                });

                this.setState(newState);
            }
        }
    }

    async getTotalNumberOfResources(currentFolderId) {
        try {
            if (this.isSharedToken()) {
                const count = await requestUtil.shareTokenPost(props.match.params.token, { currentFolderId }, '/ftree/token/countContents');
                this.setState({ numberOfContents: count })
            } else {
                const count = await requestUtil.simplePost({
                    currentFolderId,
                    deleted: this.getLocationPathname(this.props) === 'trash'
                }, '/ftree/countContents');
                this.setState({ numberOfContents: count })
            }
        } catch (err) {
            console.log(err)
            console.log('Unable to get the number of contents')
        }
    }

    handleDelete(e, DeleteList = null) {
        let updates = Object.assign({}, this.state.AllPath);
        DeleteList = DeleteList === null ? this.state.selectedItems : DeleteList;
        
        let titles = DeleteList.map(x => { return x.title })
        const requestData = DeleteList.reduce((pre, cur) => {
            if (cur.FileID) {
                pre.files.push(cur.FileID)
            } else {
                pre.folders.push(cur.FolderID)
            }
            return pre
        }, { folders: [], files: [] })
        if (window.confirm(`Are you sure that you want to delete ${titles.join()}?`)) {
            // this.props.simplepost({'files': JSON.stringify(this.state.selectedItems)}, '/ftree/authdelete').then(res => {
            this.props.simplepost(requestData, '/ftree/authdelete').then(res => {
                let deleted_IDs = [];
                for (let i = 0; i < DeleteList.length; i++) {
                    if (Object.keys(DeleteList[i]).includes('FolderID')) {
                        deleted_IDs.push(DeleteList[i].FolderID);
                    } else {
                        deleted_IDs.push(DeleteList[i].FileID);
                    }
                }
                let FilesList = this.state.AllPath.Files ? this.state.AllPath.Files : [];
                let FolderList = this.state.AllPath.Folders ? this.state.AllPath.Folders : [];
                const newFiles = FilesList.filter(x => !deleted_IDs.includes(x.FileID));
                const newFolders = FolderList.filter(x => !deleted_IDs.includes(x.FolderID));
                Object.assign(updates, { 'Files': newFiles, 'Folders': newFolders });
                this.props.ModifyPath(updates);
                this.setState({ selected: null, selectedItems: [] });
            })
        }
    }

    async handlePermanentlyDelete(e, DeleteList = null) {
        let updates = Object.assign({}, this.props.AllPath);
        DeleteList = DeleteList === null ? this.state.selectedItems : DeleteList;
        // console.log('DeleteList: ', DeleteList);
        let titles = DeleteList.map(x => { return x.title })
        if (window.confirm(`Are you sure that you want to delete ${titles.join()}?`)) {
            // Get files and folders to delete
            const filesToDelete = DeleteList.filter(f => f.FileID).map(f => f.FileID);
            const foldersToDelete = DeleteList.filter(f => !f.FileID).map(f => f.FolderID);

            // Delete
            await JSONDelete({ 'fileIDs': filesToDelete, 'folderIDs': foldersToDelete }, '/ftree/permanentlyDelete')

            // Remove the files and folders from state
            let FilesList = this.props.AllPath.Files ? this.props.AllPath.Files : [];
            let FolderList = this.props.AllPath.Folders ? this.props.AllPath.Folders : [];
            const newFiles = FilesList.filter(x => !filesToDelete.includes(x.FileID));
            const newFolders = FolderList.filter(x => !foldersToDelete.includes(x.FolderID));
            Object.assign(updates, { 'Files': newFiles, 'Folders': newFolders });
            this.props.ModifyPath(updates);
            this.setState({ selected: null, selectedItems: [] });
        }
    }

    async handleDownload(e, DownloadList = null) {
        DownloadList = DownloadList === null ? this.state.selectedItems.length>0?this.state.selectedItems:[this.state.selected] : DownloadList;
        for (let i = 0; i < DownloadList.length; i++) {
            let Item = DownloadList[i];
            if (!Object.keys(Item).includes('FileID')) {
                continue
            };
            let url = Item['URL'];
            let title = Item['title'];


            let resp = await fetch(url, { method: 'GET' });
            const reader = resp.body.getReader();
            const contentLength = +resp.headers.get('Content-Length');
            let receivedLength = 0; 
            let chunks = []; 
            while(true) {
                const {done, value} = await reader.read();
                
                if (done) {
                    break;
                }
                
                chunks.push(value);
                receivedLength += value.length;
                
                let download_percentage = Math.round(receivedLength/contentLength*100);
                if (download_percentage % 10 === 0){
                    store.dispatch({type: 'DOWNLOAD_PROGRESS', data: download_percentage});
                }
            };
            let blob = new Blob(chunks);
            let newurl = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = newurl;
            a.download = title;
            document.body.appendChild(a);
            a.click();
            a.remove();
        };
    }

    handleRenameView(e, Item) {
        this.setState({ SingleInputView: Item, NewName: Item.title });
    }
    
    handlePermissionsView(item) {
        this.setState({ permissionsView: item });
    }

    handleStarButton(e, item) {
        e.stopPropagation();
        if (item.StarredResources !== undefined){
            if (item.StarredResources.length > 0) {
                this.handleUnstar(item);
            } else {
                this.handleStar(item);
            }
        }
    }
    
    handleStar(item) {
        let items = this.state.selectedItems || []
        if (item !== null && (item.FileID || item.FolderID)) {
            items = [item]
        }

        let folderIds = items.map(v => v.FolderID || null)
        let fileIds = items.map(v => v.FileID || null)
        
        this.props.starResource({FileID: fileIds, FolderID: folderIds})

        let Folders = (this.state.AllPath.Folders||[]).map((f) => {
            if (folderIds.indexOf(f.FolderID) >= 0) {
                f.StarredResources = [true];
            }

            return f;
        })

        let Files = (this.state.AllPath.Files||[]).map((f) => {
            if (fileIds.indexOf(f.FileID) >= 0) {
                f.StarredResources = [true];
            }

            return f;
        })

        let selected = this.state.selected;
        let selectedItems = this.state.selectedItems;

        if (item === null) {
            const filelist = Files.filter(f => fileIds.indexOf(f.FileID) >= 0)
            const folderlist = Folders.filter(f => folderIds.indexOf(f.FolderID) >= 0)
            const updateArray = [].concat(folderlist).concat(filelist);

            selectedItems = updateArray            
            selected = updateArray.length > 0 ? updateArray[updateArray.length - 1] : null
        }
        let allPath = this.state.AllPath;
        try{
            allPath.Folders = Folders;
            allPath.Files = Files;
        } catch(e){}

        this.setState({
            selectedItems: selectedItems,
            selected: selected,
            AllPath: allPath
        })
    }
    
    handleUnstar(item) {
        let items = this.state.selectedItems || []
        if (item !== null && (item.FileID || item.FolderID)) {
            items = [item]
        }

        let folderIds = items.map(v => v.FolderID || null)
        let fileIds = items.map(v => v.FileID || null)
        
        this.props.unstarResource({FileID: fileIds, FolderID: folderIds})

        let Folders = (this.state.AllPath.Folders||[]).map((f) => {
            if (folderIds.indexOf(f.FolderID) >= 0) {
                f.StarredResources = [];
            }

            return f;
        })

        let Files = (this.state.AllPath.Files||[]).map((f) => {
            if (fileIds.indexOf(f.FileID) >= 0) {
                f.StarredResources = [];
            }

            return f;
        })

        let selected = this.state.selected;
        let selectedItems = this.state.selectedItems;

        if (item === null) {
            const filelist = Files.filter(f => fileIds.indexOf(f.FileID) >= 0)
            const folderlist = Folders.filter(f => folderIds.indexOf(f.FolderID) >= 0)
            const updateArray = [].concat(folderlist).concat(filelist);

            selectedItems = updateArray            
            selected = updateArray.length > 0 ? updateArray[updateArray.length - 1] : null
        }
        let allPath = this.state.AllPath;
        try {
            allPath.Folders = Folders;
            allPath.Files = Files;
        } catch(e){}

        this.setState({
            selectedItems: selectedItems,
            selected: selected,
            AllPath: allPath
        })
    }


    handleRename(newName) {
        // Update the name
        let original_title = this.state.SingleInputView.title
        let extension = Object.keys(this.state.SingleInputView).includes('FileID') ? original_title.split('.').length > 1 ? '.' + original_title.split('.').pop(): '': '';
        // Send the change
        this.props.jsonPost({
            FileID: this.state.SingleInputView.FileID,
            FolderID: this.state.SingleInputView.FolderID,
            PARENT_FOLDER_ID: this.state.SingleInputView.PARENT_FOLDER_ID,
            title: newName + extension
        }, '/ftree/renameResource').then((res) => {
            Object.assign(this.state.SingleInputView, { title: newName + extension });
            this.setState({ SingleInputView: false });
        });
    }

    async handleMoreInfo(e, Resource){
        if (Object.keys(Resource).includes('FileID')){
            await this.getDetailRes(Resource.FileID);
        }
        this.setState({
            MoreInfoView: Resource
        });
    }

    async handleRestore(e, restoreList = null){
        const items = restoreList === null ? this.state.selectedItems : restoreList;

        const fileIds = items.filter(f => f.FileID).map(f => f.FileID);
        const folderIds = items.filter(f => !f.FileID).map(f => f.FolderID);

        try {
            if (fileIds.length > 0){
                await this.props.simplepost({ "fileIDs": fileIds }, '/ftree/restoreFiles')
            }
            for (const folderId of folderIds) {
                await this.props.simplepost({ "folderID": folderId }, '/ftree/restoreFolder')
            }
            this.loadContents(this.props, this.state.paginationNumber)
        } catch (err) {
            props.showCustomError(err)
        }
    }

    sortFiles(e, sortType) {
        e.preventDefault();
        e.stopPropagation();

        let state = Object.freeze(this.state);

        if (!Object.keys(state.sorting).includes(sortType)) {
            // 1: "turn on" descending sorting for `SortType` (default); delete all other sorts
            Object.keys(state.sorting).map((key) => (delete state.sorting[key]))
            state.sorting[sortType] = { "descending": true }

        } else {
            if (state.sorting[sortType]["descending"] === true) {
                // 2: reverse sort direction for `SortType`
                state.sorting[sortType]["descending"] = false;

            } else {
                // 3: disable sorting for `SortType`
                delete state.sorting[sortType];
            }
        }
        this.setState(state);
    }

    getSortIcon(sortType) {
        let state = Object.freeze(this.state);

        if (!Object.keys(state.sorting).includes(sortType)) {
            // 1: show icon for default (no sorting yet)
            return (<i className="fa fa-sort"></i>)

        } else {
            if (state.sorting[sortType]["descending"] === true) {
                // 2: show icon for sorting descending
                return (<i className="fa fa-sort-down"></i>)

            } else {
                // 3: show icon for sorting ascending
                return (<i className="fa fa-sort-up"></i>)
            }
        }
    }

    sendRetry() {
        store.dispatch({ type: 'LOADING_STATUS', data: true });
        let which_pages = this.RetrySetting;
        let FileID;
        if (this.state.selectedItems.length > 0){
            FileID = this.state.selectedItems[0].FileID;
        } else {
            FileID = this.state.selected.FileID;
        }

        let AllPath = Object.assign({}, this.state.AllPath);
        let before = AllPath.Files;
        let foundFileIDIndex = before.findIndex(x => x.FileID === FileID && !Object.keys(x).includes('PageID'));
        before[foundFileIDIndex]['status'] = false;
        this.props.ModifyPath(Object.assign(AllPath, { Files: before }));

        this.props.simplepost({
            'FileInfo': JSON.stringify(this.state.selectedItems[0]),
            'CODE': this.state.AllPath.CurrentFolder.CODE, 'Pages': which_pages['Pages']
        }, '/ftree/retry')
        setTimeout(() => {
            this.setState({ RetryViewEnabled: false });
            store.dispatch({ type: 'LOADING_STATUS', data: false });
        }, 1000);
    }

    async MultiExport() {
        // console.log('this.ExportSetting: ', this.ExportSetting);
        // console.log('this.state.selectedItems: ', this.state.selectedItems);
        store.dispatch({ type: 'LOADING_STATUS', data: true });
        let FileIDs = [];
        let Files = [];
        // let OutputName = this.ExportSetting['OutputName']?this.ExportSetting['OutputName']: 
        //     (this.state.selectedItems[0].title.split('.').length>1?this.state.selectedItems[0].title.split('.').slice(0, -1).join('.'):this.state.selectedItems[0].title);
        let OutputName = this.ExportSetting['OutputName']?this.ExportSetting['OutputName']: null
        let Multiple_OutputNames = this.state.selectedItems.map(item=>item.title.split('.').length>1?item.title.split('.').slice(0, -1).join('.'):item.title);
        for (let i = 0; i < this.state.selectedItems.length; i++) {
            if (this.state.selectedItems[i].FileID) {
                FileIDs.push(this.state.selectedItems[i].FileID);
                Files.push(this.state.selectedItems[i]);
            }
        };

        if (FileIDs.length > 0) {
            if (this.ExportSetting.ChooseWhat === 'Reports'){
                let pageDataList = [];
                for (let i=0; i<FileIDs.length; i++){
                    let res = await this.getDetailRes(FileIDs[i]);
                    if (this.ExportSetting.PageInSheet === 'Multiple'){
                        let csvData = getReportCSV(Files[i], res);
                        let csvFilename = `${Files[i].title.split('.').slice(0, -1).join('.')}_report.csv`
                        this.setState({csvData, csvFilename}, ()=>{
                            setTimeout(()=>{
                                this.cvsLink.current.link.click();
                            });
                        });
                    } else {
                        pageDataList.push(res);
                    }
                };
                if (pageDataList.length > 0){
                    let zipped_list = Files.map((e,i) => {return [e, pageDataList[i]]});
                    let csvData = getReportCSVinOne(zipped_list);
                    this.setState({csvData, csvFilename: 'Stats_report.csv'}, ()=>{
                        setTimeout(()=>{
                            this.cvsLink.current.link.click();
                        })
                    });
                }
                setTimeout(() => {
                    store.dispatch({ type: 'LOADING_STATUS', data: false });
                    this.setState({ ExportViewEnabled: false });
                }, 500);
            }else{
                let data = Object.assign({ 'FileIDs': FileIDs }, this.ExportSetting);
                this.props.simplepost({ 'data': JSON.stringify(data) }, '/ftree/MultiExport2excel').then(async res => {
                    if (res) {
                        console.log('res: ', res);
                        if (!Array.isArray(res)){
                            res = [res];
                        }
                        for (let i=0; i < res.length; i++){
                            let real_OutputName = OutputName ? OutputName : Multiple_OutputNames[i];
                            let url = res[i];
                            let extension = url.split('.').pop();
                            let resp = await fetch(url, { method: 'GET'});
                            const reader = resp.body.getReader();
                            const contentLength = +resp.headers.get('Content-Length');
                            let receivedLength = 0; 
                            let chunks = []; 
                            while(true) {
                                const {done, value} = await reader.read();
                                if (done) {
                                    break;
                                }
                                chunks.push(value);
                                receivedLength += value.length;                        
                                let download_percentage = Math.round(receivedLength/contentLength*100);
                                if (download_percentage % 10 === 0){
                                    store.dispatch({type: 'DOWNLOAD_PROGRESS', data: download_percentage});
                                }
                            };
                            let blob = new Blob(chunks);
                            let newurl = window.URL.createObjectURL(blob);
                            let a = document.createElement('a');
                            a.href = newurl;
                            a.download = `${real_OutputName}.${extension}`
                            a.click();
                        }
                    };
                    setTimeout(() => {
                        store.dispatch({ type: 'LOADING_STATUS', data: false });
                        this.setState({ ExportViewEnabled: false });
                    }, 500);
                })
                setTimeout(() => {
                    store.dispatch({ type: 'LOADING_STATUS', data: false });
                    this.setState({ ExportViewEnabled: false });
                }, 5000);
            }
        } else {
            store.dispatch({ type: 'LOADING_STATUS', data: false });
            this.setState({ ExportViewEnabled: false });
        }
    }

    FolderSetting() {
        let current_folder = this.state.selectedItems[0];
        let path = `/foldersetting/${current_folder.FolderID}:${current_folder.CODE}:${current_folder.lang}`;
        this.props.history.push(path);
    }

    displayFileDetail() {
        // console.log('displayFileDetail');
        return (
            <div className="displayFileDetail">
                {Object.keys(this.state.selected).includes('FileID')?
                    <Row>
                        <Col md={4} id='DetailKey'>{translate('Size')}</Col>
                        <Col md={8} id='DetailValue'>{this.state.selected.size ? bytesToSize(this.state.selected.size) : 'N/A'}</Col>
                    </Row>:null}
                <Row>
                    <Col md={4} id='DetailKey'>{translate('Created')}</Col>
                    <Col md={8} id='DetailValue'>{formatted_date(this.state.selected.createdAt)}</Col>
                </Row>
                <Row>
                    <Col md={4} id='DetailKey'>{translate('Modified')}</Col>
                    <Col md={8} id='DetailValue'>{formatted_date(this.state.selected.updatedAt)}</Col>
                </Row>
                <Row>
                    <Col md={4} id='DetailKey'>{translate('Owner')}</Col>
                    <Col md={8} id='DetailValue'>{this.props.userId === this.state.selected.USER_ID? 'Me': 'Other'}</Col>
                </Row>
                {/* <Row>
                    <Col md={4} id='DetailKey'>{translate('Tags')}</Col>
                    <Col md={8} id='DetailValue'><TagModal element={this.state.selected} /></Col>
                </Row> */}
                <Row>
                    <TagModal doc={this.state.selected} simplepost={this.props.simplepost} />
                </Row>
            </div>
        )
    }

    PageIconsIndicator(file) {
        let ky_pairs = Object.keys(file).includes('ky_pairs') ? file.ky_pairs : [];
        let all_value_conf = [];
        let warning_text = 'Please double check:';
        let configList = this.state.detailRes[`${file.FileID}`]?
            this.state.detailRes[`${file.FileID}`]['FolderSetting']?
                this.state.detailRes[`${file.FileID}`]['FolderSetting']['config']:null : null;
        if (configList) {
            for (let i = 0; i < ky_pairs.length; i++) {
                let uid = ky_pairs[i]['uid'] ? ky_pairs[i]['uid'] : zfill(i, 3);
                let cell_config = configList.filter(x => x.uid === uid)[0];
                let sensitive = cell_config ? cell_config['sensitive'] ? cell_config['sensitive'] : 'Default' : 'Default';
                let threashold = sensitive === 'Extremely high' ? this.confident_threshold[0]
                    : sensitive === 'High' ? this.confident_threshold[1] :
                        sensitive === 'Default' ? this.confident_threshold[2] : this.confident_threshold[3];
                // if (ky_pairs[i]['value_conf'] < 20 && ky_pairs[i]['value_conf'] > 0) {
                if (ky_pairs[i]['value_conf'] < threashold && ky_pairs[i]['value_conf'] > 0) {
                    all_value_conf.push(ky_pairs[i]);
                    warning_text += ' ' + ky_pairs[i]['key_txt'];
                };
            };
        }
        let VerifiedBy = file.VerifiedBy ? file.VerifiedBy : null;
        let whoVerified = '';
        if (typeof (VerifiedBy) === 'object' && VerifiedBy != null) {
            whoVerified = VerifiedBy.name;
        }
        return (
            <td className="cell-icon">
                {file.status === 'finished' ? <MdDoneAll id='MdDoneAll' /> : 'Waiting...'}
                {file.Signature ? file.Signature.length > 0 ? <div className='Mytooltip'><FaFileSignature id='FaFileSignature' /><span class="Mytooltiptext">Signature</span></div> : null : null}
                {file.Stamp ? file.Stamp.length > 0 ? <div className='Mytooltip'><GiWaxSeal id='GiWaxSeal' /><span class="Mytooltiptext">Stamped</span></div> : null : null}
                {VerifiedBy ? <div className='Mytooltip'><MdVerifiedUser id='MdVerifiedUser' /><span class="Mytooltiptext">Verified by {whoVerified}</span></div> : null}
                {all_value_conf.length > 0 ? <div className='Mytooltip'><TiWarning id='TiWarning' /><span class="Mytooltiptext">{warning_text}</span></div> : null}
            </td>
        )
    }

    RightSideButtons() {
        let selects = this.state.selectedItems;
        let selected = this.state.selected;

        return (
            <div className='RightSideButtons'>
                {Object.keys(selected).includes('FileID') && checkPermission(selected.permission, PermissionTypes.DOWNLOAD) ?
                    <Button variant="link" id='MdFileDownload' onClick={(e) => { this.handleDownload(e) }}>
                        <MdFileDownload id='MdFileDownload'/>{translate('Download')}
                    </Button>:null}
                {Object.keys(selected).includes('FileID') && this.state.selectedItems.length>0 && checkPermission(selected.permission, PermissionTypes.WRITE) ?
                    <Button variant="link" id='MdAssessment' onClick={() => { this.setState({ ExportViewEnabled: true }) }}>
                        <FaFileExport id='FaFileExport'/>{translate('Export')}
                    </Button>:null}
                {Object.keys(selected).includes('FileID') ? null:
                    checkPermission(selected.permission, PermissionTypes.WRITE) &&
                        <Button variant="link" id='MdAssessment' onClick={() => { this.FolderSetting() }}>
                            <IoMdSettings id='IoMdSettings' />{translate('Setting')}
                        </Button>}
                {Object.keys(selected).includes('FileID') && checkPermission(selected.permission, PermissionTypes.WRITE) ? 
                    <Button variant="link" id='MdAssessment' onClick={() => { this.setState({ RetryViewEnabled: true }) }}>
                        <IoMdRepeat id='IoMdRepeat' />{translate('Retry')}
                    </Button>:null}
                {checkPermission(selected.permission, PermissionTypes.DELETE) && this.state.selectedItems.length>0 && this.getLocationPathname(this.props) !== 'trash' &&
                    <Button variant="outline-danger" style={{border: 'none'}} id='MdDeleteForever' onClick={(e) => { this.handleDelete(e) }}>
                        <FaTrashAlt id='FaTrashAlt'/>{translate('Delete')}
                    </Button>}
                {this.getLocationPathname(this.props) === 'trash' &&
                    <Button variant="link" id='MdDeleteForever' onClick={(e) => { this.handleRestore(e) }}>
                        <FaUndo id='FaUndo'/>{translate('Restore')}
                    </Button>}
                {this.getLocationPathname(this.props) === 'trash' &&
                    <Button variant="link" id='MdDeleteForever' onClick={(e) => { this.handlePermanentlyDelete(e) }}>
                        <FaTrashAlt id='FaTrashAlt'/>{translate('Permanently Delete')}
                    </Button>}
            </div>
        )
    }

    /**
     * @param {*} file 
     * This function turns finished file status to filter icon and enable filter for pages in certain document
     * To do:
     * OnClick, select: Verified, Signature, Low Confident. Show selections on status bar
     */
    finishStatus(file) {
        if (this.state.expandedPages.includes(file.FileID)) {
            let FileID = file.FileID;
            let FileDetailRes = this.state.detailRes[`${FileID}`];
            let configList = FileDetailRes ? FileDetailRes['FolderSetting']?FileDetailRes['FolderSetting']['config']:null : null;
            let totalWarning = 0
            let totalVerified = 0
            let totalPage = 0
            if (configList) {
                for (let j = 0; j < FileDetailRes.length; j++) {
                    let ContainsWarning = false;
                    let ky_pairs = FileDetailRes[j]['ky_pairs'];
                    let VerifiedBy = FileDetailRes[j]['VerifiedBy'];
                    let PageID = FileDetailRes[j]['PageID'];
                    if (PageID) { totalPage++ };
                    if (VerifiedBy) { totalVerified++ };
                    if (!ky_pairs) { continue }
                    for (let i = 0; i < ky_pairs.length; i++) {
                        let uid = ky_pairs[i]['uid'] ? ky_pairs[i]['uid'] : zfill(i, 3);
                        let cell_config = configList.filter(x => x.uid === uid)[0];
                        let sensitive = cell_config ? cell_config['sensitive'] ? cell_config['sensitive'] : 'Default' : 'Default';
                        let threashold = sensitive === 'Extremely high' ? this.confident_threshold[0]
                            : sensitive === 'High' ? this.confident_threshold[1] :
                                sensitive === 'Default' ? this.confident_threshold[2] : this.confident_threshold[3];
                        // if (ky_pairs[i]['value_conf'] < 20 && ky_pairs[i]['value_conf'] > 0) {
                        if (ky_pairs[i]['value_conf'] < threashold && ky_pairs[i]['value_conf'] > 0) {
                            ContainsWarning = true;
                        };
                    };
                    if (ContainsWarning) { totalWarning++ }
                }
            }
            return (
                <React.Fragment>
                    <BsFilterLeft className="cell-icon FilterIcon" id={'FileFilter-' + file.FileID} onClick={(e) => { this.filterFile(e, file) }} />
                    <h7 style={{ margin: '0 5px 0 5px' }}>
                        <Badge className='ButtonHighLight' variant="secondary" onClick={(e) => { this.toggleCheckboxFilter(e, file, 'verified') }}>
                            <MdVerifiedUser style={{ color: 'white', fontSize: '25px' }} />{totalVerified}/{totalPage}
                        </Badge>
                    </h7>
                    {/* <h7 style={{ margin: '0 5px 0 5px' }}>
                        <Badge className='ButtonHighLight' variant="secondary" onClick={(e) => { this.toggleCheckboxFilter(e, file, 'warning') }}>
                            <TiWarning style={{ color: 'white', fontSize: '25px' }} />{totalWarning}/{totalPage}
                        </Badge>
                    </h7> */}
                    {FileDetailRes ? FileDetailRes["filterOpen"] ? this.showFileFilter(file) : null : null}
                </React.Fragment>
            )
        }
        return (iconMap('TiInputChecked'))
    }

    filterFile(e, file) {
        e.stopPropagation()

        let filterOpen = !this.state.detailRes[`${file.FileID}`]["filterOpen"];
        let newState = Object.freeze(this.state);
        newState.detailRes[`${file.FileID}`]["filterOpen"] = filterOpen;
        this.setState(newState);
    }

    showFileFilter(file) {
        let filters = (Object.keys(this.state.fileFilters).includes(file.FileID)) ? this.state.fileFilters[file.FileID] : {};
        return (
            <div ref={this.dropDownRef} className="fileFilterList" id={'fileFilterList-' + file.FileID}>
                <ul class="dropdown-menu checkbox-menu allow-focus ">
                    <h6>Filter pages by:</h6>
                    <li >
                        <label>
                            <input
                                type="checkbox"
                                onChange={(e) => { this.toggleCheckboxFilter(e, file, "completed") }}
                                checked={Object.keys(filters).includes("completed") && filters["completed"]}
                                name="checkboxFileFilter-completed"
                                id="checkboxFileFilter-completed" /> Pages completed
                        </label>
                    </li>
                    <li >
                        <label>
                            <input
                                type="checkbox"
                                onChange={(e) => { this.toggleCheckboxFilter(e, file, "processing") }}
                                checked={Object.keys(filters).includes("processing") && filters["processing"]}
                                name="checkboxFileFilter-processing"
                                id="checkboxFileFilter-processing" /> Pages in-progress
                        </label>
                    </li>
                    <li >
                        <label>
                            <input
                                type="checkbox"
                                onChange={(e) => { this.toggleCheckboxFilter(e, file, "warning") }}
                                checked={Object.keys(filters).includes("warning") && filters["warning"]}
                                name="checkboxFileFilter-warning"
                                id="checkboxFileFilter-warning" /> Warnings
                        </label>
                    </li>
                    <li >
                        <label>
                            <input
                                type="checkbox"
                                onChange={(e) => { this.toggleCheckboxFilter(e, file, "signature") }}
                                checked={Object.keys(filters).includes("signature") && filters["signature"]}
                                name="checkboxFileFilter-signature"
                                id="checkboxFileFilter-signature" /> Signed
                        </label>
                    </li>
                    <li >
                        <label>
                            <input
                                type="checkbox"
                                onChange={(e) => { this.toggleCheckboxFilter(e, file, "stamp") }}
                                checked={Object.keys(filters).includes("stamp") && filters["stamp"]}
                                name="checkboxFileFilter-stamp"
                                id="checkboxFileFilter-stamp" /> Stamped
                        </label>
                    </li>
                    <li >
                        <label>
                            <input
                                type="checkbox"
                                onChange={(e) => { this.toggleCheckboxFilter(e, file, "verified") }}
                                checked={Object.keys(filters).includes("verified") && filters["verified"]}
                                name="checkboxFileFilter-verified"
                                id="checkboxFileFilter-verified" /> Verified
                        </label>
                    </li>
                    <a href="#" onClick={(e) => { this.clearFileFilters(e, file) }} >Clear all filters</a>
                </ul>
            </div>
        )
    }

    clearFileFilters(e, file) {
        e.stopPropagation();

        let prevState = Object.freeze(this.state);
        prevState.fileFilters[file.FileID] = {};
        this.setState(prevState);
    }

    toggleCheckboxFilter(e, file, ftype) {
        e.stopPropagation();
        let prevState = Object.freeze(this.state);

        if (!Object.keys(prevState.fileFilters).includes(file.FileID)) {
            prevState.fileFilters[file.FileID] = {};
        }

        // ftype will be the the "filter type" (e.g. filter completed pages only)
        if (e.target.checked === undefined) {
            let prevstate = prevState.fileFilters[file.FileID][ftype];
            prevState.fileFilters[file.FileID][ftype] = !prevstate;
        } else {
            prevState.fileFilters[file.FileID][ftype] = e.target.checked;
        }

        // if all ftypes are false, delete fileFilters for this fileid to make easier validations

        this.setState(prevState);
    }

    anyFilter(FileID) {
        let filters = [];
        if (Object.keys(this.state.fileFilters).includes(FileID)) {
            if (Object.keys(this.state.fileFilters[FileID]).length > 0) {
                Object.keys(this.state.fileFilters[FileID]).forEach(key => {
                    if (this.state.fileFilters[FileID][key] === true) {
                        filters.push(key);
                        return true;
                    }
                });
            }
        }

        // if (filters.length == 0) console.log("No filters active for this file")

        return filters.length > 0;
    }

    shouldFilterPage(file) {
        let not_filter_reasons = [];
        let configList = this.state.detailRes[`${file.FileID}`]?
            this.state.detailRes[`${file.FileID}`]['FolderSetting']?
                this.state.detailRes[`${file.FileID}`]['FolderSetting']['config']:[] : [];

        if (this.anyFilter(file.FileID) === true) {
            Object.keys(this.state.fileFilters[file.FileID]).forEach(key => {
                if (this.state.fileFilters[file.FileID][key] === true) {
                    if (key == "completed" && file.status === 'finished') {
                        not_filter_reasons.push("page is complete");
                        return false;

                    } else if (key == "processing" && file.status !== 'finished') {
                        not_filter_reasons.push("page is still in progress");
                        return false;

                    } else if (key == "signature" && file.Signature.length != 0) {
                        not_filter_reasons.push("page has signatures");
                        return false;

                    } else if (key == "stamp" && file.Stamp.length != 0) {
                        not_filter_reasons.push("page has stamps");
                        return false;

                    } else if (key == "verified" && file.VerifiedBy !== undefined && file.VerifiedBy !== null && file.VerifiedBy.hasOwnProperty("name") && file.VerifiedBy.name !== null) {
                        not_filter_reasons.push("page has been verified");
                        return false;

                    } else if (key == "warning") {
                        let ky_pairs = Object.keys(file).includes('ky_pairs') ? file.ky_pairs : [];
                        for (let i = 0; i < ky_pairs.length; i++) {
                            let uid = ky_pairs[i]['uid'] ? ky_pairs[i]['uid'] : zfill(i, 3);
                            let cell_config = configList.filter(x => x.uid === uid)[0];
                            let sensitive = cell_config ? cell_config['sensitive'] ? cell_config['sensitive'] : 'Default' : 'Default';
                            let threashold = sensitive === 'Extremely high' ? 95 : sensitive === 'High' ? 65 : sensitive === 'Default' ? 45 : 5;
                            // if (ky_pairs[i]['value_conf'] < 20 && ky_pairs[i]['value_conf'] >= 0) {
                            if (ky_pairs[i]['value_conf'] < threashold && ky_pairs[i]['value_conf'] >= 0) {
                                not_filter_reasons.push("has at least one warning");
                                return false;
                            }
                        }
                        if (not_filter_reasons.length != 0) return false;
                    }
                }
            });

        } else {
            not_filter_reasons.push("No filters active");
        }

        /* if (not_filter_reasons.length === 0) {
            console.log("Filtering this page")
        } else {
            console.log("Not filtering this page because:", not_filter_reasons)
        } */

        return not_filter_reasons.length === 0;
    }

    isPaginationPreviousAvailable() {
        return this.state.paginationNumber > 0;
    }

    isPaginationNextAvailable() {
        return (this.state.AllPath.Folders && this.state.AllPath.Folders.length >= 100) || (this.state.AllPath.Files && this.state.AllPath.Files.length >= 100);
    }

    handlePaginationPrevious() {
        if (this.state.paginationNumber <= 0) return;
        this.setState({paginationNumber: this.state.paginationNumber - 1});
        this.loadContents(this.props, this.state.paginationNumber - 1);
    }

    handlePaginationNext() {
        this.setState({paginationNumber: this.state.paginationNumber + 1});
        this.loadContents(this.props, this.state.paginationNumber + 1);
    }

    /**
     * This function is called when the user drags and drops a file into the filebox drag n drop.
     * It is responsible for sending those files to the upload view.
     */
    handleDragDropFiles(files) {
        this.setState({ UploadView: true, uploadViewFiles: files });
    }

    HandleActionMenu(e, info) {
        e.stopPropagation();
        let dim = e.target.getBoundingClientRect();

        if (this.state.ActionMenuLoc) {
            this.setState({ ActionMenuLoc: null })
        } else {
            this.setState({ ActionMenuLoc: [dim.left, dim.top + dim.height, info] })
        }
    }

    HandleAction(Action, info) { //'Share', 'Rename', 'Delete', 'Download', 'Info', 'Star'
        switch (Action) {
            case translate('Share'):
                this.handlePermissionsView(info);
                break;
            case translate('Rename'):
                this.handleRenameView(event, info)
                break;
            case translate('Delete'):
                this.handleDelete(event, [info]);
                break;
            case translate('Download'):
                this.handleDownload(event, [info]);
                break;
            case translate('Info'):
                this.handleMoreInfo(event, info);
                break;
            case translate('Restore'):
                this.handleRestore(event, [info]);
                break;
            // case 'Star':
            //     this.handleStar(info);
            //     break;
            // case 'Unstar':
            //     this.handleUnstar(info);
            case translate('Permanently Delete'):
                this.handlePermanentlyDelete(event, [info]);
                break;
            default:
                null
        };
        this.setState({ ActionMenuLoc: null });
    }

    do_search() {
        let query = document.getElementById("search-form").value;

        if (query !== null && query.length) {
            this.props.doSearch(query);

        } else {
            console.log("CANT QUERY EMPTY NAME")
        }
    }

    render() {
        if (!this.state.AllPath) {
            if (this.getLocationPathname(this.props) !== 'search') {
                return (<div className='loading'><p>LOADING...</p></div>)
            }
        };

        let { CurrentFolder, Files, Folders, RootFolders } = this.state.AllPath;
        CurrentFolder = CurrentFolder ? CurrentFolder : null;
        Files = Files ? Files : [];
        Folders = Folders ? Folders : [];
        RootFolders = RootFolders ? RootFolders : [];

        let NoPagesFiles = Files.filter(x => !Object.keys(x).includes('PageID'));
        let PagesList = Files.filter(x => Object.keys(x).includes('PageID'));
        Files = NoPagesFiles

        // default sort by creation date descending (newer on top)
        Folders.sort((a, b) => (a.createdAt > b.createdAt) ? -1 : ((b.createdAt > a.createdAt) ? 1 : 0))
        Files.sort((a, b) => (a.createdAt > b.createdAt) ? -1 : ((b.createdAt > a.createdAt) ? 1 : 0))

        if (Object.keys(this.state.sorting).length != 0) {
            if (Object.keys(this.state.sorting).includes("Name")) {
                // sort by name
                if (this.state.sorting["Name"].descending === true) {
                    // descending
                    Folders.sort((a, b) => (a.title > b.title) ? -1 : ((b.title > a.title) ? 1 : 0))
                    Files.sort((a, b) => (!Object.keys(a).includes("title") ? 0 : ((a.title > b.title) ? -1 : ((b.title > a.title) ? 1 : 0))))

                } else {
                    // ascending
                    Folders.sort((a, b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0))
                    Files.sort((a, b) => (!Object.keys(a).includes("title") ? 0 : ((a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0))))
                }
            }

            if (Object.keys(this.state.sorting).includes("Modified")) {
                // sort by name
                if (this.state.sorting["Modified"].descending === true) {
                    // descending
                    Folders.sort((a, b) => (a.updatedAt > b.updatedAt) ? -1 : ((b.updatedAt > a.updatedAt) ? 1 : 0))
                    Files.sort((a, b) => (a.updatedAt > b.updatedAt) ? -1 : ((b.updatedAt > a.updatedAt) ? 1 : 0))

                } else {
                    // ascending
                    Folders.sort((a, b) => (a.updatedAt > b.updatedAt) ? 1 : ((b.updatedAt > a.updatedAt) ? -1 : 0))
                    Files.sort((a, b) => (a.updatedAt > b.updatedAt) ? 1 : ((b.updatedAt > a.updatedAt) ? -1 : 0))
                }
            }

            if (Object.keys(this.state.sorting).includes("Created")) {
                // sort by name
                if (this.state.sorting["Created"].descending === true) {
                    // descending
                    Folders.sort((a, b) => (a.createdAt > b.createdAt) ? -1 : ((b.createdAt > a.createdAt) ? 1 : 0))
                    Files.sort((a, b) => (a.createdAt > b.createdAt) ? -1 : ((b.createdAt > a.createdAt) ? 1 : 0))

                } else {
                    // ascending
                    Folders.sort((a, b) => (a.createdAt > b.createdAt) ? 1 : ((b.createdAt > a.createdAt) ? -1 : 0))
                    Files.sort((a, b) => (a.createdAt > b.createdAt) ? 1 : ((b.createdAt > a.createdAt) ? -1 : 0))
                }
            }

            if (Object.keys(this.state.sorting).includes("Status")) {
                // sort by name
                if (this.state.sorting["Status"].descending === true) {
                    // descending
                    Folders.sort((a, b) => (a.status > b.status) ? -1 : ((b.status > a.status) ? 1 : 0))
                    Files.sort((a, b) => (a.status > b.status) ? -1 : ((b.status > a.status) ? 1 : 0))

                } else {
                    // ascending
                    Folders.sort((a, b) => (a.status > b.status) ? 1 : ((b.status > a.status) ? -1 : 0))
                    Files.sort((a, b) => (a.status > b.status) ? 1 : ((b.status > a.status) ? -1 : 0))
                }
            }
        }

        this.state.expandedPages.forEach(FileID => {
            let foundIndex = Files.findIndex(x => x.FileID === FileID);
            let SubPages = PagesList.filter(x => x.FileID === FileID);
            Files.splice.apply(Files, [foundIndex + 1, 0].concat(SubPages));
        })

        return (
            <div style={{display:'flex', flexFlow:'column'}}>
                {this.state.ActionMenuLoc ? (
                        this.getLocationPathname(this.props) === 'trash' ?
                            <ActionMenu Location={this.state.ActionMenuLoc}
                            ActionList={[translate('Restore'), translate('Permanently Delete')]}
                            HandleAction={this.HandleAction}
                            handleDisappear={() => { this.setState({ ActionMenuLoc: null }) }} /> :
                            <ActionMenu Location={this.state.ActionMenuLoc}
                            ActionList={[
                                checkPermission(this.state.ActionMenuLoc[2].permission, PermissionTypes.EDIT_PERMISSIONS) && translate('Share'),
                                checkPermission(this.state.ActionMenuLoc[2].permission, PermissionTypes.WRITE) && translate('Rename'),
                                checkPermission(this.state.ActionMenuLoc[2].permission, PermissionTypes.DELETE) && translate('Delete'),
                                checkPermission(this.state.ActionMenuLoc[2].permission, PermissionTypes.DOWNLOAD) && this.state.ActionMenuLoc[2].FileID && translate('Download'),
                                translate('Info')].filter(val => val)}
                            HandleAction={this.HandleAction}
                            handleDisappear={() => { this.setState({ ActionMenuLoc: null }) }} /> 
                    )
                    : null}
                {this.state.SingleInputView && (
                    <RenameModal
                        data={this.state.SingleInputView}
                        handleClose={ () => this.setState({ SingleInputView: null }) }
                        handleRename={ this.handleRename } />
                    )
                }
                {this.state.DetailView ?
                    <Popup show={this.state.selectedFile}
                        handleClose={() => { this.setState({ DetailView: null }) }}
                        size='big' closeButtonLoc='TopRight'>
                        <DetailViewTMPLTM item={this.state.selected} currentUser={this.props.currentUser}
                                          data={this.state.selectedFile}
                                        //   isSharedToken={!!props.match.params.token}
                                          simplepost={this.props.simplepost}
                                          page_index={this.state.page_index} />
                    </Popup>
                    : null}
                {this.state.MoreInfoView ?
                    <Popup show={true}
                        handleClose={() => { this.setState({ MoreInfoView: null }) }}
                        size='small' closeButtonLoc='TopRight'>
                            {
                                this.state.MoreInfoView.FileID ?
                                    <MoreInfoView FileInfo={this.state.MoreInfoView} PageInfo={this.state.detailRes[this.state.MoreInfoView.FileID]} CurrentUser={this.props.currentUser}/> :
                                    <FolderInfoModal folderInfo={this.state.MoreInfoView} />
                            }
                    </Popup>
                    : null}
                {this.state.UploadView ?
                    <Popup show={this.state.UploadView}
                        handleClose={() => { this.setState({ UploadView: false, uploadViewFiles: [] }) }}
                        closeButtonLoc='TopRight'>
                        <FileDrop
                            closePopup={() => { this.setState({ UploadView: false, uploadViewFiles: [] }) }}
                            files={ this.state.uploadViewFiles }
                            type={'dropin'}
                            LanguageSelectOption={true}
                            url="/ftree/authupload"
                            fieldname='file'
                            accept={constants.allowedFileTypes.join(', ')} // default: image/*, .pdf
                            CODE={this.state.AllPath.CurrentFolder.CODE}
                            method={this.state.AllPath.CurrentFolder.FolderID}
                            CaseName={this.state.AllPath.CurrentFolder.title}
                            FolderLang={this.state.AllPath.CurrentFolder.lang}
                        />
                    </Popup>
                    : null}
                {this.state.createView ?
                    <Popup show={this.state.createView} handleClose={() => { this.setState({ createView: false }) }} closeButtonLoc='TopRight' size="small">
                        <CreateView closePopup={() => { this.setState({ createView: false }) }}
                            simplepost={this.props.simplepost}
                            CurrentFolder={CurrentFolder} />
                    </Popup>
                    : null}
                {this.state.permissionsView ?
                    <Popup show={this.state.permissionsView} handleClose={() => { this.setState({ permissionsView: null }) }} closeButtonLoc='TopRight' size="small">
                        <PermissionManager
                            currentUser={this.props.currentUser}
                            folderID={this.state.permissionsView.FolderID ? this.state.permissionsView.FolderID : this.state.permissionsView.PARENT_FOLDER_ID}
                            folderName={this.state.AllPath.path ? this.state.AllPath.path[this.state.AllPath.path.length - 1].title : ''}
                            fileID={this.state.permissionsView.FileID} 
                            fileName={this.state.permissionsView.title}/>
                    </Popup>
                    : null}
                {this.state.ExportViewEnabled ?
                    <Popup show={this.state.ExportViewEnabled} handleClose={() => { this.setState({ ExportViewEnabled: false }) }}
                        handleSubmit={() => this.MultiExport()} size="xsmall">
                        <ExportView CurrentFolder={CurrentFolder} AllSelected={this.state.selectedItems} OutputMethod={v => { this.ExportSetting = v }} />
                    </Popup>
                    : null}
                {this.state.RetryViewEnabled ?
                    <Popup show={this.state.RetryViewEnabled} handleClose={() => { this.setState({ RetryViewEnabled: false }) }}
                        handleSubmit={() => this.sendRetry()} size="xsmall">
                        <RetryView OutputMethod={v => { this.RetrySetting = v }} />
                    </Popup>
                    : null}
                <Row style={{height: "4rem"}}>
                    <Col
                        md={this.getNextLocationParams(this.props).length !== 0 && this.state.AllPath.Folders && this.state.AllPath.Files ? 8 : 10}
                        className="CenterIconAlign">
                        {(this.getLocationPathname(this.props) === 'search') ? (
                            <div className="input-group">
                                <div className="form-outline" style={{ width: '100%' }}>
                                    <input type="search" id="search-form" className="form-control" style={{ width: '100%', paddingRight: '50px' }} />
                                    <button type="button" className="btn btn-primary" style={{ position: 'absolute', right: 0, top: 0, zIndex: 5 }} onClick={(e) => this.do_search(e)} >
                                        <FaSearch size="1em" />
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <Breadcrumb>
                                {this.state.AllPath.path ? this.state.AllPath.path.map((location, idx) => {
                                    let currentUrl = this.props.location.pathname.split('/');
                                    currentUrl.pop();
                                    currentUrl = currentUrl.join('/');
                                    const pathName = this.getLocationPathname(this.props);
                                    if (location.ownerName) {
                                        if (pathName === 'trash') {
                                            return <Breadcrumb.Item href="#/trash/">{translate('Trash')}</Breadcrumb.Item>
                                        }
                                        return (
                                            <Breadcrumb.Item
                                                href={this.props.userId === location.ownerId ? '#/home/' : '#/shared/'}>
                                                {location.ownerId === this.props.userId?'My Files':location.ownerName}
                                            </Breadcrumb.Item>
                                        )
                                    } else {
                                        return (
                                            <Breadcrumb.Item href={`#${currentUrl}/${location.FolderID}`}
                                                active={idx === this.state.AllPath.path.length - 1}>{location.title}
                                            </Breadcrumb.Item>
                                        )
                                    }
                                }) : <Breadcrumb.Item>{this.getLocationPathname(this.props)}</Breadcrumb.Item>}
                            </Breadcrumb>
                        )}
                    </Col>
                    {
                        this.getNextLocationParams(this.props).length !== 0 && this.state.AllPath.Folders && this.state.AllPath.Files &&
                            <Col md={2} className="paginationWrapper">
                                <p className="paginationText">{this.state.paginationNumber * 100 + 1} - {this.state.paginationNumber * 100 + this.state.AllPath.Folders.length + this.state.AllPath.Files.length} of {this.state.numberOfContents}</p>
                                <a
                                    className="paginationButton"
                                    onClick={this.isPaginationPreviousAvailable() && this.handlePaginationPrevious}
                                    // style={{ color: this.isPaginationPreviousAvailable() ? 'black' : 'lightgray', cursor: 'pointer'}}>
                                    style={this.isPaginationPreviousAvailable() ? { color: 'black', cursor: 'pointer'}: { color: 'lightgray'}}>
                                    <FaChevronLeft />
                                </a>
                                <a
                                    className="paginationButton"
                                    onClick={this.isPaginationNextAvailable() && this.handlePaginationNext}
                                    // style={{ color: this.isPaginationNextAvailable() ? 'black' : 'lightgray', cursor: 'pointer'}}>
                                    style={this.isPaginationNextAvailable() ? { color: 'black', cursor: 'pointer'}: { color: 'lightgray'}}>
                                    <FaChevronRight />
                                </a>
                            </Col>
                    }
                    <Col md={2} className="CenterIconAlign">
                        {/* <Button style={{marginRight: '1rem'}}>Upload</Button> */}
                        {/* {CurrentFolder && checkPermission(CurrentFolder.permission, PermissionTypes.WRITE) ?
                            <Button id='MdFileUpload' onClick={() => { this.setState({ UploadView: true }) }} style={{marginRight: '0.5rem'}}>
                                {translate('Upload')}
                            </Button> : null} */}
                        <Button id='MdFileUpload' 
                            onClick={() => { this.setState({ UploadView: true, uploadViewFiles: [] }) }} 
                            style={{marginRight: '0.5rem'}} 
                            disabled={CurrentFolder && checkPermission(CurrentFolder.permission, PermissionTypes.WRITE) ? false : true}>
                            {translate('Upload')}
                        </Button>
                        <Button style={{marginRight: '0.5rem'}}
                            variant={this.getLocationPathname(this.props) === 'home' || (CurrentFolder && checkPermission(CurrentFolder.permission, PermissionTypes.WRITE)) ? "primary" : "secondary"}
                            disabled={this.getLocationPathname(this.props) === 'trash' || 
                                      this.getLocationPathname(this.props) === 'search' || 
                                      this.getLocationPathname(this.props) === 'shared' || 
                                      this.getLocationPathname(this.props) === 'recent' ||
                                      (CurrentFolder && !checkPermission(CurrentFolder.permission, PermissionTypes.WRITE))}
                            onClick={() => { this.setState({ createView: true }) }}>{translate('Create')}
                        </Button>
                    </Col>
                </Row>
                <Row style={{ top: '4rem', bottom: '0px', position: 'absolute', width: '100%' }}>
                    <Col md={10} className='filebox-table-container' style={{ height: '100%' }}>
                        <DragNDrop
                            active={this.getLocationPathname(this.props) === 'folder' && (CurrentFolder && checkPermission(CurrentFolder.permission, PermissionTypes.WRITE))}
                            onUpload={this.handleDragDropFiles}>
                            <Table responsive hover className='ListTable'>
                                <thead>
                                    <tr>
                                        <th className='sticky' type='checkbox'>
                                            <label className='CheckContainer'>
                                                <input type="checkbox" onChange={(e) => { this.handleSelectAllCheckBox(e) }} checked={this.state.selectedItems.length === Files.length + Folders.length && this.state.selectedItems.length > 0} />
                                                <span className="checkmark"></span>
                                            </label>
                                        </th>
                                        <th className='sticky' style={{ paddingLeft: '50px' }} ><a href="#" className="sortable-btn" onClick={(e) => this.sortFiles(e, "Name")}> {translate('Name')} {this.getSortIcon('Name')}</a></th>
                                        <th className='sticky' style={{ width: '15%' }}><a href="#" className="sortable-btn" onClick={(e) => this.sortFiles(e, "Created")}>{translate('Created')} {this.getSortIcon('Created')}</a></th>
                                        <th className='sticky' style={{ width: '15%' }}><a href="#" className="sortable-btn" onClick={(e) => this.sortFiles(e, "Modified")}>{translate('Modified')} {this.getSortIcon('Modified')}</a></th>
                                        <th className='sticky' style={{ width: '15%' }}><a href="#" className="sortable-btn" onClick={(e) => this.sortFiles(e, "Status")}>{translate('Status')} {this.getSortIcon('Status')}</a></th>
                                        <th className='sticky' style={{ width: '10%' }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        Folders.map((folder, idx) => {
                                            return (
                                                <tr className={folder.new ? 'fadein' : null} style={this.state.selectedItems.includes(folder) ? { backgroundColor: 'var(--ViceSecondaryColor)' } : null} 
                                                    onClick={(e) => { this.selectNoPop(e, folder) }}
                                                    onMouseEnter={()=>this.setState({showStarButton: folder})} onMouseLeave={()=>this.setState({showStarButton: null})}>
                                                    <td type='checkbox'>
                                                        <label className='CheckContainer'>
                                                            <input type="checkbox" onChange={(e) => { this.selectNoPop(e, folder) }} checked={this.state.selectedItems.includes(folder)} />
                                                            {folder.StarredResources?
                                                                <span className={folder.StarredResources.length ?"checkmark AutoHide":this.state.showStarButton===folder?"checkmark": "checkmark AutoHide"}></span>
                                                            :null}
                                                        </label>
                                                    </td>
                                                    <td className="cell-icon" style={{ paddingLeft: '50px' }}>
                                                        <div className='FileBoxCell'>
                                                            { this.props.userId === folder.USER_ID ? folder.private===false?<MdFolderShared/>:<MdFolder /> : <MdFolderShared /> }
                                                            {/* {folder.private===false ? <MdPeople className='NotPrivateIcon' /> : null} */}
                                                            <div id="TitleParent">
                                                                <a id="TitleName" href="#" onClick={(e) => { this.selectFolder(e, folder) }}>{folder.title}</a>
                                                                <p id="Source">
                                                                    {folder.path ? folder.path.reduce((acc, val) => {
                                                                        if (val.ownerName) {
                                                                            return val.ownerName;
                                                                        } else {
                                                                            return acc + '/' + val.title
                                                                        }
                                                                    }, '') : ''}
                                                                </p>
                                                                {folder.tags? tags2icons(folder.tags):null}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="FileBoxDate">{formatted_date(folder.createdAt)}</td>
                                                    <td className="FileBoxDate">{formatted_date(folder.updatedAt)}</td>
                                                    <td>
                                                        {folder.CODE==='GS0000001'?null:<div>{translate('Language')}: {folder.lang?folder.lang:'N/A'}</div>}
                                                        <div>{translate('Mode')}: {folder.CODE?code2mode[folder.CODE]:'N/A'}</div>
                                                    </td>
                                                    <td className="cell-icon">
                                                        {folder.StarredResources?
                                                        <Button className={folder.StarredResources.length ?"StarButtonShow":this.state.showStarButton===folder?"StarButton margin-transition": "StarButton"} variant="link" id='MdStar' 
                                                            onClick={(e) => { this.handleStarButton(e, folder) }}>
                                                            {folder.StarredResources.length ?
                                                                <FaStar id='FaStar'/>
                                                                : <FaRegStar id='FaRegStar'/>}
                                                        </Button>:null}
                                                        { !this.isSharedToken() && <MdMoreVert className="MoreAction" onClick={(e) => { this.HandleActionMenu(e, folder); }} /> }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    {
                                        Files.map((file, idx) => {
                                            let shouldFilterPage = (file) => {
                                                return this.shouldFilterPage(file);
                                            }

                                            return (file.PageID ?
                                                this.state.expandedPages.includes(file.FileID) ?
                                                    (shouldFilterPage(file) === false) ?
                                                        <tr className={file.status === 'finished' ? '' : 'strip'}>
                                                            <td></td>
                                                            <td colSpan='3' className='ChildPage'>
                                                                {file.status === 'finished' ?
                                                                    <a href="#" onClick={(e) => this.OpenPage(e, file)}>
                                                                        <MdKeyboardArrowRight />Page {file.PageIDs.indexOf(file.PageID) + 1}/{file.PageIDs.length}
                                                                    </a>
                                                                    :
                                                                    <a><MdKeyboardArrowRight />Page {file.PageIDs.indexOf(file.PageID) + 1}/{file.PageIDs.length}</a>
                                                                }
                                                            </td>
                                                            {this.PageIconsIndicator(file)}
                                                            <td></td>
                                                        </tr>
                                                        :
                                                        null
                                                    :
                                                    null
                                                :
                                                <tr className={file.new ? 'fadein' : null}
                                                    style={this.state.selectedItems.includes(file) ? { backgroundColor: 'var(--ViceSecondaryColor)' } : null}
                                                    onClick={(e) => { this.selectNoPop(e, file) }} 
                                                    onMouseEnter={()=>this.setState({showStarButton: file})} onMouseLeave={()=>this.setState({showStarButton: null})}>
                                                    <td type='checkbox'>
                                                        <label className='CheckContainer'>
                                                            <input type="checkbox" onChange={(e) => { this.selectNoPop(e, file) }} checked={this.state.selectedItems.includes(file)} />
                                                            {/* <span className="checkmark AutoHide"></span> */}
                                                            {file.StarredResources?
                                                            <span className={file.StarredResources.length>0 ?"checkmark AutoHide":this.state.showStarButton===file?"checkmark": "checkmark AutoHide"}></span>
                                                            :null}
                                                        </label>
                                                    </td>
                                                    <td className="cell-icon" style={file.status === 'initialize' ? { opacity: '0.5' } : null}>
                                                        <div className='FileBoxCell'>
                                                            <a href="#" onClick={(e) => { this.expandPages(e, file) }}>
                                                                {this.state.expandedPages.includes(file.FileID) ? <VscTriangleDown id='TRIANGLE_DROP' /> : <VscTriangleRight id='TRIANGLE_DROP' />}
                                                            </a>
                                                            {mimeIconMap(file.title)}
                                                            <div id="TitleParent">
                                                                <a id="TitleName" href="#" onClick={(e) => { file.status != 'initialize' && this.selectFile(e, file) }}>{file.title}</a>
                                                                <p id="Source">
                                                                    {file.path ? file.path.reduce((acc, val) => {
                                                                        if (val.ownerName) {
                                                                            return val.ownerId===this.props.userId?'My Files':val.ownerName;
                                                                        } else {
                                                                            return acc + '/' + val.title
                                                                        }
                                                                    }, '') : ''}
                                                                </p>
                                                                {file.tags? tags2icons(file.tags):null}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="FileBoxDate" style={(file.status === 'initialize' || file.status === 'false') ? { opacity: '0.5' } : null}>
                                                        {formatted_date(file.createdAt)}
                                                    </td>
                                                    <td className="FileBoxDate" style={(file.status === 'initialize' || file.status === 'false') ? { opacity: '0.5' } : null}>
                                                        {formatted_date(file.updatedAt)}
                                                    </td>
                                                    <td className="cell-icon" style={{ overflowX: 'auto hidden', width: '100%' }}>
                                                        {file.status === 'finished'
                                                            ?
                                                            this.finishStatus(file)
                                                            :
                                                            ((file.status === 'initialize' || file.status === 'false') ? <MdAutorenew id='MdAutorenew' /> : iconMap('GoGear'))}
                                                        {file.VerifiedBy ? iconMap('MdVerifiedUser') : null}
                                                        {file.PageProgress && file.status != 'finished'?<span>{file.PageProgress}</span>:null}
                                                    </td>
                                                    <td className="cell-icon">
                                                        {file.StarredResources?
                                                        <Button className={file.StarredResources.length >0 ?"StarButtonShow":this.state.showStarButton===file?"StarButton margin-transition": "StarButton"} 
                                                            variant="link" id='MdStar' 
                                                            onClick={(e) => { this.handleStarButton(e, file) }}>
                                                            {file.StarredResources.length>0 ?
                                                                <FaStar id='FaStar'/>
                                                                : <FaRegStar id='FaRegStar'/>}
                                                        </Button>:null}
                                                        { !this.isSharedToken() && <MdMoreVert className="MoreAction" onClick={(e) => { this.HandleActionMenu(e, file); }} /> }
                                                    </td>
                                                </tr>)
                                        })
                                    }
                                </tbody>
                            </Table>
                            {(this.getLocationPathname(this.props) === 'search' && this.props.noSearchResults === true) ? (
                                <div>
                                    No results
                                </div>
                            ) : (null)}
                        </DragNDrop>
                    </Col>
                    <Col md={2} style={{ height: '100%', paddingRight: '0px' }}>
                        {this.state.selected?
                        <div className="RightSideInfoTop">
                            <div id='HeadSelect'>{translate('Details')}</div>
                            <div id='Select'>
                                <div id="FILEICON" className='cell-icon'>
                                    {/* {Object.keys(this.state.selected).includes('FolderID') ? <MdFolder /> : mimeIconMap(this.state.selected.title)} */}
                                    {Object.keys(this.state.selected).includes('FileID') ? mimeIconMap(this.state.selected.title):<MdFolder />}
                                </div>
                                <div id="FILENAME">
                                    <p>{this.state.selected.title}</p>
                                    <span>{this.state.selected.description}</span>
                                </div>
                            </div>
                            {this.displayFileDetail()}
                            {this.RightSideButtons()}
                        </div>
                        :
                        <div className="RightSideInfo">
                            <div id='Select'>
                                <img src="assets/images/drag-and-drop.png" />
                                <span>{translate('Select a file or folder to view its details')}</span>
                            </div>
                        </div>
                        }
                    </Col>
                </Row>
                <CSVLink data={this.state.csvData} filename={this.state.csvFilename} 
                        className='hidden' ref={this.cvsLink} target='_blank' />
            </div>
        );
    }
}

export default withRouter(FileBox);