import React from 'react';

import { withRouter } from 'react-router-dom';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import {MdFolder} from '@react-icons/all-files/md/MdFolder';

import {translate} from '../../helpers/translate';

const QuickAccess = ({ FolderList }) => {
  return (
    <div style={{width: "100%", marginTop: '20px'}}>
        <h5 style={{marginBottom: 0}}>{translate('Quick Access')}</h5>
        <hr style={{marginTop: 0}} />
        <Container fluid>
          <Row>
            {FolderList.map(folder=>{
              return (
                <Col>
                  <div className="FolderGroup" onClick={()=>{window.location.href=`#/folder/${folder.FolderID}`}}>
                    <div className="FolderIcon"><MdFolder /></div>
                    <div className="FolderTitle">{folder.title}</div>
                  </div>
                </Col>
              )
            })}
          </Row>
        </Container>
    </div>
  )
};

export default QuickAccess;

// class QuickAccess extends React.Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//     };
//   }

//   componentWillMount() {
//   }

//   componentWillUpdate() {

//   }

//   render() {
//     let titleList = this.props.serviceNames.length > 0? this.props.serviceNames : []
//     return (
//         <div style={{width: "100%", marginTop: '20px'}}>
//             <h5 style={{marginBottom: 0}}>{translate('Quick Access')}</h5>
//             <hr style={{marginTop: 0}} />
//             {/* <p>{translate('You have following services activated:')}</p> */}
//             {/* <div>
//                 {titleList.map(name=>{
//                     return (
//                         <a href={`#/folder/${name}`}><li>{name}</li></a>
//                     )
//                 })}
//             </div> */}
//             <Container fluid>
//               <Row>
//                 {titleList.map(name=>{
//                   return (
//                     <Col>
//                       <div className="FolderGroup" onClick={()=>{useHistory().push(`#/folder/${name}`)}}>
//                         <div className="FolderIcon"><MdFolder /></div>
//                         <div className="FolderTitle">{name}</div>
//                       </div>
//                     </Col>
//                   )
//                 })}
//               </Row>
//             </Container>
//         </div>
//     );
//   }
// }

// export default withRouter(QuickAccess);
