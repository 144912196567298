import { connect } from 'react-redux';

import React, { useState, useEffect } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { SimplePost, SimpleGet2 } from '../../actions/request_actions';

const mapStateToProps = (state, ownProps) => {
  const currentUser = Object.keys(state.entities.user).includes('id')? state.entities.user : state.session.currentUser;
  return {
    currentUser,
  };
};

const mapDispatchToProps = (dispatch, { location }) => {
  return {
    simplepost: (data, url) => dispatch(SimplePost(data, url)),
    simpleget: (data, url) => dispatch(SimpleGet2(data, url)),
  };
};


const Prices = (props) => {
  const [prices, setPrices] = useState([]);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [membership, setmembership] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [subscriptionData, setSubscriptionData] = useState(null);

  useEffect(() => {
    const fetchPrices = async () => {
      const {prices} = await props.simpleget({}, '/stripe-config')
      setPrices(prices);
    };
    setEmail(props.currentUser.email);
    setName(props.currentUser.name);
    setCustomerId(props.currentUser.customerID);
    fetchPrices();
  }, [])

  const createSubscription = async (priceId) => {
    if (props.currentUser.membership === 'pro'){
      setmembership('pro')
    } else {
      const {subscriptionId, clientSecret} = await props.simplepost(
        {'customerId': customerId, 'priceId': priceId}, 
        '/create-subscription');
      setSubscriptionData({ subscriptionId, clientSecret });
    }
  }

  if(subscriptionData) {
    return <Redirect to={{
      pathname: '/subscribe',
      currentUser: props.currentUser,
      state: subscriptionData,
      simplepost: props.simplepost
    }} />
  }

  if (membership === 'pro') {
    return <Redirect to={`/account-details`} />
  }

  return (
    <div>
      <h1>Select a plan</h1>

      <div className="price-list">
        {prices.map((price) => {
          return (
            <div key={price.id}>
              <h3>{price.product.name}</h3>

              <p>
                ${price.unit_amount / 100} / month
              </p>

              <button onClick={() => createSubscription(price.id)}>
                Select
              </button> 7 days free trail
            </div>
          )
        })}
      </div>
    </div>
  );
}

// export default withRouter(Prices);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Prices)
);