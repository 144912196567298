
import React from 'react';
import { connect } from 'react-redux';

import { Route, withRouter, Redirect } from 'react-router-dom';

import { logout } from '../actions/session_actions';

const Auth = ({ component: Component, path, loggedIn}) => {
  // if auth, go to dashboard
  return (
    <Route
      path={path}
      render={props =>
        !loggedIn ? <Component {...props} /> : <Redirect to="/dashboard" />}
    />
  );
};

const Protected = ({ component: Component, path, loggedIn, currentUser, logout }) => {

  if (!loggedIn) {
    logout();

    return (
      <Route
      path={path}
      render={props => <Redirect to="/auth/login" />}
      />
    );
  }

  if (loggedIn && path === '/'){
    return (
      <Route
        path={path}
        render={props => <Redirect to="/dashboard" />}
      />
    );
  }

  const { loginProvider, isAuthenticated, resetedPassword } = currentUser;

  // Only apply for local user
  if (loggedIn && !loginProvider && isAuthenticated && !resetedPassword && path !== '/update-password') {
    return (
      <Route
      path={path}
      render={props => <Redirect to="/update-password" />}
    />
    )
  }

  // Apply to all user types
  return (
    <Route
      path={path}
      render={props =>
        (loggedIn && isAuthenticated) ? <Component {...props} /> : <Redirect to="/signup" />}
    />
  );
};

const mapStateToProps = state => {
  let loggedIn = false;

  const { currentUser } = state.session;

  if (currentUser && localStorage.getItem('token') && localStorage.getItem('user')) 
    loggedIn = true;
  
  return {
    loggedIn,
    currentUser
  };
};

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout())
});

export const AuthRoute = withRouter(connect(mapStateToProps, null)(Auth));

export const ProtectedRoute = withRouter(connect(mapStateToProps, mapDispatchToProps)(Protected));
